import { schema } from "normalizr"
import article from "../../basedata/articles/item"
import customer from "../../basedata/customers/item"
import articleAmountUnit from "../amountUnits/item"
import technology from "../../basedata/technologies/item"
import processTemplate from "../../basedata/templates/item"
import status from "../../status/item"
import user from "../../users/item"

export default new schema.Entity(
  "productionOrders",
  {
    createdBy: user,
    article,
    orderPositionArticle: article,
    customer,
    articleAmountUnit,
    technology,
    processTemplate,
    status,
    ownedBy: user,
    updatedBy: user,
  },
  { idAttribute: "productionOrderId" }
)
