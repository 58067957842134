import { get } from "lodash"
import { useApolloClient } from "@apollo/client"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { GET_GLOBALSETTINGS } from "../pages/Admin/GlobalSettings/useFetchData"
import { saveSettingsAfterGraphQL } from "../actions/admin/settings"

const useFetchSettings = () => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  return useCallback(
    () =>
      client
        .query({
          query: GET_GLOBALSETTINGS,
          fetchPolicy: "no-cache",
          errorPolicy: "ignore",
        })
        .then(({ data }) => dispatch(saveSettingsAfterGraphQL(get(data, "globalSettings.items.0")))),
    [client]
  )
}

export default useFetchSettings
