import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { round } from "lodash"

const Duration = ({ duration }) =>
  duration && (
    <FormattedMessage
      id={`type.duration.${duration.unit}`}
      defaultMessage="{amount, number} {amount, plural, one {Unit} other {Units}}"
      values={{ amount: round(duration.amount, 2) }}
    />
  )

Duration.propTypes = {
  duration: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.oneOf(["seconds", "minutes", "hours", "days", "weeks", "months", "years"]),
  }),
}

Duration.defaultProps = {
  duration: null,
}

export default Duration
