import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Typography from "@mui/material/Typography"

const styles = {
  margin: "20px 0 0 0",
  padding: 0,
}

const FormGroupTitle = ({ id }) => (
  <Typography style={styles} variant="subtitle1" paragraph>
    <FormattedMessage id={id} />
  </Typography>
)

FormGroupTitle.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FormGroupTitle
