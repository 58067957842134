import { schema } from "normalizr"

export default new schema.Entity(
  "licenceModules",
  {},
  {
    idAttribute: "moduleLicenceId",
    // This is needed because when expiryDate is null it\s not returned from the backend due to server configuration
    // That caused a problem when removing expiryDate for existing license
    processStrategy: entity => ({ expiryDate: null, ...entity }),
  }
)
