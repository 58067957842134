import Moment from "moment"
import { extendMoment } from "moment-range"
import { forEach, sortBy, pick, without, partition, orderBy, reduce, every, clone, isEmpty } from "lodash"

import { adjustOrderPosition, moveProductionOrder, updateDatesOfItem } from "./helpers"

const moment = extendMoment(Moment)

const joinGaps = gaps => {
  const sortedGaps = sortBy(gaps, gap => gap.start.valueOf())
  if (sortedGaps.length <= 1) return gaps
  const joinedGaps = sortedGaps.reduce(
    (acc, currGap) => {
      const lastGap = acc.pop()
      lastGap.end.add(2)
      const newGap = lastGap.add(currGap)
      if (!newGap) {
        lastGap.end.subtract(2)
        return [...acc, lastGap, currGap]
      }
      return [...acc, newGap]
    },
    [sortedGaps.shift()]
  )
  return joinedGaps
}

const getHolidaysGaps = (
  holidays // don't memoizeOne in some case it return the shifted end of a range
) =>
  joinGaps(
    holidays.map(holiday => moment.range(moment(holiday.dateFrom).startOf("day"), moment(holiday.dateTo).endOf("day")))
  )

const getGapsInRegion = (start, end, weekendDays, holidays) => {
  const region = moment.range(start, end)
  const weekDaysRegion = region.snapTo("day")
  const weekDays = Array.from(weekDaysRegion.by("day"))
  const weekendDaysGaps = weekDays.filter(weekDay => weekendDays.includes(weekDay.day())).map(day => day.range("day"))
  const holidayGaps = getHolidaysGaps(holidays).filter(holiday => region.overlaps(holiday))
  const gaps = [...weekendDaysGaps, ...holidayGaps]
  return joinGaps(gaps)
}

const sumUpGaps = gaps => gaps.reduce((acc, curr) => acc + curr.valueOf() + 1, 0)

export const moveActivity = (nextState, id, difference) => {
  const {
    holidays = [],
    settings: { weekendDays = [0, 6] },
  } = nextState

  const activity = clone(nextState.activities[id])
  const { startDate: oldStart, endDate: oldEnd, productionProcessStep = false, planTime: hasPlanTime } = activity

  let planTime
  const oldStartDate = new Date(oldStart)
  const oldEndDate = new Date(oldEnd)
  if (!hasPlanTime) {
    // Recalculate planTime as fallback
    planTime = oldEndDate - oldStartDate
    const oldGaps = getGapsInRegion(oldStartDate, oldEndDate, weekendDays, holidays)
    planTime -= productionProcessStep ? 0 : sumUpGaps(oldGaps)
  } else {
    planTime = hasPlanTime * 1000
  }

  // Which direction
  const movedForward = difference > 0

  let newStartDate, newEndDate
  if (movedForward) {
    newStartDate = new Date(oldStartDate.getTime() + difference)
    newEndDate = new Date(newStartDate.getTime() + planTime)
  } else {
    newEndDate = new Date(oldEndDate.getTime() + difference)
    newStartDate = new Date(newEndDate.getTime() - planTime)
  }

  // Weekends and holidays only count in for non-production steps
  if (!productionProcessStep) {
    let newGaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
    if (movedForward) {
      // Move start forward as long as it is inside a gap
      while (newGaps.length > 0 && newGaps[0].contains(newStartDate)) {
        const gap = newGaps.shift()
        newStartDate = new Date(gap.end.valueOf() + 1) // Move it outside the gap
        newEndDate = new Date(newStartDate.getTime() + planTime)
        newGaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays) // Maybe the updated region start in a gap again
      }

      // Move the end forward until it matches the plantime
      let newPlantime = newEndDate.getTime() - newStartDate.getTime()
      let newGapsDuration = sumUpGaps(newGaps)
      while (newPlantime - newGapsDuration !== planTime) {
        newEndDate = new Date(newStartDate.getTime() + planTime + newGapsDuration)
        newGaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
        newGapsDuration = sumUpGaps(newGaps)
        newPlantime = newEndDate.getTime() - newStartDate.getTime()
      }
    } else {
      // Move end backwards as long as it is inside a gap
      while (newGaps.length > 0 && newGaps[newGaps.length - 1].contains(newEndDate)) {
        const gap = newGaps.pop()
        newEndDate = new Date(gap.start.valueOf() - 1) // Move it outside the gap
        newStartDate = new Date(newEndDate.getTime() - 1)
        newGaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
      }

      // Move the start backwards until it matches the plantime
      let newPlantime = newEndDate.getTime() - newStartDate.getTime()
      let newGapsDuration = sumUpGaps(newGaps)
      while (newPlantime - newGapsDuration !== planTime) {
        newStartDate = new Date(newEndDate.getTime() - planTime - newGapsDuration)
        newGaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
        newGapsDuration = sumUpGaps(newGaps)
        newPlantime = newEndDate.getTime() - newStartDate.getTime()
      }
    }
  }

  const movedActivity = {
    ...activity,
    planTime: Math.floor(planTime / 1000),
    startDate: moment(newStartDate).format(),
    endDate: moment(newEndDate).format(),
  }

  nextState.activities = {
    ...nextState.activities,
    [id]: movedActivity,
  }
  const productionOrdersMoved = {}
  forEach(activity.productionOrders, productionOrderId => {
    productionOrdersMoved[productionOrderId] = moveProductionOrder(nextState, productionOrderId, difference)
  })
  const allProductionOrderFixed = !isEmpty(productionOrdersMoved) && every(productionOrdersMoved, moved => !moved)
  if (allProductionOrderFixed) {
    nextState.activities = {
      ...nextState.activities,
      [id]: activity,
    }
  }
  adjustOrderPosition(nextState, activity.orderPositionId)
  return [nextState, allProductionOrderFixed]
}

export const updateActivity = (nextState, activity, newStart, newEnd) => {
  const {
    holidays = [],
    settings: { weekendDays = [0, 6] },
  } = nextState

  let newStartDate = new Date(newStart)
  let newEndDate = new Date(newEnd)
  let gaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)

  while (gaps.length > 0 && gaps[0].contains(newStartDate)) {
    const gap = gaps.shift()
    newStartDate = new Date(gap.end.valueOf() + 1) // Move it outside the gap
    gaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
  }

  while (gaps.length > 0 && gaps[gaps.length - 1].contains(newEndDate)) {
    const gap = gaps.pop()
    newEndDate = new Date(gap.start.valueOf() - 1) // Move it outside the gap
    gaps = getGapsInRegion(newStartDate, newEndDate, weekendDays, holidays)
  }

  // Calculate new planTime
  let planTime = newEndDate - newStartDate
  planTime -= sumUpGaps(gaps)

  return {
    ...activity,
    startDate: moment(newStartDate).format(),
    endDate: moment(newEndDate).format(),
    planTime: Math.floor(planTime / 1000),
  }
}

export const adjustActivity = (nextState, id) => {
  const activity = nextState.activities[id]
  const productionOrders = pick(nextState.productionOrders, activity.productionOrders)

  nextState.activities = {
    ...nextState.activities,
    [id]: updateDatesOfItem(activity, productionOrders),
  }
  adjustOrderPosition(nextState, activity.orderPositionId)
}

export const adjustActivitySiblings = (nextState, activityBeforeMove, activityAfterMove) => {
  const { activityId, orderPositionId, position } = activityBeforeMove
  const { activities, orderPositions } = nextState

  const adjustNexts = new Date(activityAfterMove.endDate) - new Date(activityBeforeMove.endDate) > 0 // only adjust next activities if the change was towards future
  const adjustPrevs = new Date(activityAfterMove.startDate) - new Date(activityBeforeMove.startDate) < 0 // only adjust previous activities if the change was towards past

  const siblings = pick(activities, without(orderPositions[orderPositionId].activities, activityId))
  const activityPosition = parseInt(position, 10)
  const [predecessors, successors] = partition(siblings, ac => parseInt(ac.position, 10) < activityPosition) // partition avoids two filtering operations over the same amount of items
  const nexts = orderBy(successors, op => parseInt(op.position, 10), ["asc"])
  const prevs = orderBy(predecessors, op => parseInt(op.position, 10), ["desc"])

  if (adjustNexts) {
    reduce(
      nexts,
      (prevActivity, currentActivity) => {
        if (!prevActivity) {
          return false
        }

        const prevEndDate = Date.parse(prevActivity.endDate)
        const startDate = Date.parse(currentActivity.startDate)

        if (prevEndDate > startDate) {
          // need to move the sibling to avoid overlap
          moveActivity(nextState, currentActivity.activityId, prevEndDate - startDate)
          return nextState.activities[currentActivity.activityId]
        }

        // If this activity hasn't moved, skip all those after it
        return false
      },
      activityAfterMove
    )
  }

  if (adjustPrevs) {
    reduce(
      prevs,
      (prevActivity, currentActivity) => {
        if (!prevActivity) {
          return false
        }
        const prevStartDate = Date.parse(prevActivity.startDate)
        const endDate = Date.parse(currentActivity.endDate)

        if (endDate > prevStartDate) {
          // need to move the sibling to avoid overlap
          moveActivity(nextState, currentActivity.activityId, -(endDate - prevStartDate), "backward")
          return nextState.activities[currentActivity.activityId]
        }

        // If this activity hasn't moved, skip all those after it
        return false
      },
      activityAfterMove
    )
  }
}
