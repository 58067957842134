import React from "react"
import { get, keys, map, mapValues } from "lodash"
import { FormattedMessage, IntlShape } from "react-intl"
import { grey } from "@mui/material/colors"

import { Column, progressTypes } from "../../pages/Shared/interfaces"
import StatusTypes, { StatusNames } from "../../types/StatusTypes"
import {
  BooleanCell,
  DateCell,
  DateTimeCell,
  DecimalCell,
  DifferenceCell,
  EnumCell,
  GridUser,
  IconCell,
  IntegerCell,
  SimpleGridUser,
  getStyleById,
} from "./cellRenderers"
import {
  DateHeader,
  DateTimeHeader,
  NumberHeader,
  SelectHeader,
  SelectHeaderCustomValues,
  SelectHeaderMultipleCustomValuesFunc,
  SelectOperationType,
  SelectYesNot,
} from "./filterHeaders"
import { exportBoolean } from "./export"
import { Message, User, getUsername } from "../UserName/UserName"
import {
  allDecimalsNumberFilter,
  inRangeDateFilter,
  inRangeDateTimeFilter,
  numberFilter,
  operationTypeFilter,
  simpleEqualFilter,
  statusFilter,
} from "./filterMethods"
import SettingsBackupRestore from "../Icons/SettingsBackupRestore"

export const statusColumn = (accessor: string) => ({
  accessor: (row: { [x: string]: number }) => StatusNames[get(row, accessor)],
  Cell: EnumCell(
    ({ value }: { value: keyof typeof StatusNames }) => <FormattedMessage id={`type.status.${value}`} />,
    (value: keyof typeof StatusTypes) => getStyleById(StatusTypes[value])
  ),
  filter: statusFilter,
  Filter: SelectHeaderMultipleCustomValuesFunc(
    map(keys(StatusNames), statusKey => ({
      label: `type.status.${StatusNames[statusKey]}`,
      value: statusKey,
    }))
  ),
  id: accessor,
  export: ({ value }: { value: keyof typeof StatusNames }) => ({
    id: `type.status.${value}`,
  }),
})

export const statusIdColumn: Column = {
  id: "statusId",
  translationId: "item.status",
  accessor: "statusId",
  Header: <FormattedMessage id="item.status" />,
  showTooltip: false,
  ...statusColumn("statusId"),
}

export const createdByFullNameColumn: Column = {
  id: "createdBy.fullName",
  translationId: "item.createdBy",
  accessor: "createdBy",
  Header: <FormattedMessage id="item.createdBy" />,
  Cell: GridUser,
  export: ({ value }: { value: User }, formatMessage: (arg0: Message) => string): string =>
    getUsername(value, formatMessage),
}

export const ownedByFullNameColumn: Column = {
  id: "ownedBy.fullName",
  translationId: "item.ownedBy",
  accessor: "ownedBy",
  Header: <FormattedMessage id="item.ownedBy" />,
  Cell: GridUser,
  export: ({ value }: { value: User }, formatMessage: (arg0: Message) => string): string =>
    getUsername(value, formatMessage),
}

export const locationNameColumn: Column = {
  id: "location.name",
  translationId: "term.location",
  accessor: "location.name",
  Header: <FormattedMessage id="term.location" />,
}

export const dateColumn = {
  Cell: DateCell,
  Filter: DateHeader,
  filter: inRangeDateFilter,
}

export const dateTimeColumn = {
  Cell: DateTimeCell,
  Filter: DateTimeHeader,
  filter: inRangeDateTimeFilter,
}

export const reportedEndDateColumn: Column = {
  id: "reportedEndDate",
  translationId: "common.timestamp",
  accessor: "reportedEndDate",
  Header: <FormattedMessage id="common.timestamp" />,
  ...dateColumn,
}

export const lastProgressDateColumn: Column = {
  id: "lastProgressDate",
  translationId: "term.lastProgressDate",
  accessor: "lastProgressDate",
  Header: <FormattedMessage id="term.lastProgressDate" />,
  ...dateColumn,
}

export const createdOnColumn: Column = {
  id: "createdOn",
  translationId: "item.createdOn",
  accessor: "createdOn",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.createdOn" />,
}

export const startDateColumn: Column = {
  id: "startDate",
  translationId: "item.start",
  accessor: "startDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.start" />,
}

export const startDateTimeColumn: Column = {
  id: "startDate",
  translationId: "item.start",
  accessor: "startDate",
  Cell: DateTimeCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.start" />,
}

export const endDateColumn: Column = {
  id: "endDate",
  translationId: "item.end",
  accessor: "endDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.end" />,
}

export const endDateTimeColumn: Column = {
  id: "endDate",
  translationId: "item.end",
  accessor: "endDate",
  Cell: DateTimeCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.end" />,
}

export const statusDateColumn: Column = {
  id: "statusDate",
  translationId: "common.dateStatusChange",
  accessor: "statusDate",
  Header: <FormattedMessage id="common.dateStatusChange" />,
  ...dateColumn,
}

export const terminationDateColumn: Column = {
  id: "terminationDate",
  translationId: "term.deliveryDate",
  accessor: "terminationDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="term.deliveryDate" />,
}

export const orderDateColumn: Column = {
  id: "orderDate",
  translationId: "item.orderDate",
  accessor: "orderDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="item.orderDate" />,
}

export const projectNumberColumn: Column = {
  id: "project.projectNumber",
  translationId: "term.projectNumber",
  accessor: "project.projectNumber",
  Header: <FormattedMessage id="term.projectNumber" />,
}
export const projectNameColumn: Column = {
  id: "project.projectName",
  translationId: "term.projectName",
  accessor: "project.projectName",
  Header: <FormattedMessage id="term.projectName" />,
}

export const processNumberColumn: Column = {
  id: "process.processNumber",
  translationId: "term.projectNumber",
  accessor: "process.processNumber",
  Header: <FormattedMessage id="term.projectNumber" />,
}

export const processNameColumn: Column = {
  id: "process.processName",
  translationId: "term.projectName",
  accessor: "process.processName",
  Header: <FormattedMessage id="term.projectName" />,
}

export const processCustomerNumberColumn: Column = {
  id: "process.customer.customerNumber",
  translationId: "term.customerNumber",
  accessor: "process.customer.customerNumber",
  Header: <FormattedMessage id="term.customerNumber" />,
}

export const processCustomerNameColumn: Column = {
  id: "process.customer.customerName",
  translationId: "term.customerName",
  accessor: "process.customer.customerName",
  Header: <FormattedMessage id="term.customerName" />,
}

export const customerColumn = (
  formatMessage: IntlShape["formatMessage"],
) => ({
  id: "customer",
  translationId: "term.customer",
  accessor: (row: { customer: { customerName: string } }) =>
    row?.customer?.customerName ?? formatMessage({ id: "common.unknown" }),
  Header: <FormattedMessage id="term.customer" />,
})

export const articleAmountColumn: Column = {
  id: "articleAmount",
  translationId: "term.articleAmount",
  accessor: "articleAmount",
  Cell: DecimalCell,
  Filter: NumberHeader,
  Header: <FormattedMessage id="term.articleAmount" />,
  width: 50,
}

export const articleNumberColumn: Column = {
  id: "article.articleNumber",
  translationId: "term.articleNumber",
  accessor: "article.articleNumber",
  Header: <FormattedMessage id="term.articleNumber" />,
}

export const articleColumn: Column = {
  id: "article.articleName",
  translationId: "term.article",
  accessor: "article.articleName",
  Header: <FormattedMessage id="term.article" />,
}

export const articleNameColumn: Column = {
  id: "article.articleName",
  translationId: "term.articleName",
  accessor: "article.articleName",
  Header: <FormattedMessage id="term.articleName" />,
}

export const pendingColumn: Column = {
  id: "pending",
  translationId: "item.pending",
  accessor: (row: { pending: boolean }): boolean => !row.pending,
  Cell: BooleanCell,
  Filter: SelectHeaderCustomValues([
    { value: true, label: "pages.planningObjects.orders.list.pending.off" },
    { value: false, label: "pages.planningObjects.orders.list.pending.on" },
  ]),
  Header: <FormattedMessage id="item.pending" />,
  showTooltip: false,
}

export const planAnywayColumn: Column = {
  id: "planAnyway",
  translationId: "term.planAnywayColumn",
  accessor: "planAnyway",
  Header: <FormattedMessage id="term.planAnywayColumn" />,
  Cell: BooleanCell,
  Filter: SelectYesNot,
  width: 40,
  export: exportBoolean,
  showTooltip: false,
}

export const orderPositionNumberColumn: Column = {
  id: "orderPositionNumber",
  translationId: "term.orderPosition",
  accessor: "orderPositionNumber",
  Filter: NumberHeader,
  Header: <FormattedMessage id="term.orderPosition" />,
  width: 50,
}

export const productionOrderNumberColumn: Column = {
  id: "productionOrderNumber",
  translationId: "term.productionOrderNumber",
  accessor: "productionOrderNumber",
  Header: <FormattedMessage id="term.productionOrderNumber" />,
}

export const articleGroupNameColumn: Column = {
  id: "article.articleGroup.articleGroupName",
  translationId: "term.articleGroup",
  accessor: "article.articleGroup.articleGroupName",
  Header: <FormattedMessage id="term.articleGroup" />,
}

export const valueAddColumn: Column = {
  id: "valueAddName",
  translationId: "common.name",
  accessor: "valueAddName",
  filterable: true,
  Header: <FormattedMessage id="common.name" />,
}

export const troubleTypeColum: Column = {
  id: "troubleType",
  translationId: "term.troubleType",
  accessor: "troubleType.troubleTypeName",
  Header: <FormattedMessage id="term.troubleType" />,
}

export const commentColumn: Column = {
  id: "comment",
  accessor: "comment",
  Header: <FormattedMessage id="item.comment" />,
  style: { textAlign: "center" },
}

export const userColumn: Column = {
  id: "user",
  translationId: "term.user",
  accessor: "user.fullName",
  Header: <FormattedMessage id="term.user" />,
  Cell: SimpleGridUser("user"),
}

export const attachmentsColumn: Column = {
  id: "item.id",
  translationId: "item.hasAttachments",
  accessor: "hasAttachment",
  Header: <FormattedMessage id="item.hasAttachments" />,
  Cell: BooleanCell,
  Filter: SelectYesNot,
  filter: simpleEqualFilter,
}

const getDuration = (duration: number, durationUnit: string) => {
  switch (durationUnit) {
    default:
      return (duration || 0) / 60
    case "hours":
      return (duration || 0) / (60 * 60)
    case "days":
      return (duration || 0) / (60 * 60 * 24)
  }
}

export const durationByUnitColumn = (
  propertyName: string,
  durationUnit: string,
  formatMessage: IntlShape["formatMessage"],
  withoutHeader: boolean
) => {
  const column: Column = {
    accessor: (row: { [x: string]: number }) => getDuration(row[propertyName], durationUnit),
    Cell: DecimalCell,
    Filter: NumberHeader,
    filter: numberFilter,
    id: propertyName,
    style: {
      textAlign: "right",
    },
  }
  const headerColumnId = `${formatMessage({ id: `pages.reports.charts.labels.${propertyName}` })} (${formatMessage({
    id: `type.time.${durationUnit}`,
  })})`
  if (!withoutHeader) {
    column.Header = <FormattedMessage id={headerColumnId} />
  }
  return column
}

export const itemStatusColumn: Column = {
  ...statusColumn("status.statusId"),
  id: "item.status",
  translationId: "item.status",
  Header: <FormattedMessage id="item.status" />,
}

export const termStatusColumn: Column = {
  id: "status",
  translationId: "term.status",
  Header: <FormattedMessage id="term.status" />,
  ...statusColumn("status"),
}

export const resourceNameColumn: Column = {
  id: "resource.resourceName",
  translationId: "term.resource",
  accessor: "resource.resourceName",
  filterable: true,
  Header: <FormattedMessage id="term.resource" />,
}

export const decimalColumn = {
  filter: numberFilter,
  Filter: NumberHeader,
  Cell: DecimalCell,
}

export const requiredCapacityColumn: Column = {
  id: "requiredCapacity",
  translationId: "term.requiredCapacity",
  accessor: (row: { requiredCapacity: number }) => row.requiredCapacity || 0,
  Header: <FormattedMessage id="term.requiredCapacity" />,
  style: { textAlign: "right" },
  summary: true,
  ...decimalColumn,
}

export const integerColumn = {
  filter: numberFilter,
  Filter: NumberHeader,
  Cell: IntegerCell,
}
export const progressTypeColumn: Column = {
  id: "type",
  accessor: "type",
  Header: <FormattedMessage id="term.progressType" />,
  Cell: EnumCell(
    ({ value }: { value: keyof typeof progressTypes }) => (
      <FormattedMessage id={progressTypes?.[value]?.messageId || "common.unknown"} />
    ),
    () => ({
      backgroundColor: grey[400],
    })
  ),
  filter: simpleEqualFilter,
  Filter: SelectHeader(mapValues(progressTypes, ({ messageId }) => <FormattedMessage id={messageId} />)),
  export: ({ value }: { value: keyof typeof progressTypes }) => ({
    id: progressTypes?.[value]?.messageId || "common.unknown",
  }),
}

export const progressAmountColumn: Column = {
  id: "progressAmount",
  translationId: "item.amount",
  accessor: (row: { amount: number }) => row.amount || 0,
  Header: <FormattedMessage id="item.amount" />,
  style: { textAlign: "right" },
  summary: true,
  ...integerColumn,
}

export const progressTargetAmountColumn: Column = {
  id: "targetAmount",
  accessor: (row: { targetAmount: number }) => row.targetAmount || 0,
  Header: <FormattedMessage id="term.targetAmount" />,
  style: { textAlign: "right" },
  summary: true,
  ...integerColumn,
}

export const actualConsumptionColumn: Column = {
  id: "actualConsumption",
  translationId: "term.compacfoam.actualConsumption",
  accessor: "actualConsumption",
  Header: <FormattedMessage id="term.compacfoam.actualConsumption" />,
  summary: true,
  ...integerColumn,
}

export const predictedConsumptionColumn: Column = {
  id: "predictedConsumption",
  translationId: "term.compacfoam.predictedConsumption",
  accessor: "predictedConsumption",
  Header: <FormattedMessage id="term.compacfoam.predictedConsumption" />,
  summary: true,
  ...integerColumn,
}

export const progressRejectTypeColumn: Column = {
  id: "progressRejectType",
  translationId: "term.rejectType",
  accessor: (row: { rejectType: { name: string } }) => row?.rejectType?.name || "",
  Header: <FormattedMessage id="term.rejectType" />,
}

export const operationTypeColumn: Column = {
  id: "operationType",
  accessor: "parallelOperation",
  Header: <FormattedMessage id="term.operation" />,
  Filter: SelectOperationType,
  filter: operationTypeFilter,
  Cell: ({ cell }: { cell: { value: unknown } }) => (
    <FormattedMessage id={cell.value ? "common.secondary" : "common.primary"} />
  ),
}

export const differencePercentageColumn: Column = {
  id: "diffPerc",
  accessor: "diffPerc",
  translationId: "pages.reports.charts.labels.diffPerc",
  Header: <FormattedMessage id="pages.reports.charts.labels.diffPerc" />,
  Cell: DifferenceCell,
  Filter: NumberHeader,
  filter: allDecimalsNumberFilter,
  style: { textAlign: "center" },
  export: ({ value }: { value: number }) => Math.round(value),
}

export const switchedOffColumn: Column = {
  id: "switchedOff",
  accessor: "switchedOff",
  Header: <FormattedMessage id="common.stand" />,
  Cell: IconCell(SettingsBackupRestore),
  Filter: SelectHeaderCustomValues([
    {
      label: "common.revoked",
      value: true,
    },
    {
      label: "common.notRevoked",
      value: false,
    },
  ]),
  export: ({ value }: { value: unknown }) => ({ id: value ? "common.revoked" : null }),
}
