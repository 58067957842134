import { createStore, applyMiddleware } from "redux"
import { routerMiddleware } from "connected-react-router"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "@redux-devtools/extension"

import { createPersistor } from "./persist"
import createReducers from "./reducers"
import rootSaga from "./sagas"
import normalizeMiddleware from "./middleware/normalize"
import socketsMiddleware from "./middleware/sockets"
import { isDevelopment } from "./utils/env"
import { history } from "./history"

const sagaMiddleware = createSagaMiddleware()
const reducers = createReducers(history)

const middleware = applyMiddleware(normalizeMiddleware, routerMiddleware(history), sagaMiddleware, socketsMiddleware)
const withMiddleware = isDevelopment
  ? composeWithDevTools({ maxAge: 35, actionsDenylist: ["SOCKET_RECEIVED_CONFLICTS_COUNT"] })(middleware)
  : middleware

export const store = withMiddleware(createStore)(reducers)
createPersistor(store)
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof reducers>
