import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider } from "@apollo/client"
import { PersistGate } from "redux-persist/es/integration/react"
import { Provider } from "react-redux"
import CssBaseline from "@mui/material/CssBaseline"
import { ConnectedRouter } from "connected-react-router"
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { AuthProvider } from "react-oidc-context"

import App from "./containers/App"

import apolloClient from "./apolloClient"
import { getPersistor } from "./persist"
import { store } from "./store"
import { history } from "./history"
import InitializeApp from "./containers/InitializeApp"
import theme from "./theme"
import { oidcConfig } from "./oidcConfig"

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={getPersistor()}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          <AuthProvider {...oidcConfig}>
            <InitializeApp>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </MuiThemeProvider>
              </StyledEngineProvider>
            </InitializeApp>
          </AuthProvider>
        </ConnectedRouter>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
