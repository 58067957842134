import ActionTypes from "../constants/ActionTypes"

const initialState = {
  conflictsCount: null,
}

export default (state = initialState, action) => {
  if (action.type === ActionTypes.SOCKET_RECEIVED_CONFLICTS_COUNT) {
    return {
      ...state,
      conflictsCount: action.payload,
    }
  }
  return state
}
