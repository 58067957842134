import { put as putEffect, select, takeLatest } from "redux-saga/effects"
import { denormalize } from "normalizr"
import { get as getLodash } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence, doAPIRequest } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import { get, put } from "../../utils/http"
import orderPositionDetailsSchema from "../../schemas/planningObjects/orderPositionDetails"
import orderPositionDetailsSchemaSingle from "../../schemas/planningObjects/orderPositionDetails/item"
import { createAction } from "../../utils/redux"
import { getUserData } from "../selectors"
import { preparePrefixUrl } from "../../utils/api"

const prepareOrderPosition = (orderPosition, orderPositionFromBackend, planAnyway) => ({
  ...orderPositionFromBackend,
  orderPosition: {
    ...getLodash(orderPositionFromBackend, "orderPosition"),
    planAnyway,
    technology: {
      ...getLodash(orderPositionFromBackend, "orderPosition.technology"),
      ...getLodash(orderPosition, "orderPosition.technology"),
    },
    processTemplate: {
      ...getLodash(orderPositionFromBackend, "orderPosition.processTemplate"),
      ...getLodash(orderPosition, "orderPosition.processTemplate"),
    },
  },
})

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ORDER_POSITION_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/positions`,
    schema: orderPositionDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.orderPositionId}`,
    prepareResponse: response => [response],
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ORDER_POSITION_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/positions`,
    method: put,
    schema: orderPositionDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.data.orderPosition.orderPositionId}`,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [
      {
        ...response,
        orderPosition: { handOverDate: null, processTemplate: null, ownedBy: null, ...response.orderPosition },
      },
    ],
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/positions`,
    method: put,
    schema: orderPositionDetailsSchema,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}/planon`,
    prepareRequest: ({ data }) => data,
    prepareResponse: response => [response],
  }),
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_REVOKE_PLAN,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/positions`,
    method: put,
    schema: orderPositionDetailsSchema,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}/planoff`,
    prepareRequest: ({ data }) => data,
    prepareResponse: response => [response],
  }),
  takeLatest(ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_SEQUENCE, function* getOrderPositionAndPlan(action) {
    const {
      payload: { id, data: orderPositionDetails, planAnyway, versionIdentifier },
    } = action

    try {
      let orderPositionDetailsFromBackend
      if (!planAnyway) {
        const { token } = yield select(getUserData)
        const { id: simulationId } = yield select(state => state.simulation)
        orderPositionDetailsFromBackend = yield doAPIRequest({
          endPoint: preparePrefixUrl(`${API_SERVER_PLANNING_PROCESS}/positions/${id}`, simulationId),
          method: get,
          token,
        })
        orderPositionDetailsFromBackend = {
          ...orderPositionDetailsFromBackend,
          orderPosition: { ...orderPositionDetailsFromBackend.orderPosition, versionIdentifier },
        }
      } else {
        orderPositionDetailsFromBackend = yield select(state =>
          denormalize(parseInt(id, 10), orderPositionDetailsSchemaSingle, state.entities)
        )
      }

      yield putEffect(
        createAction(ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_REQUEST, {
          id,
          data: prepareOrderPosition(orderPositionDetails, orderPositionDetailsFromBackend, planAnyway),
        })
      )
    } catch (e) {
      const { message } = e
      yield putEffect(
        createAction(ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_FAILURE, {
          response: { message },
        })
      )
    }
  }),
]
