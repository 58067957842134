import React from "react"

import { FormattedMessage } from "react-intl"
import { Column } from "../interfaces"
import { NumberHeader } from "../../../components/DataGrid/filterHeaders"
import {
  articleNumberColumn,
  articleNameColumn,
  articleGroupNameColumn,
  orderPositionNumberColumn,
  locationNameColumn,
  processCustomerNumberColumn,
  processCustomerNameColumn,
  statusIdColumn,
  createdByFullNameColumn,
  createdOnColumn,
  orderDateColumn,
  terminationDateColumn,
  ownedByFullNameColumn,
  processNameColumn,
  processNumberColumn,
  pendingColumn,
  startDateTimeColumn,
  endDateTimeColumn,
} from "../../../components/DataGrid/columns"

const ColumnsConfigActivities: Column[] = [
  articleNumberColumn,
  articleNameColumn,
  articleGroupNameColumn,
  {
    id: "positionNumber",
    translationId: "term.positionNumber",
    accessor: "positionNumber",
    Filter: NumberHeader,
    Header: <FormattedMessage id="term.positionNumber" />,
    width: 50,
  },
  orderPositionNumberColumn,
  {
    id: "valueAdd.valueAddName",
    translationId: "term.activityType",
    accessor: "valueAdd.valueAddName",
    Header: <FormattedMessage id="term.activityType" />,
  },
  locationNameColumn,
  startDateTimeColumn,
  endDateTimeColumn,
  processCustomerNumberColumn,
  processCustomerNameColumn,
  statusIdColumn,
  pendingColumn,
  processNumberColumn,
  processNameColumn,
  ownedByFullNameColumn,
  terminationDateColumn,
  orderDateColumn,
  createdOnColumn,
  createdByFullNameColumn,
]

export default ColumnsConfigActivities
