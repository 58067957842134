import reduceReducers from "reduce-reducers"
import { createAPIRequestReducer, initialState } from "../api"
import ActionTypes from "../../constants/ActionTypes"

const listReducer = createAPIRequestReducer(ActionTypes.BASEDATA_RESOURCES_LIST)

function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.BASEDATA_RESOURCE_DETAILS_CREATE_SUCCESS:
      return {
        ...state,
        entities: [...state.entities, ...action.payload.result],
      }
    default:
      return state
  }
}

export default reduceReducers(listReducer, updateReducer)
