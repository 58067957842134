import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import ActionTypes from "../../../constants/ActionTypes"
import WorkbenchTypes from "../../../types/WorkbenchTypes"
import PersistorKey from "../../../types/PersistorKey"

export const initialState = {
  progressSettings: {
    hideRegisterButton: false,
  },
  defaultWorkbench: WorkbenchTypes.Operations,
  Operations: {
    filterResource: { scope: "all", id: 0 },
    filterTimespan: "twoDays",
    filterWorker: 0,
    filterShowPlausi: false,
    filterShowStatusDone: false,
    filterSearch: "",
    selectedLocation: -1,
    selectedId: -1,
  },
  Activities: {
    filterTimespan: "twoDays",
    filterShowPlausi: false,
    filterShowStatusDone: false,
    filterSearch: "",
    selectedLocation: -1,
    selectedId: -1,
  },
}

const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.UI_WORKBENCH_WORKBENCH_SET_DEFAULT: {
      const { workbench } = payload
      return {
        ...state,
        defaultWorkbench: workbench,
      }
    }

    case ActionTypes.UI_WORKBENCH_CHANGE_SHOWFINISHED_OPERATION: {
      const { filterShowStatusDone } = payload
      return {
        ...state,
        Operations: {
          ...state.Operations,
          filterShowStatusDone,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_CHANGE_SHOWPLAUSI_OPERATION: {
      const { filterShowPlausi } = payload
      return {
        ...state,
        Operations: {
          ...state.Operations,
          filterShowPlausi,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_CHANGE_SEARCH_OPERATION: {
      const { filterSearch } = payload
      return {
        ...state,
        Operations: {
          ...state.Operations,
          filterSearch,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_CHANGE_SHOWFINISHED_ACTIVITY: {
      const { filterShowStatusDone } = payload
      return {
        ...state,
        Activities: {
          ...state.Activities,
          filterShowStatusDone,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_CHANGE_SHOWPLAUSI_ACTIVITY: {
      const { filterShowPlausi } = payload
      return {
        ...state,
        Activities: {
          ...state.Activities,
          filterShowPlausi,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_CHANGE_SEARCH_ACTIVITY: {
      const { filterSearch } = payload
      return {
        ...state,
        Activities: {
          ...state.Activities,
          filterSearch,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_OPERATIONS_FILTER_SET: {
      return {
        ...state,
        Operations: {
          ...state.Operations,
          ...payload,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_ACTIVITIES_FILTER_SET: {
      return {
        ...state,
        Activities: {
          ...state.Activities,
          ...payload,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_FILTER_CLEAR_OPERATION: {
      return {
        ...state,
        Operations: {
          ...state.Operations,
          filterResource: { scope: "all", id: 0 },
          filterTimespan: "twoDays",
          filterWorker: 0,
          selectedLocation: -1,
        },
      }
    }
    case ActionTypes.UI_WORKBENCH_FILTER_CLEAR_ACTIVITY: {
      return {
        ...state,
        Activities: {
          ...state.Activities,
          filterTimespan: "twoDays",
          selectedLocation: -1,
        },
      }
    }

    case ActionTypes.UI_WORKBENCH_PROGRESS_SETTINGS_HIDE_REGISTER_SET: {
      return {
        ...state,
        progressSettings: {
          ...state.progressSettings,
          hideRegisterButton: payload,
        },
      }
    }

    default:
      return state
  }
}

export default persistReducer(
  {
    key: PersistorKey.ui_workbench,
    storage,
  },
  reducer
)
