import React from "react"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"

const PaperAddress = ({ customer: { city, country, customerName, customerNumber, street, zipCode }, showName }) => (
  <div>
    {showName && (
      <Typography variant="subtitle1">
        {customerNumber} | {customerName}
      </Typography>
    )}
    {zipCode && (
      <Typography variant="body2">
        {street} | {zipCode} {city} | {country}
      </Typography>
    )}
  </div>
)

PaperAddress.defaultProps = {
  showName: false,
}

PaperAddress.propTypes = {
  customer: PropTypes.object.isRequired,
  showName: PropTypes.bool,
}

export default PaperAddress
