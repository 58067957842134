import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import resourcesSchema from "../../schemas/basedata/resources"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import resourceDetailsSchema from "../../schemas/basedata/resources/details"
import { put, post } from "../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_RESOURCES_LIST,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/resources`,
    schema: resourcesSchema,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_RESOURCE_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/resources`,
    schema: resourceDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_RESOURCE_DETAILS_CREATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/resources`,
    schema: resourcesSchema,
    method: post,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [response],
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_RESOURCES_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/resources`,
    schema: resourcesSchema,
    method: put,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [response],
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_RESOURCE_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/resources`,
    schema: resourceDetailsSchema,
    method: put,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareRequest: payload => payload.data,
  }),
]
