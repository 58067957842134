import { FormattedMessage } from "react-intl"
import React, { Fragment } from "react"
import makeStyles from "@mui/styles/makeStyles"

import AdamosButton from "./AdamosButton"

const useStyles = makeStyles({
  orContainer: {
    width: "100%",
    color: "white",
    display: "flex",
    alignItems: "center",
    padding: 16,
  },
  line: {
    background: "white",
    height: 1,
    width: "100%",
    flex: 1,
  },
  or: {
    padding: 4,
  },
})

const AdamosButtonWithOr = props => {
  const classes = useStyles()
  return (
    <Fragment>
      <div className={classes.orContainer}>
        <span className={classes.line} />
        <span className={classes.or}>
          <FormattedMessage id="term.or" />
        </span>
        <span className={classes.line} />
      </div>
      <AdamosButton {...props} />
    </Fragment>
  )
}

export default AdamosButtonWithOr
