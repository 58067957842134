import { schema } from "normalizr"
import productionOrder from "../productionOrders/item"
import operations from "../operations"

export default new schema.Entity(
  "productionOrderDetails",
  {
    productionOrder,
    operations,
  },
  {
    idAttribute: value => value.productionOrder.productionOrderId,
  }
)
