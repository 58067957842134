import { delay, takeEvery, put } from "redux-saga/effects"
import ActionTypes from "../../constants/ActionTypes"
import { removeSnackbarMessage } from "../../actions/ui/snackbar"

const MESSAGE_SHOW_DURATION = 6500 // 5 seconds

export function* removeMessage(action) {
  const {
    payload: { id },
  } = action
  yield delay(MESSAGE_SHOW_DURATION)
  yield put(removeSnackbarMessage(id))
}

export default [takeEvery(ActionTypes.SNACKBAR_MESSAGE_CREATE, removeMessage)]
