import moment from "moment"
import { clone, forEach, pick, map, mapValues, max, min, orderBy, reduce, uniq } from "lodash"

import { setConflictsByResource } from "./conflicts"
import { moveActivity } from "./helpers"

export const adjustOrderPosition = (nextState, id) => {
  const orderPosition = nextState.orderPositions[id]
  const activities = pick(nextState.activities, orderPosition.activities)

  nextState.orderPositions = {
    ...nextState.orderPositions,
    [id]: updateDatesOfItem(orderPosition, activities),
  }

  adjustProject(nextState, orderPosition.projectId)
}

const adjustProject = (nextState, id) => {
  const project = nextState.projects[id]
  const orderPositions = pick(nextState.orderPositions, project.orderPositions)

  nextState.projects = {
    ...nextState.projects,
    [id]: updateDatesOfItem(project, orderPositions),
  }
}

export const cloneStore = state => mapValues(state, branch => clone(branch))

export const moveOrderPosition = (nextState, id, difference) => {
  const { activities } = nextState
  const orderPosition = nextState.orderPositions[id]
  const nextEndDate = moment(orderPosition.endDate).add(difference)
  const nextStartDate = moment(orderPosition.startDate).add(difference)

  nextState.orderPositions = {
    ...nextState.orderPositions,
    [id]: {
      ...orderPosition,
      endDate: nextEndDate.format(),
      startDate: nextStartDate.format(),
    },
  }

  const sortedActivities = orderBy(
    map(orderPosition.activities, activityId => activities[activityId]),
    [({ startDate }) => new Date(startDate)],
    [difference > 0 ? "asc" : "desc"]
  )
  reduce(
    sortedActivities,
    (accumulatedTimeDifference, activity) => {
      const { activityId, endDate: oldEnd, startDate: oldStart } = activity

      const [, allProductionOrderFixed] = moveActivity(nextState, activityId, accumulatedTimeDifference)
      if (allProductionOrderFixed) {
        return difference
      }
      const movedActivity = nextState.activities[activityId]
      const { endDate: newEnd, startDate: newStart } = movedActivity

      return difference > 0 ? new Date(newEnd) - new Date(oldEnd) : new Date(newStart) - new Date(oldStart)
    },
    difference
  )

  adjustOrderPosition(nextState, id)
}

export const updateDatesOfItem = (item, subitems) => {
  const endDates = map(subitems, "endDate")
  const startDates = map(subitems, "startDate")

  const maxEndDate = max(map(endDates, d => moment(d)))
  const minEndDate = min(map(startDates, d => moment(d)))

  return {
    ...item,
    endDate: maxEndDate && maxEndDate.format(),
    startDate: minEndDate && minEndDate.format(),
  }
}

export const updateConflictsForResources = (nextState, resources) =>
  forEach(uniq(resources), resource => setConflictsByResource(nextState, resource))
