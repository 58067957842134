import moment from "moment"
import { useEffect, useState } from "react"

// parse date string from top component (usually from redux form)
const actOnInput = value => (value ? moment(value) : null)

export const useChangeValue = value => {
  const [valueToAccept, changeValue] = useState(actOnInput(value))
  useEffect(() => {
    changeValue(actOnInput(value))
  }, [value])
  const change = val => changeValue(val) // cause datePicker send 2 argument
  return [valueToAccept, change]
}
