import reduceReducers from "reduce-reducers"

import { createAPIRequestReducer, initialState } from "../api"
import ActionTypes from "../../constants/ActionTypes"

const orderPsoitionDetailsReducer = createAPIRequestReducer(ActionTypes.PLANNING_OBJECTS_ORDER_POSITION_DETAILS_LOAD)

function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITION_DETAILS_LOAD_RESET:
      return {
        ...state,
        isLoaded: false,
      }

    default:
      return state
  }
}

export default reduceReducers(orderPsoitionDetailsReducer, updateReducer)
