import moment from "moment"

export const getFormattedDate = (mom, format) => {
  if (!mom || !moment.isMoment(mom)) {
    return null
  }
  if (format) {
    return mom.format(format)
  }
  return mom.format()
}
