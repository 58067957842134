import { myPlaneusModules } from "./modules"

export const LOCALSTORAGE_STARTPAGE_KEY = "planeo.startPage"
export const LOCALSTORAGE_CHANGELOGS_KEY = "planeo.changeLogs"

export const initialModulesAreas = [
  {
    moduleLicenceId: 20,
    id: "myplaneus",
    moduleLicenceName: "My Planeus",
    moduleLicences: [
      {
        moduleLicenceId: 21,
        id: myPlaneusModules.userinfo,
        moduleLicenceName: "User info",
        licenceId: 0,
        maxNumberOfUsers: 0,
        isMenu: true,
        isLicence: false,
        siteSequence: 1,
        expiryDate: null,
        usersAssigned: 1,
        areas: [],
        role: {
          roleId: 1,
          roleName: "Admin",
        },
      },
      {
        moduleLicenceId: 22,
        id: myPlaneusModules.modulepreferences,
        moduleLicenceName: "Module Preferences",
        licenceId: 0,
        maxNumberOfUsers: 0,
        isMenu: true,
        isLicence: false,
        siteSequence: 1,
        expiryDate: null,
        usersAssigned: 1,
        areas: [],
        role: {
          roleId: 1,
          roleName: "Admin",
        },
      },
      {
        moduleLicenceId: 222,
        id: myPlaneusModules.languages,
        moduleLicenceName: "Languages",
        licenceId: 0,
        maxNumberOfUsers: 0,
        isMenu: true,
        isLicence: false,
        siteSequence: 1,
        expiryDate: null,
        usersAssigned: 1,
        areas: [],
        role: {
          roleId: 1,
          roleName: "Admin",
        },
      },
    ],
  },
]
