export default {
  date: {
    year: { year: "numeric" },
    month: { month: "long" },
    long: { year: "numeric", month: "long", day: "2-digit" },
  },
  number: {
    duration: {
      maximumSignificantDigits: 2,
    },
  },
}
