import { normalize } from "normalizr"

import {
  productionPlanningModules,
  administrationModules,
  configurationModules,
  planningObjectsTabs,
} from "../../constants/modules"
import accessibleLicenceModulesSchema from "../../schemas/accessibleLicenceModules"
import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "../api"

const identifierMapping = {
  conflictradar: productionPlanningModules.conflictRadar,
  workbench: productionPlanningModules.workbench,
  planningobjects: productionPlanningModules.planningObjects,
  usermanagement: administrationModules.userManagement,
  amountunits: "amountUnits",
  conflictmatrix: "conflictMatrix",
  productionplanning: "productionPlanning",
  articlegroups: configurationModules.articleGroups,
  productionorders: planningObjectsTabs.productionOrders,
  usermodulesandroles: "userModulesAndRoles",
  objectnames: configurationModules.objectNames,
  resourcegroups: configurationModules.resourceGroups,
  myplaneus: "myPlaneus",
  reporting: productionPlanningModules.reports,
}

export function formatIdentifier(identifier) {
  const lowerCaseIdentifier = identifier.toLowerCase()
  if (lowerCaseIdentifier in identifierMapping) return identifierMapping[lowerCaseIdentifier]
  return identifier
}

const formatIdentifiers = licenceModules =>
  licenceModules.map(module => ({
    ...module,
    id: formatIdentifier(module.id),
    moduleLicences: module.moduleLicences.map(licence => ({
      ...licence,
      id: formatIdentifier(licence.id),
      areas: licence.areas.map(area => ({
        ...area,
        id: formatIdentifier(area.id),
      })),
    })),
  }))

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ACCESSIBLE_LICENCE_MODULES_LIST_REQUEST:
      return {
        ...initialState,
        isPending: true,
      }

    case ActionTypes.ACCESSIBLE_LICENCE_MODULES_LIST_SUCCESS: {
      const { accessibleLicenceModules } = action.payload
      const result = normalize(formatIdentifiers(accessibleLicenceModules), accessibleLicenceModulesSchema)
      return {
        ...state,
        isLoaded: true,
        isPending: false,
        ...result,
      }
    }

    default:
      return state
  }
}
