import { schema } from "normalizr"
import userSchema from "../../users/item"

export default new schema.Entity(
  "projects",
  {
    createdBy: userSchema,
    updatedBy: userSchema,
    ownedBy: userSchema,
  },
  { idAttribute: "projectId" }
)
