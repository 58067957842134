import { schema } from "normalizr"
import status from "../../status/item"

export default new schema.Entity(
  "resourceStatusList",
  {
    status,
  },
  { idAttribute: "statusId" }
)
