import ActionTypes from "../../constants/ActionTypes"
import { setValue } from "../../utils/storage"

export default function (state = {}, action) {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.SCREEN_LOCK_SUCCESS: {
      setValue("screenToken", payload)
      return state
    }
    case ActionTypes.SCREEN_SET_LANGUAGE: {
      return {
        ...state,
        screenLanguage: payload,
      }
    }
    default:
      return state
  }
}
