import ActionTypes from "../../../constants/ActionTypes"
import { createAction } from "../../../utils/redux"

const action = (actionType, payload) => createAction(ActionTypes[`UI_TIMELINE_${actionType}`], payload)

export function closePlanningDrawer() {
  return action("CLOSE_PLANNING_DRAWER")
}

export function closeProductionDrawer() {
  return action("CLOSE_PRODUCTION_DRAWER")
}

export function dragColumnHeaderWidth(headerWidth) {
  return action("DRAG_COLUMN_HEADER_WIDTH", { headerWidth })
}

export function setDefaultTimeline(timeline) {
  return action("TIMELINE_SET_DEFAULT", { timeline })
}

export function showPreviousRange() {
  return action("SHOW_PREVIOUS_RANGE")
}

export function showNextRange() {
  return action("SHOW_NEXT_RANGE")
}

export function setTimelineWindowSize(windowSize) {
  return action("SET_WINDOW_SIZE", { windowSize })
}

export function showToday() {
  return action("SHOW_TODAY")
}

export function timelineMoved(range) {
  const { end, start } = range
  const animationDisabled = !!range.animationDisabled
  return action("TIMELINE_MOVED", { range: { end, start, animationDisabled } })
}

export function togglePersonelCapacity() {
  return action("TOGGLE_PERSONEL_PLAN")
}

export function toggleProductionTimeline() {
  return action("TOGGLE_PRODUCTION_TIMELINE")
}

export function toggleResourcesTimeline() {
  return action("TOGGLE_RESOURCES_TIMELINE")
}

export function toggleShowArchivedResources() {
  return action("TOGGLE_SHOWARCHIVEDSOURCES_TIMELINE")
}

export function toggleShowFinishedItems() {
  return action("TOGGLE_SHOWFINISHED_TIMELINE")
}

export function toggleShowFreeResources() {
  return action("TOGGLE_SHOWFREERESOURCES_TIMELINE")
}

export function toggleShowFinishedProjects() {
  return action("TOGGLE_SHOWFINISHEDPROJECTS_TIMELINE")
}

export function toggleShowVisibleArea() {
  return action("TOGGLE_SHOWVISIBLEAREA_TIMELINE")
}

export function toggleShowDateFilter() {
  return action("TOGGLE_SHOWDATEFILTER_TIMELINE")
}

export function toggleShowDelayedConflicts() {
  return action("TOGGLE_SHOWDELAYEDCONFLICTS_TIMELINE")
}

export function setDateFilter(payload) {
  return action("SET_SHOWDATEFILTER_TIMELINE", payload)
}

export function setSelectedUserFilter(payload) {
  return action("USER_FILTER_SET", payload)
}

export const timelineRerender = () => createAction(ActionTypes.UI_TIMELINE_FORCERERENDER)
export const timelineRerenderPlanningClear = () => createAction(ActionTypes.UI_TIMELINE_FORCERERENDER_PLANNING_CLEAR)
export const timelineRerenderProductionClear = () =>
  createAction(ActionTypes.UI_TIMELINE_FORCERERENDER_PRODUCTION_CLEAR)
export const timelineRerenderResourceClear = () => createAction(ActionTypes.UI_TIMELINE_FORCERERENDER_RESOURCE_CLEAR)
