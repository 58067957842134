import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import { API_SERVER_USER } from "../../constants/api"
import rolesSchema from "../../schemas/roles"

export default [
  APIRequestSequence({
    actionType: ActionTypes.ROLES_LIST,
    endPoint: `${API_SERVER_USER}/roles`,
    schema: rolesSchema,
  }),
]
