import { uniq } from "lodash"
import reduceReducers from "reduce-reducers"

import { initialState } from "../api"
import { createPlanningObjectsReducer } from "../planningObjects"
import ActionTypes from "../../constants/ActionTypes"

const planningObjectReducer = createPlanningObjectsReducer("projects")

function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_CREATE_SUCCESS:
      if (!action.payload.result) {
        return state
      }
      return {
        ...state,
        entities: uniq([...action.payload.result, ...state.entities]),
      }
    default:
      return state
  }
}

export default reduceReducers(updateReducer, planningObjectReducer)
