import { connectRouter } from "connected-react-router"
import { History } from "history"

import entities from "./entities"
import globalsettings from "./globalsettings"
import user from "./user"
import pages from "./pages"
import ui from "./ui"
import lists from "./lists"
import form from "./form"
import terminal from "./terminal"
import timeline from "./timeline"
import timelineStatus from "./timelineStatus"
import uploads from "./uploads"
import conflicts from "./conflicts"
import simulation from "./simulation"
import ActionTypes from "../constants/ActionTypes"
import appConfig from "./appConfig/appConfig"

interface Action {
  type: string
  payload: unknown
}
interface State {
  entities: unknown
  form: unknown
  globalsettings: unknown
  lists: unknown
  pages: unknown
  router: unknown
  terminal: unknown
  timeline: unknown
  timelineStatus: unknown
  ui: unknown
  uploads: unknown
  user: unknown
  conflicts: unknown
  appConfig: unknown
  simulation: unknown
}

const undefinedStates: State = {
  entities: undefined,
  form: undefined,
  globalsettings: undefined,
  lists: undefined,
  pages: undefined,
  router: undefined,
  terminal: undefined,
  timeline: undefined,
  timelineStatus: undefined,
  ui: undefined,
  uploads: undefined,
  user: undefined,
  conflicts: undefined,
  appConfig: undefined,
  simulation: undefined,
}

export default (history: History) =>
  (state: State = undefinedStates, action: Action) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (action.type === ActionTypes.RESET_STORE) {
      // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unsafe-assignment
      state = { ...undefinedStates, user: state.user }
    }
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      entities: entities(state.entities, action),
      form: form(state.form, action),
      globalsettings: globalsettings(state.globalsettings, action),
      lists: lists(state.lists, action),
      pages: pages(state.pages, action),
      router: connectRouter(history)(state.router, action),
      terminal: terminal(state.terminal, action),
      // @ts-ignore old piece of code
      timeline: timeline(state.timeline, action, undefined, state.globalsettings),
      timelineStatus: timelineStatus(state.timelineStatus, action),
      ui: ui(state.ui, action),
      uploads: uploads(state.uploads, action),
      user: user(state.user, action),
      conflicts: conflicts(state.conflicts, action),
      appConfig: appConfig(state.appConfig, action),
      simulation: simulation(state.simulation, action),
    }
  }
