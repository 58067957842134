import { all } from "redux-saga/effects"
import { uniq } from "lodash"
import root from "./root"

const calculateDuplicateSagas = a => a.length - uniq(a).length

export default function* () {
  const duplicateSagas = calculateDuplicateSagas(root)
  if (duplicateSagas > 0) console.error(`${duplicateSagas} duplicate Saga(s) found`) // eslint-disable-line
  yield all(root)
}
