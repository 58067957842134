import { every, get, toLower } from "lodash"

import { genericRequestState } from "../api"
import ActionTypes from "../../constants/ActionTypes"
import StatusTypes from "../../types/StatusTypes"
import handlePlanningObjectsSharedActions from "./handlePlanningObjectsSharedActions"

const INITIAL_STATE = {
  statusChange: {
    ...genericRequestState,
    objectNotExist: false,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.PAGES_ORDER_POSITIONS_RESET:
      return INITIAL_STATE
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_REQUEST:
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isError: false,
          isPending: true,
          errorCode: null,
          detailsObject: null,
        },
      }
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_SUCCESS: {
      const planningSucceeded = every(action.payload.entities.orderPositions, { statusId: StatusTypes.planned })
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isPending: false,
          isFinished: planningSucceeded,
          isError: !planningSucceeded,
          errorCode: null,
          detailsObject: null,
        },
      }
    }
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_PLAN_FAILURE: {
      const errorCode = get(action, "payload.response.errorCode")
      const detailsObject = get(action, "payload.response.detailsObject")
      const message = get(action, "payload.response.message")
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isPending: false,
          isError: true,
          errorCode,
          detailsObject,
          objectNotExist: toLower(message) === "objectnotexists",
        },
      }
    }

    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_REVOKE_PLAN_REQUEST:
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isError: false,
          isPending: true,
        },
      }
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_REVOKE_PLAN_SUCCESS: {
      const unPlanningSucceeded = every(action.payload.entities.orderPositions, { statusId: StatusTypes.notPlanned })
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isPending: false,
          isFinished: unPlanningSucceeded,
          isError: !unPlanningSucceeded,
        },
      }
    }
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITIONS_REVOKE_PLAN_FAILURE:
      return {
        ...state,
        statusChange: {
          ...state.statusChange,
          isPending: false,
          isError: true,
        },
      }
    default:
      return handlePlanningObjectsSharedActions(action, state, "orderPositions")
  }
}
