import { schema } from "normalizr"
import createdBy from "../../users/item"
import article from "../articles/item"

export default new schema.Entity(
  "technologies",
  {
    createdBy,
    article,
  },
  {
    idAttribute: "technologyId",
  }
)
