import { schema } from "normalizr"
import valueAdd from "../../valueAdds/item"

export default new schema.Entity(
  "processTemplateItems",
  {
    valueAdd,
  },
  { idAttribute: "templateItemId" }
)
