import ActionTypes from "../../constants/ActionTypes"

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UI_BREADCRUMBS_SET: {
      const { payload } = action
      return payload
    }
    case ActionTypes.UI_BREADCRUMBS_CLEAR: {
      return null
    }
    default:
      return state
  }
}
