import { schema } from "normalizr"
import article from "../articles/item"
import createdBy from "../../users/item"

export default new schema.Entity(
  "templates",
  {
    createdBy,
    article,
  },
  {
    idAttribute: "templateId",
  }
)
