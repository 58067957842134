import { schema } from "normalizr"
import project from "../projects/item"
import customer from "../../basedata/customers/item"
import orderPositions from "../orderPositions"

export default new schema.Entity(
  "projectDetails",
  {
    project,
    customer,
    orderPositions,
  },
  {
    idAttribute: value => value.project.projectId,
  }
)
