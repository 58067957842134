import { Availability } from "./availability"
import { reduceTimeItemsToTime } from "./reduceTimeItemsToTime"
import { trimTimeSlicesIntoRange } from "./trimTimeSlicesIntoRange"

import { forcedTimezone } from "./forcedTimezone"

export const calculateGapsWithinRange = (gapConfiguration, start, end) => {
  const gapStream =
    gapConfiguration instanceof Availability
      ? gapConfiguration.reset(start)
      : new Availability(gapConfiguration, start, forcedTimezone)
  const gapsDuringTimeRange = trimTimeSlicesIntoRange(gapStream.read(end), start, end)
  const gapTimeInRange = reduceTimeItemsToTime(gapsDuringTimeRange)

  return gapTimeInRange // in seconds
}
