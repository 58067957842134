import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Typography, Alert } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { lowerCase } from "lodash"
import MessageTypes from "../../../types/MessageTypes"
import { lightgreen, lightred, warningOrange } from "../../../constants/colors"

const useStyles = makeStyles({
  alert: {
    color: "white",
    boxShadow: "rgba(0,0,0,0.5) 0px 4px 10px",
  },
  alertError: {
    backgroundColor: lightred,
  },
  alertInfo: {
    backgroundColor: lightgreen,
  },
  alertWarning: {
    backgroundColor: warningOrange,
  },
})

const SnackbarBase = ({ id, messageId, type, onClose, ...props }) => {
  const classes = useStyles()
  classes.alertClassName = `${classes.alert}`
  switch (type) {
    case MessageTypes.ERROR:
      classes.alertClassName += ` ${classes.alertError}`
      break
    case MessageTypes.INFO:
      classes.alertClassName += ` ${classes.alertInfo}`
      break
    case MessageTypes.WARNING:
      classes.alertClassName += ` ${classes.alertWarning}`
      break
    default:
      classes.alertClassName += ` ${classes.alertInfo}`
      break
  }

  return (
    <Alert
      key={id}
      id={id}
      className={classes.alertClassName}
      onClose={onClose}
      severity={lowerCase(type)}
      variant="filled"
    >
      <Typography variant="body2">
        <FormattedMessage id={messageId} {...props} />
      </Typography>
    </Alert>
  )
}
SnackbarBase.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default SnackbarBase
