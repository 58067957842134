import ActionTypes from "../../../constants/ActionTypes"
import { APIRequestSequence } from "../../api"
import { API_SERVER_BASEDATA } from "../../../constants/api"
import { put } from "../../../utils/http"
import technologiesSchema from "../../../schemas/basedata/technologies/index"
import technologyDetailsSchema from "../../../schemas/basedata/technologies/details"

export default [
  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TECHNOLOGIES_LIST,
    endPoint: `${API_SERVER_BASEDATA}/technologies`,
    schema: technologiesSchema,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TECHNOLOGY_DETAILS_LOAD,
    endPoint: `${API_SERVER_BASEDATA}/technologies`,
    schema: technologyDetailsSchema,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}`,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TECHNOLOGY_DETAILS_UPDATE,
    endPoint: `${API_SERVER_BASEDATA}/technologies`,
    schema: technologyDetailsSchema,
    method: put,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}`,
    prepareRequest: ({ data }) => data,
    prepareResponse: technology => technology,
  }),
]
