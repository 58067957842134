import React from "react"
import withStyles from "@mui/styles/withStyles"
import cn from "classnames"

export const styles = theme => ({
  bodyPadded: {
    flex: "auto",
    overflow: "auto",
    overflowX: "hidden",
    padding: theme.spacing(2),
  },
  bodyNotPadded: {
    flex: "auto",
    overflow: "auto",
    overflowX: "hidden",
  },
  displayFlex: {
    display: "flex",
    flexDirection: "column",
  },
})
// renamed SimpleBody because of linting error, default export and normal export
export const BodyWithoutStyles = ({ children, classes, padded, customClassName, displayFlex, style }) => (
  <div
    className={cn([
      padded ? classes.bodyPadded : classes.bodyNotPadded,
      customClassName,
      { [classes.displayFlex]: displayFlex },
    ])}
    style={style}
  >
    {children}
  </div>
)

BodyWithoutStyles.defaultProps = {
  padded: true,
}

export default withStyles(styles)(BodyWithoutStyles)
