import { filter, pick, map, max, min, orderBy, reduce } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { setConflictsByOrderPosition } from "./helpers/conflicts"
import { initialState } from "./initialState"

import {
  adjustOperationSiblings,
  adjustProductionOrder,
  cloneStore,
  moveOrderPosition,
  updateConflictsForResources,
} from "./helpers/helpers"

import {
  changeAttendantOperations,
  getAttendantOperations,
  getSelectedOperations,
  moveOperation,
  newTimelineStateWithNew,
  writeAttendantOperations,
} from "./helpers/operationsHelpers"

export default (state = initialState, action) => {
  const { payload } = action
  const { selection } = state

  switch (action.type) {
    case ActionTypes.TIMELINE_PROJECT_MOVE: {
      const { item } = payload
      // change id after jump from planning object
      if (selection?.length === 1 && selection[0].id !== item.itemId) {
        selection[0].id = item.itemId
      }
      const project = state.projects[item.itemId]
      const timeDifference = new Date(item.start) - new Date(project.startDate)
      let nextState = newTimelineStateWithNew(payload, state)
      nextState = reduce(
        selection,
        (tempState, { id }) => {
          const selectedProject = tempState.projects[id]
          const sortedOrderPositions = orderBy(
            map(selectedProject.orderPositions, opId => tempState.orderPositions[opId]),
            [op => new Date(op.startDate)],
            [timeDifference > 0 ? "asc" : "desc"]
          )
          reduce(
            sortedOrderPositions,
            (currentTimeDifference, orderPosition) => {
              const { orderPositionId, startDate: oldStart, endDate: oldEnd } = orderPosition
              moveOrderPosition(tempState, orderPositionId, timeDifference)
              const movedOrderPosition = tempState.orderPositions[orderPositionId]
              const { endDate: newEnd, startDate: newStart } = movedOrderPosition
              return timeDifference > 0 ? new Date(newEnd) - new Date(oldEnd) : new Date(newStart) - new Date(oldStart)
            },
            timeDifference
          )
          return tempState
        },
        nextState
      )

      setConflictsByOrderPosition(nextState)

      return nextState
    }

    case ActionTypes.TIMELINE_ITEM_CLONED: {
      const { activity } = payload
      const { activityId } = activity

      const { orderPositionId } = activity
      const orderPosition = state.orderPositions[orderPositionId]

      const updatedState = {
        activities: {
          ...state.activities,
          [activityId]: {
            ...activity,
          },
        },
        orderPositions: {
          ...state.orderPositions,
          [orderPositionId]: {
            ...orderPosition,
            activities: [...orderPosition.activities, activityId],
          },
        },
      }

      return {
        ...state,
        ...updatedState,
      }
    }

    case ActionTypes.TIMELINE_CLOSE_GAPS_RIGHT: {
      const affectedResources = []
      const nextState = reduce(
        getSelectedOperations(state),
        (tempState, operation) => {
          /*
           * This code will find the leftmost next item in the productionOrder and the leftmost next operation on the resource
           * Afterwards the item is moved to the smaller start of both
           */

          const productionOrder = tempState.productionOrders[operation.productionOrderId]
          const operationsInOrder = pick(tempState.operations, productionOrder.operations)
          const operationsInThisResource = filter(tempState.operations, { resource: operation.resource })

          const itemsRight = startDate => startDate >= new Date(operation.endDate)
          const endDates = map(operationsInOrder, ({ startDate }) => new Date(startDate))
          endDates.push(new Date(productionOrder.endDate)) // we will not move the item beyond the end of the production order
          const startOfNextItems = filter(endDates, itemsRight)

          const nextOperations = filter(
            map(operationsInThisResource, ({ startDate }) => new Date(startDate)),
            itemsRight
          )

          const nextEdge = min([...startOfNextItems, ...nextOperations])
          const moveDifference = nextEdge - Date.parse(operation.endDate) - (operation.bufferTime || 0) * 1000

          const attendantOperations = getAttendantOperations(tempState, operation.productionOrderId)
          const { movedOperation } = moveOperation(tempState, operation.operationId, moveDifference, 0, {}, true)
          const changedAttendantOperations = changeAttendantOperations(tempState, movedOperation, attendantOperations)
          writeAttendantOperations(tempState.operations, changedAttendantOperations)
          // eslint-disable-next-line no-param-reassign
          tempState.operations[operation.operationId] = movedOperation
          adjustOperationSiblings(tempState, operation, movedOperation, false)
          adjustProductionOrder(tempState, operation.productionOrderId)
          affectedResources.push(operation.resource)
          return tempState
        },
        cloneStore(state)
      )
      setConflictsByOrderPosition(nextState)
      updateConflictsForResources(nextState, affectedResources)
      return nextState
    }

    case ActionTypes.TIMELINE_CLOSE_GAPS_LEFT: {
      const affectedResources = []
      const nextState = reduce(
        getSelectedOperations(state),
        (tempState, operation) => {
          /*
           * This code will find the rightmost previous item in the productionOrder and the rightmost previous operation on the resource
           * Afterwards the item is moved to the bigger end of both
           */

          const productionOrder = tempState.productionOrders[operation.productionOrderId]
          const operationsInOrder = pick(tempState.operations, productionOrder.operations)
          const operationsInThisResource = filter(tempState.operations, { resource: operation.resource })

          const itemsLeft = endDate => endDate <= new Date(operation.startDate)
          const endDates = map(
            operationsInOrder,
            ({ bufferTime, endDate }) => new Date(Date.parse(endDate) + (bufferTime || 0) * 1000)
          )
          endDates.push(new Date(productionOrder.startDate)) // we will not move the item before the start of the production order
          const endingOfPreviousItems = filter(endDates, itemsLeft)

          const previousOperations = filter(
            map(operationsInThisResource, ({ endDate }) => new Date(endDate)),
            itemsLeft
          )

          const nextEdge = max([...endingOfPreviousItems, ...previousOperations])
          const moveDifference = nextEdge - Date.parse(operation.startDate)

          const attendantOperations = getAttendantOperations(tempState, operation.productionOrderId) // attOperation
          const { movedOperation } = moveOperation(tempState, operation.operationId, moveDifference, 0, null, true)
          const changedAttendantOperations = changeAttendantOperations(tempState, movedOperation, attendantOperations)
          writeAttendantOperations(tempState.operations, changedAttendantOperations)
          // eslint-disable-next-line no-param-reassign
          tempState.operations[operation.operationId] = movedOperation
          adjustOperationSiblings(tempState, operation, movedOperation, false)
          adjustProductionOrder(tempState, operation.productionOrderId)
          affectedResources.push(operation.resource)
          return tempState
        },
        cloneStore(state)
      )
      setConflictsByOrderPosition(nextState)
      updateConflictsForResources(nextState, affectedResources)
      return nextState
    }

    case ActionTypes.TIMELINE_TRIGGER_RAW: {
      const nextState = cloneStore(state)
      return nextState
    }

    default:
      return state
  }
}
