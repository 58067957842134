import { push } from "connected-react-router"
import { takeLatest, put } from "redux-saga/effects"
import ActionTypes from "../../../../constants/ActionTypes"
import { Routes } from "../../../../constants/routes"

function navigate(subPath, idName) {
  return function* navigateToDetails(action) {
    const id = action.payload[idName]
    const url = `${Routes.PLANNING_OBJECTS_DEFAULT}/${subPath}/${id}`
    yield put(push(url))
  }
}

export default [takeLatest(ActionTypes.UI_PLANNING_OBJECTS_DETAILS_PROJECT_NAVIGATE, navigate("projects", "projectId"))]
