import { push } from "connected-react-router"
import { takeLatest, put } from "redux-saga/effects"
import ActionTypes from "../../../../constants/ActionTypes"
import { Routes } from "../../../../constants/routes"
import { createAction } from "../../../../utils/redux"

function openPanel() {
  return put(createAction(ActionTypes.UI_BASEDATA_ARTICLES_OPEN_PANEL))
}

function navigate(subPath, idName) {
  return function* navigateToDetails(action) {
    const id = action.payload[idName]
    const url = `${Routes.BASEDATA_DEFAULT}/${subPath}/${id}`
    yield put(push(url))
    yield openPanel()
  }
}

export default [takeLatest(ActionTypes.UI_BASEDATA_DETAILS_ARTICLE_NAVIGATE, navigate("articles", "articleId"))]
