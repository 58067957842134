import { combineReducers } from "redux"
import { reducer as defaultReducer } from "redux-form"
import { pick } from "lodash"
import articleCreateForm from "./articleCreateForm"
import resourceCreateForm from "./resourceCreateForm/index"
import timelineFilterForm from "./timelineFilterForm/index"
import workbenchFilterForm from "./workbenchFilterForm/workbenchFilterForm"
import availableHoursPerDayFilterForm from "./availableHoursPerDayFilterForm/availableHoursPerDayFilterForm"
import userForm from "./userForm"

const formReducers = combineReducers({
  articleCreateForm,
  resourceCreateForm,
  timelineFilterForm,
  workbenchFilterForm,
  availableHoursPerDayFilterForm,
  userForm,
})
function rootFormReducer(state, action) {
  const intermediateState = defaultReducer(state, action)
  const partState = formReducers(
    pick(intermediateState, [
      "userForm",
      "articleCreateForm",
      "resourceCreateForm",
      "timelineFilterForm",
      "workbenchFilterForm",
      "availableHoursPerDayFilterForm",
    ]),
    action
  )
  return { ...intermediateState, ...partState }
}
export default rootFormReducer
