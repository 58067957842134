import { normalize } from "normalizr"
import ActionTypes from "../constants/ActionTypes"
// import validate from "./validator"
import { createAction } from "../utils/redux"

export const NORMALIZE = "NORMALIZE"

export default store => next => action => {
  if (!action[NORMALIZE]) return next(action)

  const { schema } = action[NORMALIZE]
  if (!schema) {
    console.error(action.type, ": Cannot normalize without a schema") // eslint-disable-line
    return next(action)
  }
  const { data } = action.payload
  // validate(data, schema)

  const { entities, result } = normalize(data, schema)

  store.dispatch(
    createAction(ActionTypes.NORMALIZE_MIDDLEWARE_SAVE, {
      entities,
      result,
      source: data,
    })
  )

  return next({
    ...action,
    payload: {
      ...action.payload,
      entities,
      result,
    },
  })
}
