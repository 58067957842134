import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import ActionTypes from "../../constants/ActionTypes"

const endPointUrl = `${API_SERVER_PLANNING_PROCESS}/publicHolidays`

export default [
  APIGenericSequence({
    actionType: ActionTypes.BASEDATA_PUBLIC_HOLIDAYS_LIST,
    endPoint: endPointUrl,
  }),
]
