import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useAuth } from "react-oidc-context"
import makeStyles from "@mui/styles/makeStyles"
import * as Colors from "@mui/material/colors"
import DocumentTitle from "react-document-title"

import Form from "./Form"
import { PlaneusCompleteLogo } from "../../theme/Planeus"
import { logIn } from "../../actions/authentication"

const isSSOCustomer = process.env.REACT_APP_SSOCUSTOMER

const useStyles = makeStyles({
  form: {
    width: "30%",
    height: "30%",
  },
  header: {
    margin: "8px",
  },
  error: {
    backgroundColor: Colors.red.A700,
    marginTop: 24,
    borderRadius: 8,
    color: "white",
    padding: 16,
  },
  logo: {
    width: "80%",
    marginBottom: 48,
  },
})

function getLoginError(message) {
  switch (message) {
    case "Invalid username or password":
      return <FormattedMessage id="pages.login.errors.wrongCredentials" />
    case "InvalidUserNameOrPassword":
      return <FormattedMessage id="pages.login.errors.wrongCredentials" />
    case "NumberOfLoginAttemptsExceeded":
      return <FormattedMessage id="pages.login.errors.numberOfLoginAttemptsExceeded" />
    default:
      return <FormattedMessage id="errors.API.500" />
  }
}

const Login = () => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const authentication = useSelector(state => state.user.authentication)
  const dispatch = useDispatch()
  const { loginError } = authentication
  const auth = useAuth()

  const handleSubmit = values => {
    const { username, password } = values
    if (username && password) {
      dispatch(logIn(username, password))
    }
  }
  return (
    <div>
      <PlaneusCompleteLogo className={classes.logo} />
      <DocumentTitle title={formatMessage({ id: "modules.login" })} />
      <Form
        onSubmit={handleSubmit}
        isPending={false}
        isSSOCustomer={isSSOCustomer === "true"}
        onLoginSSO={auth.signinRedirect}
      />
      {loginError && <div className={classes.error}>{getLoginError(loginError.message)}</div>}
    </div>
  )
}

export default Login
