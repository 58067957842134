import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import { uploadFile } from "../../utils/http"

export default [
  APIGenericSequence({
    actionType: ActionTypes.FILE_UPLOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/uploadFile`,
    method: uploadFile,
  }),
]
