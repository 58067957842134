import { head, initial, last, reduce, tail } from "lodash"

/* Merges overlapping time slices of ascending ordered time slices */
/* Exception - when separateGaps is true, we keep gap separate from capacity plans in order to be able to display the reason in the timeline */
export const mergeOrderedTimeSlices = (orderedSlices, separateGaps = false) =>
  reduce(
    tail(orderedSlices),
    (previousItems, item) => {
      const initialPreviousItems = initial(previousItems)
      const previousItem = last(previousItems)

      if (previousItem.end >= item.start - 1000) {
        // If previous is gap
        if (previousItem.type && separateGaps) {
          // If previous gap contains the current item fully
          if (previousItem.end >= item.end) {
            return [...initialPreviousItems, previousItem]
          }
          return [...initialPreviousItems, previousItem, { start: previousItem.end, end: item.end }]
        }
        // If current is gap
        if (item.type && separateGaps) {
          if (previousItem.end >= item.end) {
            return [...initialPreviousItems, 
              { start: previousItem.start, end: item.start }, 
              item,
              { start: item.end, end: previousItem.end }]
          }
          return [...initialPreviousItems, { start: previousItem.start, end: item.start }, item]
        }
        return [
          ...initialPreviousItems,
          { start: previousItem.start, end: item.end > previousItem.end ? item.end : previousItem.end },
        ]
      }
      return [...previousItems, item]
    },
    orderedSlices.length > 0 ? [head(orderedSlices)] : []
  )
