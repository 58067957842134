import React from "react"

import { FormattedMessage } from "react-intl"
import { Column } from "../interfaces"
import { NumberHeader } from "../../../components/DataGrid/filterHeaders"
import {
  ownedByFullNameColumn,
  statusIdColumn,
  startDateColumn,
  endDateColumn,
  createdByFullNameColumn,
  createdOnColumn,
} from "../../../components/DataGrid/columns"

const ColumnsConfigProjects: Column[] = [
  {
    id: "processNumber",
    translationId: "term.projectNumber",
    accessor: "processNumber",
    Header: <FormattedMessage id="term.projectNumber" />,
  },
  {
    id: "processName",
    translationId: "term.projectName",
    accessor: "processName",
    Header: <FormattedMessage id="term.projectName" />,
  },
  ownedByFullNameColumn,
  {
    id: "customer.customerNumber",
    translationId: "term.customerNumber",
    accessor: "customer.customerNumber",
    Header: <FormattedMessage id="term.customerNumber" />,
  },
  {
    id: "customer.customerName",
    translationId: "term.customerName",
    accessor: "customer.customerName",
    Header: <FormattedMessage id="term.customerName" />,
  },
  {
    id: "orderPositionsCount",
    translationId: "term.orderPositions",
    accessor: "orderPositionsCount",
    Filter: NumberHeader,
    Header: <FormattedMessage id="term.orderPositions" />,
    width: 50,
  },
  statusIdColumn,
  startDateColumn,
  endDateColumn,
  createdByFullNameColumn,
  createdOnColumn,
]

export default ColumnsConfigProjects
