import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import ActionTypes from "../../constants/ActionTypes"
import { createGenericRequestReducer, genericRequestState } from "../api"

const INITIAL_STATE = {
  availableReports: [],
  fetch: { ...genericRequestState },
  parameters: {},
  settings: {},
}

const fetchReducer = createGenericRequestReducer(ActionTypes.REPORTS_FETCH)

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        availableReports: action.payload,
        fetch: fetchReducer(state.fetch, action),
      }

    case ActionTypes.PAGES_REPORT_PARAMETERS_SAVE:
      return {
        ...state,
        parameters: { ...state.parameters, ...action.payload },
      }

    case ActionTypes.PAGES_REPORT_SETTINGS_SAVE:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      }

    default:
      return {
        ...state,
        fetch: fetchReducer(state.fetch, action),
      }
  }
}

export default persistReducer(
  {
    key: "pages_report",
    storage,
  },
  reducer
)
