import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { map, noop } from "lodash"
import memoizeOne from "memoize-one"
import withStyles from "@mui/styles/withStyles"

import FormControl from "@mui/material/FormControl"
import ListItemIcon from "@mui/material/ListItemIcon"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import FormHelperText from "@mui/material/FormHelperText"
import { InputLabel } from "@mui/material"

import Bullet from "./Bullet"
import { timelineItemColors } from "../../theme/colors"

const renderBubble = value => <Bullet color={value} />

export const styles = {
  MenuItem: {
    padding: 2,
  },
  MenuList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    maxWidth: 180,
    padding: 10,
  },
  Select: {
    height: 20,
    paddingBottom: 5,
    paddingTop: 5,
  },
  disabled: {
    opacity: 0.5,
  },
  unsetWidth: {
    width: 75,
  },
}

const renderItems = (colors, menuItemClass) =>
  map(colors, color => (
    <MenuItem classes={menuItemClass} key={color} value={color} data-test-id={color}>
      <ListItemIcon>
        <Bullet color={color} />
      </ListItemIcon>
    </MenuItem>
  ))

const handleChange = memoizeOne(onChange => evt => onChange(evt.target.value))

export const ColorPicker = ({
  classes,
  colors,
  defaultValue,
  disabled,
  helperText,
  label,
  onChange,
  onClose,
  onOpen,
  required,
  value,
}) => {
  const menuItemClass = { root: classes.MenuItem }

  return (
    <FormControl variant="standard" required={required}>
      <InputLabel style={{ whiteSpace: "noWrap" }} required={required}>
        {label}
      </InputLabel>
      <Select
        variant="standard"
        classes={{ select: classes.Select }}
        className={cn({ [classes.unsetWidth]: value === "", [classes.disabled]: disabled })}
        disabled={disabled}
        onChange={handleChange(onChange)}
        onClose={onClose}
        onOpen={onOpen}
        MenuProps={{ MenuListProps: { classes: { root: classes.MenuList } } }}
        renderValue={renderBubble}
        value={value || defaultValue}
        role="menu"
      >
        {renderItems(colors, menuItemClass)}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

ColorPicker.propTypes = {
  colors: PropTypes.array,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
}

ColorPicker.defaultProps = {
  colors: timelineItemColors,
  disabled: false,
  defaultValue: timelineItemColors[0],
  helperText: null,
  label: null,
  onChange: noop,
  onClose: noop,
  onOpen: noop,
  required: false,
  value: null,
}

export default withStyles(styles)(ColorPicker)
