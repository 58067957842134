import { useAuth } from "react-oidc-context"
import { get, includes } from "lodash"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { oidcConfig } from "../../oidcConfig"
import { appInit, setStandardMode, setTerminalMode } from "../../actions/app"
import { isTerminalPage } from "../../utils/checkLocation"

const isSSOCustomer = process.env.REACT_APP_SSOCUSTOMER === "true"
const backupAdamosIssuer = "https://id.adamos.com"

export const useInit = (location, authenticated) => {
  const dispatch = useDispatch()
  const isTerminal = isTerminalPage(location)

  const authAdamos = useAuth()
  const authenticatedOnAdamos = authAdamos.isAuthenticated

  const params = new URLSearchParams(location.search)
  const issuerFromURL = params.get("iss")
  const jumpFromADAMOS = includes(issuerFromURL, oidcConfig.authority) || includes(issuerFromURL, backupAdamosIssuer)

  useEffect(() => {
    if (jumpFromADAMOS) {
      authAdamos.signinRedirect()
    }
  }, [issuerFromURL])

  useEffect(() => {
    if (isSSOCustomer && authenticatedOnAdamos && !authenticated) {
      dispatch(appInit(get(authAdamos, "user.access_token"))) // adamos login
    }
  }, [authenticatedOnAdamos])

  useEffect(() => {
    if (isTerminal) {
      dispatch(setTerminalMode())
    } else {
      dispatch(setStandardMode())
    }
    if (!jumpFromADAMOS && !authenticatedOnAdamos) {
      dispatch(appInit()) // page renew + active directory login
    }
  }, [])
}
