import { v4 as uuid } from "uuid"
import ActionTypes from "../../constants/ActionTypes"

export function createSnackbarMessage(type, text, values = undefined, defaultMessage) {
  const id = uuid()

  return {
    type: ActionTypes.SNACKBAR_MESSAGE_CREATE,
    payload: { text, id, values, defaultMessage, type },
  }
}

export function removeSnackbarMessage(id) {
  return {
    type: ActionTypes.SNACKBAR_MESSAGE_REMOVE,
    payload: { id },
  }
}
