import { compact, flatten, get, includes, isEmpty, keyBy, map, mapValues, replace, split } from "lodash"

const customOrderByVariables = (sorted, formatVariable) =>
  flatten(
    map(sorted, sortItem => {
      if (formatVariable) return formatVariable(sortItem)
      return sortItem
    })
  )

const additionalPropsVariablesMapping = (propertyMapping, sorted) =>
  map(sorted, sortItem => {
    const { path } = sortItem
    if (includes(path, "additionalProps")) {
      const [propertyName, propertyValue] = split(replace(path, "additionalProps.", ""), ".")
      const columnItem = get(propertyMapping, propertyName)
      return {
        ...sortItem,
        path: `additionalProps.${columnItem}.${propertyValue}`,
      }
    }
    return sortItem
  })

export const getOrderByAdditionalProps = (propertyMapping, formatVariable) => sorted =>
  additionalPropsVariablesMapping(propertyMapping, customOrderByVariables(sorted, formatVariable))

export const getWhereAdditionalProps = (propertyMapping, formatVariable) => filtered =>
  compact(
    map(additionalPropsVariablesMapping(propertyMapping, filtered), whereItem => {
      if (formatVariable) return formatVariable(whereItem)

      return whereItem
    })
  )

export const mergeAdditionalPropsInItem = (item, name, propertyTypes) => ({
  ...item,
  additionalProps: keyBy(
    map(get(item, name), additionalProp => ({
      ...additionalProp,
      propertyName: get(get(propertyTypes, get(additionalProp, "propertyId")), "propertyName"),
    })),
    "propertyName"
  ),
})

export const mergeArticleAdditionalProps = (item, propertyTypes) =>
  mergeAdditionalPropsInItem(item, "articlePropertyValues", propertyTypes)

export const mergeArticlesAdditionalProps = (items, propertyTypes) => {
  if (isEmpty(propertyTypes)) {
    return items
  }

  return map(items, item => mergeArticleAdditionalProps(item, propertyTypes))
}

// Merges all possible properties in the item, those that have a value and those that don't
export const mergeAllAdditionalProps = (item, name, propertyTypes) => {
  const propertyTypesByName = keyBy(propertyTypes, "propertyName")
  const existingProps = keyBy(get(item, name, []), "propertyId")
  const additionalProps = mapValues(propertyTypesByName, ({ propertyName, id }) => ({
    propertyName,
    propertyId: id,
    value: get(existingProps, `[${id}].value`, null),
  }))

  return {
    ...item,
    additionalProps,
  }
}
