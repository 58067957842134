import { groupBy, keyBy, mapValues } from "lodash"

import ActionTypes from "../../constants/ActionTypes"

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADDITIONAL_PROPERTIES_SUCCESS: {
      const { payload } = action

      const groupedProps = groupBy(payload, "propertyType")

      const props = mapValues(groupedProps, list => ({
        propertyTypes: keyBy(list, "id"),
        mappedProperties: mapValues(keyBy(list, "propertyName"), "id"),
        list,
      }))

      return { ...state, ...props }
    }

    default:
      return state
  }
}
