import { push } from "connected-react-router"
import { takeLatest, put, call, select } from "redux-saga/effects"
import { get } from "lodash"
import { generatePath } from "react-router-dom"

import ActionTypes from "../../constants/ActionTypes"
import { API_SERVER_USER } from "../../constants/api"
import { post } from "../../utils/http"
import { formatAPIResponse } from "../../utils/api"
import { createAction } from "../../utils/redux"
import { Routes } from "../../constants/routes"
import hash from "../../utils/hash"
import { getUserData } from "../selectors"
import { oidcConfig } from "../../oidcConfig"
import { setStandardMode } from "../../actions/app"
import AppMode from "../../types/AppMode"

export function* goToLogin() {
  yield put(createAction(ActionTypes.RESET_STORE))
  yield put(push(Routes.LOGIN))
}

export function* goToTerminalLogin(terminal) {
  yield put(createAction(ActionTypes.RESET_STORE))
  yield put(push(generatePath(Routes.TERMINAL_START, { terminalId: terminal })))
}

function tryLogin(username, password) {
  const options = {
    url: `${API_SERVER_USER}/login`,
    customHeaders: {
      Authorization: `Basic ${username}`,
      AuthorizationHash: `Basic ${`${username}:${hash(password)}`}`,
    },
  }

  return post(options).then(formatAPIResponse)
}

export function* tryLogout(userId = null, token, terminal) {
  const url = terminal ? `${API_SERVER_USER}/terminals/logout` : `${API_SERVER_USER}/logout`
  const customHeaders = token ? { Authorization: `Bearer ${token}` } : {}
  const options = {
    credentials: token ? "same-origin" : "include",
    customHeaders,
    data: {
      userId,
    },
    responseType: "none",
    url,
  }

  yield post(options)
}

function* login(action) {
  try {
    const { username, password } = action.payload
    const user = yield call(tryLogin, username, password)

    yield put(createAction(ActionTypes.USER_LOGIN_SUCCESS, { user }))
  } catch (e) {
    const { message } = e

    const errorPayload = {
      message,
      originalAction: action,
    }
    yield put(createAction(ActionTypes.USER_LOGIN_FAILURE, errorPayload))
  }
}

function* autologinFailed(data) {
  const mode = yield select(state => state.appConfig.mode)
  const isTerminal = mode === AppMode.terminal
  if (isTerminal) {
    yield put(createAction(ActionTypes.USER_AUTO_LOGIN_TERMINAL_FAILURE, data))
  } else {
    yield put(createAction(ActionTypes.USER_AUTO_LOGIN_FAILURE, data))
  }
}

function* autologinSucceeded(data) {
  const mode = yield select(state => state.appConfig.mode)
  const isTerminal = mode === AppMode.terminal
  if (isTerminal) {
    yield put(createAction(ActionTypes.USER_AUTO_LOGIN_TERMINAL_SUCCESS, data))
  } else {
    yield put(createAction(ActionTypes.USER_AUTO_LOGIN_SUCCESS, data))
  }
}

function* checkForValidSessionOverSSOToken(SSOToken) {
  const ssoResponse = yield getTokenOverAdamosToken(SSOToken)
  const json = yield ssoResponse.json()
  if (ssoResponse.status > 201) {
    sessionStorage.removeItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`)
    yield autologinFailed(json)
  } else {
    yield checkForValidSession(json.token)
  }
}

export function* checkForValidSession(token) {
  const response = yield tryCheckToken(token)
  const json = yield response.json()
  if (response.status > 201) {
    yield autologinFailed(json)
  } else {
    yield autologinSucceeded(json)
  }
}

function tryCheckToken(token) {
  /* With token attempt standard authentication - without: attemt active directory authentication */
  const customHeaders = token ? { Authorization: `Bearer ${token}` } : {}
  const options = {
    credentials: token ? "same-origin" : "include",
    headers: {
      ...customHeaders,
      "Content-Type": "application/json",
    },
    method: "GET",
  }
  return call(fetch, `${API_SERVER_USER}/users/check`, options)
}

function getTokenOverAdamosToken(token) {
  /* With token attempt standard authentication - without: attemt active directory authentication */
  const customHeaders = token ? { AuthorizationAdamos: `Bearer ${token}` } : {}
  const options = {
    credentials: token ? "same-origin" : "include",
    headers: {
      ...customHeaders,
      "Content-Type": "application/json",
    },
    method: "GET",
  }
  return call(fetch, `${API_SERVER_USER}/users/check`, options)
}

function* logout(action) {
  const userId = get(action, "payload.userId")
  const token = get(action, "payload.token")

  try {
    yield call(tryLogout, userId, token)
    yield call(goToLogin)
  } catch (e) {
    const { message } = e
    const errorPayload = {
      message,
    }
    yield put(createAction(ActionTypes.USER_LOGOUT_FAILURE, errorPayload))
    yield call(goToLogin)
  }
  yield put(setStandardMode())
}

function* init(SSOtoken) {
  try {
    // token is persisted on localStorage by redux-persist
    const { token } = yield select(getUserData)
    if (token) {
      // If there is a token we bypass active directory authentication and just try to authenticate the token
      yield checkForValidSession(token)
    } else if (SSOtoken.payload.token) {
      // sso token
      yield checkForValidSessionOverSSOToken(SSOtoken.payload.token)
    } else {
      // without token we are either not logged in or have active directory credentials
      yield checkForValidSession()
    }
  } catch (err) {
    yield autologinFailed()
  }
}

function* updateAuthDetails({ payload }) {
  const updatedUser = get(payload, "data[0]", {})
  const { userId: updatedUserId } = updatedUser
  const { userId } = yield select(getUserData)
  if (updatedUserId !== userId) return
  yield put(createAction(ActionTypes.USER_DETAILS_SELF_UPDATE, updatedUser))
}

export default [
  takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
  takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
  takeLatest(ActionTypes.APP_INIT, init),
  takeLatest(ActionTypes.USERS_UPDATE_SUCCESS, updateAuthDetails),
]
