import { isNil } from "lodash"
import ActionTypes from "../../../constants/ActionTypes"

const initialState = {
  showArchived: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOWARCHIVED_TOOGLE: {
      if (!isNil(action.payload)) {
        return {
          ...state,
          showArchived: action.payload,
        }
      }
      return {
        ...state,
        showArchived: !state.showArchived,
      }
    }
    default:
      return state
  }
}
