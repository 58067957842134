import React, { Fragment } from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { grey } from "../../constants/colors"

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: 10,
    fontWeight: 500,
    color: grey,
  },
})

const useStyles = makeStyles(styles)

const PaperWithHeader = ({ children, title }) => {
  const classes = useStyles()
  return (
    <Fragment>
      {title && (
        <Typography className={classes.title} color="textPrimary" variant="body2">
          {title}
        </Typography>
      )}
      <Paper className={classes.root} elevation={1}>
        {children}
      </Paper>
    </Fragment>
  )
}

PaperWithHeader.propTypes = {
  title: PropTypes.node,
}

PaperWithHeader.defaultProps = {
  title: null,
}

export default PaperWithHeader
