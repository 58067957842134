import { takeLatest, put as putEffect } from "redux-saga/effects"
import { createAction } from "../../../utils/redux"
import ActionTypes from "../../../constants/ActionTypes"
import { APIRequestSequence, APIGenericSequence } from "../../api"
import { API_SERVER_PLANNING_PROCESS } from "../../../constants/api"
import projectSchema from "../../../schemas/planningObjects/projectsList"
import projectDetailsSchema from "../../../schemas/planningObjects/projectDetails"
import { post, put } from "../../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/projects`,
    schema: projectDetailsSchema,
    prepareUrl: (endPoint, { projectId }) => `${endPoint}/${projectId}`,
    prepareResponse: response => {
      const project = response
      project.project.ownedBy = project.project.ownedBy || null
      return [project]
    },
  }),
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_CREATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/projects`,
    schema: projectSchema,
    method: post,
    prepareRequest: ({ data }) => data,
    prepareResponse: project => [project],
  }),
  APIGenericSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_CHECK,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/projects/check`,
    method: post,
    prepareRequest: ({ data }) => data,
    prepareResponse: project => [project],
  }),
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/projects`,
    schema: projectDetailsSchema,
    method: put,
    prepareRequest: ({ data }) => data,
    prepareResponse: project => [project],
    prepareUrl: (
      endPoint,
      {
        data: {
          project: { projectId },
        },
      }
    ) => `${endPoint}/${projectId}`,
  }),
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_REVOKE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/projects`,
    schema: projectDetailsSchema,
    method: put,
    prepareResponse: project => [project],
    prepareUrl: (endPoint, projectId) => `${endPoint}/${projectId}/planoff`,
  }),
  takeLatest(ActionTypes.PLANNING_OBJECTS_PROJECT_DETAILS_CREATE_SUCCESS, ({ payload: { result } }) =>
    result ? watchProjectCreateSuccess(result[0]) : _ => _
  ),
]

function* watchProjectCreateSuccess(projectId) {
  yield putEffect(createAction(ActionTypes.UI_PLANNING_OBJECTS_DETAILS_PROJECT_NAVIGATE, { projectId }))
  yield putEffect(createAction(ActionTypes.PAGES_PROJECTS_RESET)) // check flag
}
