const DurationTypes = {
  minute: 1,
  hour: 2,
  day: 3,
  week: 4,
  month: 5,
  year: 6,
}

const DurationMultipliers = {
  1: 60,
  2: 60 * 60,
  3: 60 * 60 * 24,
  4: 60 * 60 * 24 * 7,
  5: (60 * 60 * 24 * 365) / 12,
  6: 60 * 60 * 24 * 365,
}

export default DurationTypes

export const WorkflowDurationTypes = {
  today: {
    seconds: 0,
  },
  twoDays: {
    seconds: DurationMultipliers[DurationTypes.day] * 2,
  },
  fiveDays: {
    seconds: DurationMultipliers[DurationTypes.day] * 5,
  },
  all: {
    seconds: null,
    translationId: "common.all",
  },
}
