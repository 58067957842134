import React from "react"
import PropTypes from "prop-types"

import Body from "./Body"

const Loading = ({ message }) => <Body>{message}</Body>

Loading.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

Loading.defaultProps = {
  message: null,
}

export default Loading
