import React from "react"
import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import { Button } from "@mui/material"
import { FormattedMessage } from "react-intl"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { Routes } from "../../constants/routes"
import { lightblue } from "../../constants/colors"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexGrow: 1,
    backgroundColor: lightblue,
    background: "linear-gradient(150deg, #00a5e5 -10%, #00346e 100%)",
    justifyContent: "center",
  },
  label: {
    color: "white",
    textAlign: "center",
    fontWeight: "300",
    marginBottom: theme.spacing(2),
    display: "inline-block",
  },
  "@media screen and (max-height: 500px)": {
    label: {
      height: 20,
    },
  },
  button: {
    padding: 14,
    fontSize: "1rem",
    width: "100%",
  },
}))

const Autologout = () => {
  const classes = useStyles()
  const history = useHistory()
  const { terminal } = useParams()
  const logoutRedirect = () => {
    if (terminal) {
      history.push(generatePath(Routes.LOGOUT, { terminal }))
    } else {
      history.push(Routes.LOGOUT)
    }
  }
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Grid item>
          <Typography className={classes.label} variant="h5">
            <FormattedMessage id="errors.auth.autologout" />
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item xs={11} sm={8} md={4} lg={2} xl={1}>
            <Box boxShadow={2}>
              <Button
                className={classes.button}
                color="primary"
                disableRipple
                type="submit"
                variant="contained"
                onClick={logoutRedirect}
              >
                <FormattedMessage id="buttons.goToLogin" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default Autologout
