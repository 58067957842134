import { get, difference, forEach, isEmpty, last, map, omit, values, some } from "lodash"
import moment from "moment"
import { call, put, select, takeLatest } from "redux-saga/effects"

import { ActionCreators } from "redux-undo"
import { createAction } from "../../utils/redux"
import { post } from "../../utils/http"

import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence, checkTokenAndRedirect, doAPIRequest } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import { createSnackbarMessage } from "../../actions/ui/snackbar"
import { timelineRerender } from "../../actions/ui/timelines"
import { getSimulationId, getState, getUserData } from "../selectors"
import errors from "../../utils/errors"
import MessageTypes from "../../types/MessageTypes"
import { setTimelineUpdateLoading, unsetTimelineUpdateLoading, filterCleverly } from "../../actions/timelines"

const endPoint = `${API_SERVER_PLANNING_PROCESS}/planningTimelineRaw`
const endPointFetch = `${API_SERVER_PLANNING_PROCESS}/planningTimelineRawFlat`
const endPointFetchProjects = `${API_SERVER_PLANNING_PROCESS}/getTimelineDataByProjectIds`
const endPointOperationReplan = `${API_SERVER_PLANNING_PROCESS}/planningTimelineRaw/operation/successors/replan`

const checkDateRangeRestriction = (updateObj, filterDateRange) => {
  if (isEmpty(updateObj.productionOrders)) {
    return false
  }
  const { fromDate, toDate } = filterDateRange
  return some(updateObj.productionOrders, ({ startDate, endDate }) => {
    const outOfStartDate = moment(startDate).isBefore(fromDate)
    const outOfEndDate = moment(endDate).isAfter(toDate)
    return outOfStartDate || outOfEndDate
  })
}

export default [
  APIGenericSequence({
    actionType: ActionTypes.TIMELINE_DATA_FETCH,
    prepareUrl: (endpoint, payload, state) => {
      const filterDateRange = get(state, "ui.timelines.filterDateRange")
      const { fromDate, toDate } = filterDateRange
      const params = `?fromDate=${fromDate}&toDate=${toDate}`
      return endpoint + params
    },
    endPoint: endPointFetch,
  }),
  takeLatest(ActionTypes.TIMELINE_DATA_FETCH_PROJECTS, function* updateTimelineProjects(action) {
    const affectedProjects = action.payload
    const { token } = yield select(getUserData)
    const simulationId = yield select(getSimulationId)

    let resp = null

    try {
      resp = yield doAPIRequest({
        endPoint: endPointFetchProjects,
        method: post,
        prepareRequest: () => affectedProjects,
        token,
        state: { simulation: { id: simulationId } },
      })

      const { projects, versionIdentifier } = resp

      yield put(
        createAction(ActionTypes.TIMELINE_UPDATE_PROJECTS, {
          projects,
          affectedProjects: affectedProjects.projectIds,
        })
      )
      yield put(
        createAction(ActionTypes.TIMELINE_VERSION_UPDATE, {
          versionIdentifier,
        })
      )
    } catch (e) {
      const skipErrorHandling = yield call(checkTokenAndRedirect, e)
      if (skipErrorHandling) {
        return
      }

      yield put(ActionCreators.undo())
    }
  }),
  takeLatest(ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_LEFT, function* optimizeLeft() {
    const { timeline } = yield select(getState)
    const updateObj = {}

    /* Detect the changed data items and send them to the API */
    forEach(["operations"], group => {
      updateObj[group] = difference(values(timeline.present[group]), values(last(timeline.past)[group]))
    })

    const numberOfChangedOperations = get(updateObj, "operations", []).length
    yield put(
      createSnackbarMessage(MessageTypes.INFO, "timeline.userMessage.updatedOperations", {
        values: numberOfChangedOperations,
      })
    )
  }),
  takeLatest(ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_RIGHT, function* optimizeRight() {
    const { timeline } = yield select(getState)
    const updateObj = {}

    /* Detect the changed data items and send them to the API */
    forEach(["operations"], group => {
      updateObj[group] = difference(values(timeline.present[group]), values(last(timeline.past)[group]))
    })

    const numberOfChangedOperations = get(updateObj, "operations", []).length
    yield put(
      createSnackbarMessage(MessageTypes.INFO, "timeline.userMessage.updatedOperations", {
        values: numberOfChangedOperations,
      })
    )
  }),
  takeLatest(ActionTypes.TIMELINE_ITEM_UPDATE, function* saveOrUndo(action) {
    const { payload: subAction } = action

    const { selection } = yield select(state => state.timeline.present)
    if (!selection || !selection.length) {
      yield put(timelineRerender())
      return
    }

    yield put(setTimelineUpdateLoading())
    try {
      yield put(subAction)
    } catch (err) {
      if (err instanceof errors.UserError) {
        yield put(createSnackbarMessage(MessageTypes.ERROR, err.messageId, err.values, err.message))
      } else {
        yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.timeline.updateError"))
      }

      yield put(unsetTimelineUpdateLoading())
      yield put(createAction(ActionTypes.TIMELINE_TRIGGER_RAW))
      yield put(timelineRerender())
      return
    }
    const { timeline, ui } = yield select(getState)
    const { token } = yield select(getUserData)
    const simulationId = yield select(getSimulationId)
    const updateObj = {}
    if (isEmpty(last(timeline.past))) {
      yield put(timelineRerender())
      yield put(unsetTimelineUpdateLoading())
      return
    }
    /* Detect the changed data items and send them to the API */
    forEach(["activities", "operations", "orderPositions", "productionOrders", "projects", "valueAdds"], group => {
      updateObj[group] = difference(values(timeline.present[group]), values(last(timeline.past)[group]))
    })
    updateObj.versionIdentifier = ui.timelines.versionIdentifier
    const { filterDateRange } = ui.timelines
    if (checkDateRangeRestriction(updateObj, filterDateRange)) {
      yield put(createSnackbarMessage(MessageTypes.WARNING, "pages.timeline.moveOperation.error"))
      yield put(unsetTimelineUpdateLoading())
      yield put(ActionCreators.undo())
      return
    }
    let resp = null
    try {
      resp = yield doAPIRequest({
        endPoint,
        method: post,
        prepareRequest: () => updateObj,
        token,
        state: { simulation: { id: simulationId } },
      })

      const { error, projects, versionIdentifier } = resp

      if (error) {
        switch (error) {
          case "TimelineDataOutdated": {
            yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.timeline.collisionError"))
            yield put(ActionCreators.undo())
            break
          }
          default: {
            yield put(ActionCreators.undo())
          }
        }
      }

      const updatedProjects = difference(map(resp.projects, "projectId"), map(updateObj.projects, "projectId"))

      const cleverFilterId = yield select(state => state.timeline.present.filters.clever)

      if (cleverFilterId) {
        yield put(filterCleverly(cleverFilterId, true))
      }

      yield put(unsetTimelineUpdateLoading())
      yield put(
        createAction(ActionTypes.TIMELINE_UPDATE_PROJECTS, {
          projects,
          affectedProjects: updatedProjects,
        })
      )
      yield put(
        createAction(ActionTypes.TIMELINE_VERSION_UPDATE, {
          versionIdentifier,
        })
      )
    } catch (e) {
      const skipErrorHandling = yield call(checkTokenAndRedirect, e)
      if (skipErrorHandling) {
        return
      }

      yield put(unsetTimelineUpdateLoading())
      yield put(ActionCreators.undo())
      return
    }

    yield put(
      createAction(ActionTypes.ENTITIES_UPDATE_PERSISTS, {
        ...updateObj,
      })
    )
  }),

  takeLatest(ActionTypes.TIMELINE_ITEM_UNDO_UPDATE, function* saveOrUndo() {
    let indexInFuture = 0
    yield put(setTimelineUpdateLoading())
    yield put(ActionCreators.undo())
    const stateUndo = yield select(getState)

    if (!isEmpty(get(stateUndo.timeline, "present.operationsResourcesSuggestions"))) {
      yield put(ActionCreators.undo())
      indexInFuture = 1
    }
    const { timeline, ui } = yield select(getState)
    const { token } = yield select(getUserData)
    const simulationId = yield select(getSimulationId)
    const updateObj = {}

    const currentTimeline = timeline.future[indexInFuture]

    let somethingHasChanged = false
    /* Detect the changed data items and send them to the API */
    forEach(["activities", "operations", "orderPositions", "productionOrders", "projects", "valueAdds"], group => {
      updateObj[group] = difference(values(timeline.present[group]), values(currentTimeline[group]))
      somethingHasChanged = somethingHasChanged || !isEmpty(updateObj[group])
    })

    if (!somethingHasChanged) {
      yield put(unsetTimelineUpdateLoading())
      return // nothing to do here, probably a selection was undone
    }

    updateObj.versionIdentifier = ui.timelines.versionIdentifier

    let resp = null

    try {
      resp = yield doAPIRequest({
        endPoint,
        method: post,
        prepareRequest: () => updateObj,
        token,
        state: { simulation: { id: simulationId } },
      })

      const { error, projects, versionIdentifier } = resp

      if (error) {
        switch (error) {
          case "TimelineDataOutdated": {
            yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.timeline.collisionError"))
            yield put(ActionCreators.undo())
            break
          }
          default: {
            yield put(ActionCreators.undo())
          }
        }
      }

      const updatedProjects = difference(map(resp.projects, "projectId"), map(updateObj.projects, "projectId"))

      yield put(unsetTimelineUpdateLoading())
      yield put(
        createAction(ActionTypes.TIMELINE_UPDATE_PROJECTS, {
          projects,
          affectedProjects: updatedProjects,
        })
      )
      yield put(
        createAction(ActionTypes.TIMELINE_VERSION_UPDATE, {
          versionIdentifier,
        })
      )
    } catch (e) {
      const skipErrorHandling = yield call(checkTokenAndRedirect, e)
      if (skipErrorHandling) {
        return
      }
      yield put(ActionCreators.redo())
      yield put(unsetTimelineUpdateLoading())
      return
    }

    yield put(
      createAction(ActionTypes.ENTITIES_UPDATE_PERSISTS, {
        ...updateObj,
      })
    )
  }),

  takeLatest(ActionTypes.TIMELINE_OPERATION_REPLAN, function* rePlanOperation(action) {
    const {
      payload: { operation },
    } = action
    const { ui } = yield select(getState)
    const { token } = yield select(getUserData)
    const simulationId = yield select(getSimulationId)
    const updateObj = {}
    updateObj.currentOperation = operation
    updateObj.versionIdentifier = ui.timelines.versionIdentifier

    let resp = null

    try {
      yield put(setTimelineUpdateLoading())

      resp = yield doAPIRequest({
        endPoint: endPointOperationReplan,
        method: post,
        prepareRequest: () => updateObj,
        token,
        state: { simulation: { id: simulationId } },
      })

      const { projects, versionIdentifier } = resp

      // always update projects after replan
      const updatedProjects = map(resp.projects, "projectId")
      yield put(
        createAction(ActionTypes.TIMELINE_UPDATE_PROJECTS_SAVE_TO_PAST, {
          projects,
          affectedProjects: updatedProjects,
        })
      )
      yield put(
        createAction(ActionTypes.TIMELINE_VERSION_UPDATE, {
          versionIdentifier,
        })
      )
      yield put(unsetTimelineUpdateLoading())
    } catch (e) {
      const skipErrorHandling = yield call(checkTokenAndRedirect, e)
      if (skipErrorHandling) return
      const { errorCode, detailsObject } = e.response
      switch (errorCode) {
        case "TimelineDataOutdated": {
          yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.timeline.collisionError"))
          yield put(ActionCreators.undo())
          break
        }
        case "InvalidArticleGroupCombination": {
          yield put(
            createSnackbarMessage(MessageTypes.ERROR, "errors.API.InvalidArticleGroupCombination", {
              article: detailsObject.article,
              resourceName: detailsObject.resourceName,
            })
          )
          yield put(ActionCreators.undo())
          break
        }
        default: {
          yield put(ActionCreators.undo())
        }
      }

      yield put(ActionCreators.redo())
      yield put(unsetTimelineUpdateLoading())
      return
    }

    yield put(
      createAction(ActionTypes.ENTITIES_UPDATE_PERSISTS, {
        activities: [],
        operations: [],
        orderPositions: [],
        productionOrders: [],
        projects: [],
        valueAdds: [],
        ...updateObj,
      })
    )
  }),

  takeLatest(ActionTypes.TIMELINE_ITEM_CLONE, function* cloneOrUndo(action) {
    const { timeline } = yield select(getState)
    const { token } = yield select(getUserData)
    const simulationId = yield select(getSimulationId)
    const {
      payload: { item },
    } = action
    const currentActivity = timeline.present.activities[item.itemId]

    const returnedActivity = yield doAPIRequest({
      endPoint: `${endPoint}/activity/copy`,
      method: post,
      prepareRequest: () => ({
        ...currentActivity,
        endDate: moment(item.end).format(),
        startDate: moment(item.start).format(),
      }),
      token,
      state: { simulation: { id: simulationId } },
    })

    if (returnedActivity) {
      yield put(
        createAction(ActionTypes.TIMELINE_ITEM_CLONED, {
          activity: returnedActivity,
        })
      )

      /* Apply the standard code which makes parental items grow to items size */
      yield put(
        createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
          type: ActionTypes.TIMELINE_ACTIVITY_RESIZE,
          payload: {
            item: {
              ...item,
              activityId: returnedActivity.activityId,
              itemId: returnedActivity.activityId,
            },
          },
        })
      )
    }
  }),
  takeLatest(ActionTypes.UI_TIMELINE_SET_SHOWDATEFILTER_TIMELINE, function* setFilterRangeTimeline({ payload }) {
    if (payload.fetchTimelineData) {
      yield put(
        createAction(ActionTypes.TIMELINE_DATA_FETCH_REQUEST, { payload: omit(payload, ["fetchTimelineData"]) })
      )
    }
  }),
]
