import { compact, head, map, some, tail } from "lodash"

import ActionTypes from "../../../constants/ActionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case ActionTypes.UI_ARTICLE_DIALOG_CHANGE_STANDARD_TEMPLATE: {
      const templateIndex = action.template
      return {
        ...state,
        values: {
          ...state.values,
          templateList: map(state.values.templateList, (template, index) => ({
            ...template,
            isStandard: templateIndex === index,
          })),
        },
      }
    }

    case ActionTypes.UI_ARTICLE_DIALOG_DELETED_TEMPLATE: {
      const { templateList } = state.values
      if (some(templateList, { isStandard: true }) || templateList.length === 0) {
        return state
      }
      return {
        ...state,
        values: {
          ...state.values,
          templateList: compact([{ ...head(templateList), isStandard: true }, ...tail(templateList)]),
        },
      }
    }

    default:
      return state
  }
}
