import { ApolloClient, createHttpLink, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { generatePath } from "react-router-dom"

import { omit, get } from "lodash"

import { API_SERVER_BASEDATA } from "./constants/api"
import { store } from "./store"
import { history } from "./history"
import { checkTokenExpired } from "./utils/http"
import { Routes } from "./constants/routes"
import AppMode from "./types/AppMode"
import { preparePrefixUrl } from "./utils/api"

const linkOpts = {
  fetch: (uri, options) => {
    const state = store.getState()
    const { token: standardToken } = state.user.authentication
    const { token: tokenTerminal } = state.user.terminalAuthentication
    const { id: simulationId } = state.simulation
    const { baselineid: baselineId } = options.headers

    const { mode } = state.appConfig
    let token
    if (mode === AppMode.standard) {
      token = standardToken
    }
    if (mode === AppMode.terminal) {
      token = tokenTerminal
    }
    const headers = omit(options.headers, ["authorization", "baselineid"])
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    const opts = {
      ...options,
      credentials: token ? "same-origin" : "include",
      headers,
    }
    const uriFull = preparePrefixUrl(`${uri}/graphql`, baselineId || simulationId)
    return fetch(uriFull, opts)
  },
  uri: API_SERVER_BASEDATA,
}

const errorLink = onError(({ networkError }) => {
  const isTokenExpired = checkTokenExpired(get(networkError, "result.message", ""))
  const statusCode = get(networkError, "statusCode", 0)
  if (networkError && statusCode >= 400 && isTokenExpired) {
    const state = store.getState()
    const { terminal } = get(state, "user.terminal", {})
    if (terminal) {
      history.push(generatePath(Routes.AUTO_LOGOUT_TERMINAL, { terminal }))
    } else {
      history.push(Routes.AUTO_LOGOUT)
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: { ResourceAvailabilityPlanGraphType: { keyFields: ["id", "validFrom", "validTo"] } },
  }),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
  link: from([errorLink, createHttpLink(linkOpts)]),
})

export default client
