const errors = {
  UserError(messageId, message = null, values = null) {
    // eslint-disable-next-line prefer-rest-params
    const temp = Error.apply(this, arguments)
    temp.name = "userError"
    this.name = temp.name
    this.stack = temp.stack
    this.messageId = messageId
    this.message = message
    this.values = values || {}
  },
}
errors.UserError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: errors.UserError,
    writable: true,
    configurable: true,
  },
})

module.exports = errors
