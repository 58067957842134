import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.BASEDATA_RESOURCE_DETAILS_UPDATE_SUCCESS: {
      return {
        ...state,
        [payload.result]: { ...state[payload.result], archived: payload.data.archived },
      }
    }

    default:
      return state
  }
}
