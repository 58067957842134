export const lightSkyBlue = "#0288D1"
export const white = "#FFFFFF"
export const grey = "rgba(0, 0, 0, 0.54)"
export const red = "#D50000"
export const defaultButtonColor = "rgb(0 0 0 / 87%)"
export const articleTechnologyBackground = "#EDEDED"
export const terminalColorBlue = "#455760"

export const lightgrey = "#F1F1F1"
export const pureblack = "#000000"
export const lightred = "#E83850"
export const lightgreen = "#37C5AB"
export const lightblue = "#00A5E5"
export const highlightblue = "#E0F1FA"
export const orange = "#FF8C00"
export const dimmedblue = "#306B8B"
export const greyblue = "#9AB7C7"
export const darkgreyblue = "#467B98"
export const verylightgrey = "#F7F7F7"
export const warningOrange = "#F09300"
export const orangeSimulation = "#FF7A07"
export const orangeSimulationDarker = "#ED6E00"
export const backgroundDrawerColor = "#2E3336"
