import { reject } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

const initialState = {
  messages: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SNACKBAR_MESSAGE_CREATE: {
      const { id, text, type, values, defaultMessage } = action.payload
      return {
        ...state,
        messages: [...state.messages, { id, text, type, values, defaultMessage }],
      }
    }

    case ActionTypes.SNACKBAR_MESSAGE_REMOVE: {
      const { id } = action.payload
      return {
        ...state,
        messages: reject(state.messages, { id }),
      }
    }

    default:
      return state
  }
}
