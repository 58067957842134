/* eslint-disable no-shadow */

import { createTheme, Theme } from "@mui/material/styles"
import { grey, pink } from "@mui/material/colors"

import { greyblue, lightSkyBlue } from "../constants/colors"

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Theme {
    link: {
      color: string
      fontSize: number
      fontWeight: number
      textDecoration: string
    }
    menu: {
      height: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    link?: {
      color: string
      fontSize: number
      fontWeight: number
      textDecoration: string
    }
    menu?: {
      height: string
    }
  }
}
// ---to remove, when we move to mui v5 / safari issue
const isSafari =
  typeof navigator !== "undefined" &&
  /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
  /(os |version\/)15(.|_)[4-9]/i.test(navigator.userAgent)

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  link: {
    color: lightSkyBlue,
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
  },
  menu: {
    height: "64px",
  },
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: lightSkyBlue,
    },
    secondary: {
      light: pink.A200,
      main: pink.A400,
      dark: pink.A700,
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "&:hover": {
            backgroundColor: greyblue,
          },
        },
        text: {
          fontWeight: 500,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        root: {
          backgroundColor: "#e0e0e0",
          borderRadius: 9,
          color: "#FFF",
          fontWeight: 500,
          height: "auto",
          padding: "1px 0",
          textTransform: "uppercase",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "&:first-of-type": {
            paddingTop: 10,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: { padding: "12px 24px 10px" },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 8,
          paddingTop: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          minHeight: "initial",
          paddingBottom: 8,
          paddingTop: 8,
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&.Mui-focusVisible": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: "rgba(0,0,0,.54)",
          fontSize: 12,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.selected": {
            backgroundColor: grey[200],
          },
        },
      },
    },
    MuiPaper: isSafari && {
      styleOverrides: {
        root: {
          transition: "none !important",
        },
      },
    },
  },
})
