import ActionTypes from "../../../constants/ActionTypes"

export default function (state = {}, action) {
  if (!state.values) return state

  switch (action.type) {
    case ActionTypes.BASEDATA_RESOURCE_DETAILS_CREATE_SUCCESS: {
      const { payload } = action
      if (payload.data && payload.data.length && payload.data[0].errorCode) {
        return {
          ...state,
          error: payload.data[0].errorCode,
        }
      }

      return {
        ...state,
        error: null,
        success: true,
      }
    }
    case ActionTypes.BASEDATA_RESOURCES_UPDATE_SUCCESS: {
      const { payload } = action
      if (payload.data && payload.data.length && payload.data[0].errorCode) {
        return {
          ...state,
          error: payload.data[0].errorCode,
        }
      }
      return {
        ...state,
        error: null,
        success: true,
      }
    }
    default:
      return state
  }
}
