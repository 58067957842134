import React, { Fragment } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"

import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import ArrowUpIcon from "@mui/icons-material/ArrowUpward"
import { useHistory } from "react-router"
import IconButton from "@mui/material/IconButton"
import Skeleton from "@mui/material/Skeleton"

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  gridItem: {
    alignSelf: "center",
    minWidth: 0,
    minHeight: "48px",
  },
  iconButton: {
    marginLeft: -12,
  },
  headerWithSpaces: {
    whiteSpace: "pre-wrap",
  },
})

const PanelHeader = ({ classes, Controls, parentUrl, divider, Header, SubHeader, style, loading, readOnly }) => {
  const history = useHistory()
  const addUpButton = !!parentUrl
  return (
    <Fragment>
      <Grid container className={classes.header} style={style}>
        {addUpButton && (
          <Grid item xs={1}>
            <IconButton onClick={() => history.push(parentUrl)} className={classes.iconButton} size="large">
              <ArrowUpIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item={addUpButton} container={!addUpButton} xs={addUpButton ? 11 : undefined}>
          <Grid container wrap="nowrap" justifyContent="space-between">
            <Grid item className={classes.gridItem}>
              <Typography variant="h6" noWrap className={classes.headerWithSpaces}>
                {loading ? <Skeleton height={32} width={200} variant="rectangular" /> : Header}
              </Typography>
            </Grid>
            {!readOnly && (
              <Grid item style={{ display: "flex" }} className={classes.headerWithSpaces}>
                {loading ? <Skeleton height={48} width={48} variant="circular" /> : Controls}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.headerWithSpaces}>
            {loading ? <Skeleton height={21} width="75%" variant="rectangular" /> : SubHeader}
          </Grid>
        </Grid>
      </Grid>
      {divider && <Divider />}
    </Fragment>
  )
}

PanelHeader.defaultProps = {
  Controls: null,
  divider: true,
  parentUrl: null,
  SubHeader: null,
  loading: false,
  Header: null,
  readOnly: false,
}

PanelHeader.propTypes = {
  Controls: PropTypes.node,
  divider: PropTypes.bool,
  parentUrl: PropTypes.string,
  Header: PropTypes.node,
  SubHeader: PropTypes.node,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default withStyles(styles)(PanelHeader)
