import { filter, map, sortBy } from "lodash"
import moment from "moment"

import { CapacityStream } from "./capacities"
import { getHolidaySlices } from "./holidays"
import { filterTimeSlicesInRange } from "./filterTimeSlicesInRange"
import { mergeOrderedTimeSlices } from "./mergeSlices"

const timelineGapType = "gap"

const transformGaps = gaps =>
  sortBy(
    map(gaps, ({ dateFrom, dateTo, gapType }) => ({
      end: new Date(dateTo),
      start: new Date(dateFrom),
      type: timelineGapType,
      title: gapType,
    })),
    ["start", "end"]
  )

export class Availability {
  /* Build a list of available time slices from capacity plans. Remove gaps and holidays from those capacitys */
  constructor({ capacityPlans, gaps = [], holidays = [] }, from, timezone) {
    this.capacityStream = new CapacityStream(capacityPlans, from, timezone)
    this.gaps = transformGaps(gaps)
    this.holidays = getHolidaySlices(holidays, timezone)
    if (moment.isMoment(from) || from instanceof Date) {
      this.from = moment(from)
    } else {
      throw new Error("Availability class requires a start point for its calculation")
    }
  }

  read(until = this.from.clone().add(7, "d"), separateGaps) {
    const capacityPlans = this.capacityStream.read(until, separateGaps)
    const timeSpecificCapacityPlans = filter(capacityPlans, cp => !(cp.start >= until || cp.end <= this.from))

    // const holidayItems = filterTimeSlicesInRange(this.holidays, this.from, until) <--- source of Bug PLAN-1715
    const gapItems = filterTimeSlicesInRange(this.gaps, this.from, until)
    const unavailableSlices = [
      ...timeSpecificCapacityPlans,
      ...filterTimeSlicesInRange(this.holidays, this.from, until),
      ...gapItems,
    ]
    const sortedSlices = sortBy(unavailableSlices, ["start", "end"])

    this.from = until
    const mergedSlices = mergeOrderedTimeSlices(sortedSlices, separateGaps)

    return mergedSlices
  }

  reset(from) {
    this.from = moment(from)
    this.capacityStream.reset(this.from)
    return this
  }
}
