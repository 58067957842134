import moment from "moment"
import { orderBy, pick, reduce, partition, map } from "lodash"

import { setOperationConflictsByStatus } from "./conflicts"
import { adjustActivity, moveOperation, updateDatesOfItem, updateConflictsForResources } from "./helpers"
import {
  changeAttendantOperations,
  checkForError,
  excludeAttendantOperations,
  getAttendantOperations,
  getGroupedDependentOp,
  sortMainOperations,
  updateDependent,
  writeAttendantOperations,
} from "./operationsHelpers"

export const adjustProductionOrder = (nextState, id) => {
  const productionOrder = nextState.productionOrders[id]
  const operations = pick(nextState.operations, productionOrder.operations)

  nextState.productionOrders = {
    ...nextState.productionOrders,
    [id]: updateDatesOfItem(productionOrder, operations),
  }

  adjustActivity(nextState, productionOrder.activityId)
}

export const moveProductionOrder = (nextState, id, difference, direction) => {
  // direction not used
  const productionOrder = nextState.productionOrders[id]
  if (productionOrder.locked) {
    return false
  }
  const nextEndDate = moment(productionOrder.endDate).add(difference)
  const nextStartDate = moment(productionOrder.startDate).add(difference)

  nextState.productionOrders = {
    ...nextState.productionOrders,
    [id]: {
      ...productionOrder,
      endDate: nextEndDate.format(),
      startDate: nextStartDate.format(),
    },
  }

  const affectedResources = []
  const operations = excludeAttendantOperations(map(productionOrder.operations, opId => nextState.operations[opId]))
  const [dependentOperations, mainOperations] = partition(operations, "mainOperationId")

  reduce(
    orderBy(mainOperations, op => new Date(op.startDate), [difference > 0 ? "asc" : "desc"]),
    (accumulatedTimeDifference, latestOperation) => {
      const oldEnd = Date.parse(latestOperation.endDate)
      const oldStart = Date.parse(latestOperation.startDate)

      const attendantOperations = getAttendantOperations(nextState, latestOperation.productionOrderId)
      const { movedOperation } = moveOperation(
        nextState,
        latestOperation.operationId,
        accumulatedTimeDifference,
        0,
        {},
        direction
      )
      const changedAttendantOperations = changeAttendantOperations(nextState, movedOperation, attendantOperations)
      writeAttendantOperations(nextState.operations, changedAttendantOperations)
      const newEnd = Date.parse(movedOperation.endDate)
      const newStart = Date.parse(movedOperation.startDate)
      nextState.operations[latestOperation.operationId] = movedOperation
      affectedResources.push(latestOperation.resource)
      return difference > 0 ? newEnd - oldEnd : newStart - oldStart
    },
    difference
  )

  // Section: move parallel operations
  const sortedMainOperationBackward = sortMainOperations(
    pick(nextState.operations, map(mainOperations, "operationId")),
    difference
  )
  const keyedDependentOperations = getGroupedDependentOp(
    pick(nextState.operations, map(dependentOperations, "operationId"))
  )
  sortedMainOperationBackward.forEach(movedMainOperation => {
    updateDependent(keyedDependentOperations, movedMainOperation, nextState)
  })
  checkForError(nextState)

  adjustProductionOrder(nextState, id)
  updateConflictsForResources(nextState, affectedResources)
  setOperationConflictsByStatus(nextState)
  return true
}
