import React from "react"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"
import { FormattedMessage } from "react-intl"
import { grey } from "../../constants/colors"

const titleStyles = {
  marginBottom: 10,
  fontSize: 14,
  fontWeight: 500,
  color: grey,
}

const FormTitle = ({ id, customStyles, ...props }) => (
  <Typography color="textPrimary" variant="h6" style={{ ...titleStyles, ...customStyles }} {...props}>
    <FormattedMessage id={id} />
  </Typography>
)

FormTitle.propTypes = {
  id: PropTypes.string.isRequired,
  customStyles: PropTypes.instanceOf(Object),
}

FormTitle.defaultProps = {
  customStyles: {},
}

export default FormTitle
