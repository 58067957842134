import { combineReducers } from "redux"

import templates from "./templates"
import templateDetails from "./templateDetails"

import technologies from "./technologies"
import technologyDetails from "./technologyDetails"

import customers from "./customers"
import holidays from "./holidays"
import imports from "./imports"
import resources from "./resources"
import resourceDetails from "./resourceDetails"
import orderPositions from "./orderPositions"
import projects from "./projects"
import productionOrders from "./productionOrders"
import operations from "./operations"
import projectDetails from "./projectDetails"
import activities from "./activities"
import amountUnits from "./amountUnits"
import licenceModules from "./licenceModules"
import licenceModuleDetails from "./licenceModuleDetails"
import userList from "./userList"
import userDetails from "./userDetails"
import roles from "./roles"
import modules from "./modules"
import modulesUserManagement from "./modulesUserManagement"
import basedataResourceDetails from "./basedataResourceDetails"
import planningObjectsActivityDetails from "./planningObjectsActivityDetails"
import planningObjectsOperationDetails from "./planningObjectsOperationDetails"
import planningObjectsOrderPositionDetails from "./planningObjectsOrderPositionDetails"
import planningObjectsProductionOrderDetails from "./planningObjectsProductionOrderDetails"
import planningObjectsProjectDetails from "./planningObjectsProjectDetails"
import planningTimelineProjectDetails from "./planningTimelineProjectDetails"
import planningTimelineOrderPositionDetails from "./planningTimelineOrderPositionDetails"
import planningTimelineActivityDetails from "./planningTimelineActivityDetails"
import productionTimelineProductionOrderDetails from "./productionTimelineProductionOrderDetails"

export default combineReducers({
  templates,
  templateDetails,
  technologies,
  technologyDetails,
  customers,
  imports,
  resources,
  resourceDetails,
  projectDetails,
  projects,
  orderPositions,
  activities,
  holidays,
  productionOrders,
  operations,

  amountUnits,

  basedataResourceDetails,

  planningObjectsActivityDetails,
  planningObjectsOperationDetails,
  planningObjectsOrderPositionDetails,
  planningObjectsProductionOrderDetails,
  planningObjectsProjectDetails,

  planningTimelineProjectDetails,
  planningTimelineOrderPositionDetails,
  planningTimelineActivityDetails,

  productionTimelineProductionOrderDetails,

  licenceModules,
  licenceModuleDetails,

  userList,
  userDetails,

  roles,
  modules,
  modulesUserManagement,
})
