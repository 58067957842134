import { omit } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

export const initialState = {
  Article: {},
  Template: {},
  Technology: {},
  Resource: {},
  Customer: {},
  Project: {},
  OrderPosition: {},
  ProductionOrder: {},
  Operation: {},
  Activity: {},
  UserFiltersChanges: {},
}

export default function (state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.UI_FILTERS_SET: {
      const { type, filters } = payload
      return {
        ...state,
        [type]: filters
          ? {
              ...state[type],
              ...filters,
            }
          : null,
      }
    }
    case ActionTypes.UI_USER_FILTER_CHANGE: {
      const { type } = payload
      return {
        ...state,
        UserFiltersChanges: {
          ...state.UserFiltersChanges,
          [type]: {
            ...state.UserFiltersChanges[type],
            ...omit(payload, "type"),
          },
        },
      }
    }
    default:
      return state
  }
}
