import { takeLatest, put, select } from "redux-saga/effects"

import { createSnackbarMessage } from "../../actions/ui/snackbar"
import ActionTypes from "../../constants/ActionTypes"
import { API_SERVER_USER } from "../../constants/api"
import { getUserData } from "../selectors"
import { put as putFetch } from "../../utils/http"
import { createAction } from "../../utils/redux"
import MessageTypes from "../../types/MessageTypes"

function* lockTerminal({ payload: terminal, name: terminalName }) {
  const { token } = yield select(getUserData)
  const options = {
    credentials: token ? "same-origin" : "include",
    customHeaders: { Authorization: `Bearer ${token}` },
    responseType: "json",
    url: `${API_SERVER_USER}/terminals/${terminal}`,
  }

  try {
    const { token: terminalLockToken } = yield putFetch(options)
    yield put(createAction(ActionTypes.TERMINAL_LOCK_SUCCESS, terminalLockToken))
    yield put(createSnackbarMessage(MessageTypes.INFO, "pages.terminal.activated", { id: terminalName }))
  } catch (e) {
    yield put(createAction(ActionTypes.TERMINAL_LOCK_FAILURE))
    yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.terminal.notActivated", { id: terminalName }))
  }
}

export default [takeLatest(ActionTypes.TERMINAL_LOCK_REQUEST, lockTerminal)]
