import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import customersSchema from "../../schemas/basedata/customers"
import { API_SERVER_BASEDATA } from "../../constants/api"

export default [
  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_CUSTOMERS_LIST,
    endPoint: `${API_SERVER_BASEDATA}/customers`,
    schema: customersSchema,
  }),
]
