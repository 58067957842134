import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import ActionTypes from "../../../constants/ActionTypes"
import TimelineWindowSizes from "../../../constants/TimelineWindowSizes"
import { calculateNextRange, middleDate, snapWindowRange, todayRange } from "../../../utils/timeline/range"
import TimelineTypes from "../../../types/TimelineTypes"

const initialWindowSize = TimelineWindowSizes.MONTH
const initialRange = snapWindowRange(new Date(), initialWindowSize)

export const initialState = {
  defaultTimeline: TimelineTypes.Planning,
  columnHeaderWidth: 350,
  filters: {},
  selectedUserFilter: -1,
  filterDateRange: { fromDate: null, toDate: null },
  locked: false,
  range: initialRange,
  toggles: {
    production: true,
    resources: true,
    showArchivedResources: false,
    showDelayedConflicts: false,
    showDateFilter: false,
    showFinished: false,
    showFreeResources: false,
    showFinishedProjects: true,
    showVisibleArea: false,
    showPersonelCapacity: false,
  },
  versionIdentifier: null,
  windowSize: initialWindowSize,
}

const toggle = (state, propertyName) => {
  const toggles = { ...state.toggles }
  toggles[propertyName] = !toggles[propertyName]
  return {
    ...state,
    toggles,
  }
}

const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.UI_TIMELINE_SET_SHOWDATEFILTER_TIMELINE: {
      return {
        ...state,
        filterDateRange: { fromDate: payload.fromDate, toDate: payload.toDate },
      }
    }
    case ActionTypes.UI_TIMELINE_FILTER_UPDATE: {
      return {
        ...state,
        filters: payload,
      }
    }
    case ActionTypes.UI_TIMELINE_FILTER_CLEAR: {
      return {
        ...state,
        filters: {},
      }
    }

    case ActionTypes.UI_TIMELINE_SET_WINDOW_SIZE: {
      const { windowSize } = payload
      return { ...state, windowSize, range: snapWindowRange(middleDate(state.range), windowSize) }
    }

    case ActionTypes.UI_TIMELINE_TIMELINE_MOVED: {
      return {
        ...state,
        range: {
          start: payload.range.start,
          end: payload.range.end,
          animationDisabled: payload.range.animationDisabled,
        },
      }
    }

    case ActionTypes.UI_TIMELINE_SHOW_NEXT_RANGE:
      return { ...state, range: calculateNextRange(state.range, state.windowSize, 1) }

    case ActionTypes.UI_TIMELINE_SHOW_PREVIOUS_RANGE:
      return { ...state, range: calculateNextRange(state.range, state.windowSize, -1) }

    case ActionTypes.UI_TIMELINE_SHOW_TODAY:
      return {
        ...state,
        range: todayRange(state.range),
      }

    case ActionTypes.UI_TIMELINE_TOGGLE_PERSONEL_PLAN:
      return toggle(state, "showPersonelCapacity")

    case ActionTypes.UI_TIMELINE_TOGGLE_PRODUCTION_TIMELINE:
      return toggle(state, "production")

    case ActionTypes.UI_TIMELINE_TOGGLE_RESOURCES_TIMELINE:
      return toggle(state, "resources")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWARCHIVEDSOURCES_TIMELINE:
      return toggle(state, "showArchivedResources")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWDATEFILTER_TIMELINE:
      return toggle(state, "showDateFilter")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWDELAYEDCONFLICTS_TIMELINE:
      return toggle(state, "showDelayedConflicts")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWFINISHED_TIMELINE:
      return toggle(state, "showFinished")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWFREERESOURCES_TIMELINE:
      return toggle(state, "showFreeResources")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWFINISHEDPROJECTS_TIMELINE:
      return toggle(state, "showFinishedProjects")

    case ActionTypes.UI_TIMELINE_TOGGLE_SHOWVISIBLEAREA_TIMELINE:
      return toggle(state, "showVisibleArea")

    case ActionTypes.UI_TIMELINE_DRAG_COLUMN_HEADER_WIDTH: {
      const { headerWidth } = payload
      return {
        ...state,
        columnHeaderWidth: headerWidth,
      }
    }

    case ActionTypes.UI_TIMELINE_TIMELINE_SET_DEFAULT: {
      const { timeline } = payload
      return {
        ...state,
        defaultTimeline: timeline,
      }
    }

    case ActionTypes.TIMELINE_DATA_FETCH_SUCCESS: {
      const { versionIdentifier } = payload
      return {
        ...state,
        versionIdentifier,
      }
    }

    case ActionTypes.TIMELINE_VERSION_UPDATE: {
      const { versionIdentifier } = payload
      return {
        ...state,
        versionIdentifier,
      }
    }

    case ActionTypes.UI_TIMELINE_FORCERERENDER: {
      return {
        ...state,
        forceRerenderPlanning: true,
        forceRerenderProduction: true,
        forceRerenderResource: true,
      }
    }

    case ActionTypes.UI_TIMELINE_FORCERERENDER_PLANNING_CLEAR: {
      return {
        ...state,
        forceRerenderPlanning: false,
      }
    }
    case ActionTypes.UI_TIMELINE_FORCERERENDER_PRODUCTION_CLEAR: {
      return {
        ...state,
        forceRerenderProduction: false,
      }
    }
    case ActionTypes.UI_TIMELINE_FORCERERENDER_RESOURCE_CLEAR: {
      return {
        ...state,
        forceRerenderResource: false,
      }
    }
    case ActionTypes.UI_TIMELINE_USER_FILTER_SET: {
      return {
        ...state,
        selectedUserFilter: payload || null,
      }
    }

    default:
      return state
  }
}

const persistConfig = {
  key: "ui_timeline",
  storage,
}

export default persistReducer(persistConfig, reducer)
