import { call, put, select, takeLatest } from "redux-saga/effects"
import { isArray, get as getLodash } from "lodash"
import { generatePath } from "react-router-dom"

import { createAction } from "../utils/redux"
import { formatAPIResponse, preparePrefixUrl } from "../utils/api"
import ActionTypes from "../constants/ActionTypes"
import { checkTokenExpired, get } from "../utils/http"
import { getUserData, getState } from "./selectors"
import { NORMALIZE } from "../middleware/normalize"
import { createSnackbarMessage } from "../actions/ui/snackbar"
import { Routes } from "../constants/routes"
import { history } from "../history"
import MessageTypes from "../types/MessageTypes"

export const showAutologoutPage = terminal => {
  if (terminal) {
    history.push(generatePath(Routes.AUTO_LOGOUT_TERMINAL, { terminal }))
  } else {
    history.push(Routes.AUTO_LOGOUT)
  }
}

export function* checkTokenAndRedirect(e) {
  const { response } = e
  const isTokenExpired = checkTokenExpired(getLodash(response, "message", ""))
  if (isTokenExpired) {
    const state = yield select(getState)
    const { terminal } = state.user.terminal
    showAutologoutPage(terminal)
    return true
  }
  return false
}

const responseErrorStruct = dataForValidation =>
  typeof dataForValidation.errorCode === "string" && typeof dataForValidation.details === "string"
// type of error that come with HTTP code 200 :
const successfulResponseErrorStruct = dataForValidation =>
  typeof dataForValidation.errorCode === "string" && typeof dataForValidation.message === "string"

export function doAPIRequest({
  method = get,
  endPoint,
  token,
  payload,
  prepareRequest,
  prepareUrl,
  prepareResponse,
  state,
}) {
  const url = prepareUrl ? prepareUrl(endPoint, payload) : endPoint
  const data = prepareRequest ? prepareRequest(payload, state) : null
  const { id: simulationId } = getLodash(state, "simulation", {})
  const newUrl = preparePrefixUrl(url, simulationId)

  return method({ url: newUrl, token, data }).then(response => {
    if (prepareResponse && !responseErrorStruct(response) && !successfulResponseErrorStruct(response)) {
      response = prepareResponse(response, state) // eslint-disable-line no-param-reassign
    }
    return formatAPIResponse(response, state)
  })
}

export function createAPIRequest({ actionType, schema, actualDoAPIRequest = doAPIRequest, isLoadedSelector, ...rest }) {
  return function* APIRequest(action) {
    const { payload, context } = action
    try {
      const { token } = yield select(getUserData)
      let needsLoading = true

      if (isLoadedSelector) {
        const isLoaded = yield select(isLoadedSelector)
        needsLoading = !isLoaded
      }

      if (needsLoading) {
        yield put(createAction(ActionTypes[`${actionType}_DO_REQUEST`]))

        const state = yield select(getState)
        const data = yield call(actualDoAPIRequest, {
          ...rest,
          token,
          payload,
          state,
        })

        const dataForValidation = isArray(data) ? data[0] : data
        if (!!dataForValidation && responseErrorStruct(dataForValidation)) {
          const defaultMessage = dataForValidation.details ? dataForValidation.details : dataForValidation.errorCode

          yield put(
            createSnackbarMessage(
              MessageTypes.ERROR,
              `errors.API.${dataForValidation.errorCode}`,
              undefined,
              defaultMessage
            )
          )
          yield put(
            createAction(ActionTypes[`${actionType}_FAILURE`], dataForValidation, {
              context,
            })
          )
          return
        }

        if (successfulResponseErrorStruct(data)) {
          yield put(
            createAction(
              ActionTypes[`${actionType}_SUCCESS`],
              {
                data,
              },
              {
                context,
              }
            )
          )
          return
        }
        yield put(
          createAction(
            ActionTypes[`${actionType}_SUCCESS`],
            {
              data,
            },
            {
              [NORMALIZE]: { schema },
              context,
            }
          )
        )
      }
    } catch (e) {
      const { response, message } = e
      const skipErrorHandling = yield call(checkTokenAndRedirect, e)
      if (skipErrorHandling) {
        return
      }

      const errorPayload = {
        originalAction: action,
        response,
        message, // http 404 comes as message, not response back
      }

      yield put(
        createAction(ActionTypes[`${actionType}_FAILURE`], errorPayload, {
          context,
        })
      )
      yield put(createAction(ActionTypes.API_ERROR, errorPayload)) // not user anymore, only in test
    }
  }
}

export function APIRequestSequence(settings) {
  const { actionType } = settings
  return takeLatest(`${actionType}_REQUEST`, createAPIRequest(settings))
}

export function createGenericRequest(settings) {
  const {
    isLoadedSelector,
    actionType,
    endPoint,
    method = get,
    prepareRequest,
    prepareResponse,
    prepareUrl,
    ...rest
  } = settings
  return function* GenericRequest(action) {
    const { payload } = action
    try {
      let needsLoading = true

      if (isLoadedSelector) {
        const isLoaded = yield select(isLoadedSelector)
        needsLoading = !isLoaded
      }
      if (needsLoading) {
        const state = yield select(getState)
        const { token } = yield select(getUserData)
        const { id: simulationId } = getLodash(state, "simulation", {})
        const url = preparePrefixUrl(prepareUrl ? prepareUrl(endPoint, payload, state) : endPoint, simulationId)
        const data = yield call(method, {
          ...rest,
          data: prepareRequest ? prepareRequest(payload) : payload,
          token,
          url,
        })

        const dataForValidation = isArray(data) ? data[0] : data

        if (!!dataForValidation && responseErrorStruct(dataForValidation)) {
          const defaultMessage = dataForValidation.details ? dataForValidation.details : dataForValidation.errorCode
          yield put(
            createSnackbarMessage(
              MessageTypes.ERROR,
              `errors.API.${dataForValidation.errorCode}`,
              undefined,
              defaultMessage
            )
          )
          return
        }

        yield put(
          createAction(
            ActionTypes[`${actionType}_SUCCESS`],
            prepareResponse ? prepareResponse(data, state, payload) : data
          )
        )
      }
    } catch (error) {
      const { response } = error
      const skipErrorHandling = yield call(checkTokenAndRedirect, error)
      if (skipErrorHandling) {
        return
      }

      const errorPayload = {
        originalAction: action,
        response,
        ...error,
      }
      yield put(createAction(ActionTypes[`${actionType}_FAILURE`], errorPayload))
    }
  }
}

export function APIGenericSequence(settings) {
  const { actionType } = settings

  return takeLatest(`${actionType}_REQUEST`, createGenericRequest(settings))
}
