import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import { API_SERVER_USER } from "../../constants/api"
import modulesUserManagementSchema from "../../schemas/modulesUserManagement"

export default [
  APIRequestSequence({
    actionType: ActionTypes.MODULES_LIST,
    endPoint: `${API_SERVER_USER}/modules`,
    schema: modulesUserManagementSchema,
    prepareResponse: data =>
      data.reduce((acc, { id, moduleId, moduleName, licence }) => {
        if (!acc.find(item => item.moduleId === moduleId))
          return [...acc, { id, moduleId, moduleName, licences: [licence] }]
        const index = acc.findIndex(item => item.moduleId === moduleId)
        acc[index].licences.push(licence)
        return acc
      }, []),
  }),
]
