import { schema } from "normalizr"
import articleGroup from "../articleGroups/item"
import userSchema from "../../users/item"

export default new schema.Entity(
  "articles",
  {
    createdBy: userSchema,
    updatedBy: userSchema,
    articleGroup,
  },
  {
    idAttribute: "articleId",
  }
)
