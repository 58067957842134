import authentication from "./authentication"
import accessibleLicenceModules from "./accessibleLicenceModules"
import pinAuthentication from "./pin_authentication"
import screen from "./screen"
import terminal from "./terminal"
import languages from "./languages"

export default [
  ...authentication,
  ...accessibleLicenceModules,
  ...pinAuthentication,
  ...screen,
  ...terminal,
  ...languages,
]
