import { orderBy, reduce, partition, map, pick, forEach, clone } from "lodash"

import { initialState } from "./initialState"
import { setConflictsByOrderPosition, setOperationConflictsByStatus } from "./helpers/conflicts"

import ActionTypes from "../../constants/ActionTypes"

import {
  adjustActivity,
  adjustActivitySiblings,
  updateConflictsForResources,
  updateDatesOfItem,
} from "./helpers/helpers"

import {
  changeAttendantOperations,
  checkErrorIsEmpty,
  checkForError,
  excludeAttendantOperations,
  getAttendantOperations,
  getAttendantOperationsFull,
  getGroupedDependentOp,
  moveOperation,
  newTimelineStateWithNew,
  sortMainOperations,
  StatusOfMovingTypes,
  updateDependent,
  writeAttendantOperations,
} from "./helpers/operationsHelpers"
import errors from "../../utils/errors"

export default (state = initialState, action) => {
  const { payload } = action
  const { selection } = state

  switch (action.type) {
    /**
     * One productionOrder was moved.
     * Finally update the conflicts.
     */
    case ActionTypes.TIMELINE_PRODUCTIONORDER_MOVE: {
      const { start: newStart, itemId: productionOrderId } = payload.item || state.movedItem
      const newStartDate = new Date(newStart)

      const { startDate: oldStart } = state.productionOrders[productionOrderId]
      const oldStartDate = new Date(oldStart)
      const timeDifference = newStartDate - oldStartDate

      const affectedResources = []
      let nextState = newTimelineStateWithNew(payload, state)

      try {
        nextState = reduce(
          selection,
          (tempState, { id }) => {
            const productionOrder = tempState.productionOrders[id]
            const activityBeforeMove = tempState.activities[productionOrder.activityId]
            const childrenOperations = []

            /*
             * Move the operations belonging to the productionOrder by the amount of time the productionOrder is moved.
             * BUT: As the length of an operation can change during moving, we need to adjust time moved according to those "length changes
             */
            const operations = excludeAttendantOperations(pick(tempState.operations, productionOrder.operations))
            const [dependentOperations, mainOperations] = partition(operations, "mainOperationId")

            // If we have moved backwards, start from the last operation so that the first will fit itself
            const orderedOperations = orderBy(mainOperations, op => new Date(op.startDate), [
              timeDifference > 0 ? "asc" : "desc",
            ])
            // Section: move main operations
            const attendantOperations = getAttendantOperations(tempState, productionOrder.productionOrderId)
            reduce(
              orderedOperations,
              (accumulatedTimeDifference, operation) => {
                const oldEnd = Date.parse(operation.endDate)
                const oldOperationStart = Date.parse(operation.startDate)
                const { movedOperation, statusOfMoving } = moveOperation(
                  tempState,
                  operation.operationId,
                  accumulatedTimeDifference
                )
                const changedAttendantOperations = changeAttendantOperations(
                  tempState,
                  movedOperation,
                  attendantOperations
                )
                writeAttendantOperations(tempState.operations, changedAttendantOperations)
                if (statusOfMoving === StatusOfMovingTypes.frozenZone) {
                  throw new errors.UserError("pages.timeline.multipleMove.frozenTime")
                }
                const newEnd = Date.parse(movedOperation.endDate)
                const newOperationStart = Date.parse(movedOperation.startDate)
                // eslint-disable-next-line no-param-reassign
                tempState.operations[operation.operationId] = movedOperation
                affectedResources.push(operation.resource)
                childrenOperations.push(movedOperation)
                return timeDifference > 0 ? newEnd - oldEnd : newOperationStart - oldOperationStart // this is the new timeDifference by which we must move the following operation
              },
              timeDifference
            )

            // Since we have the exclusivity rule, in order to prevent collision between 2 parallel operations on the same resource we have to handle them in a specific order.
            // If we move forward, we make sure that the later operation is moved first to free the place for the earlier operation and the opposite.
            // Section: move parallel operations
            const sortedMainOperationBackward = sortMainOperations(
              pick(nextState.operations, map(mainOperations, "operationId")),
              timeDifference
            )
            const keyedDependentOperations = getGroupedDependentOp(
              pick(nextState.operations, map(dependentOperations, "operationId"))
            )
            sortedMainOperationBackward.forEach(movedMainOperation => {
              updateDependent(keyedDependentOperations, movedMainOperation, tempState)
            })
            checkForError(nextState)
            const attendantOperationsAfterMove = getAttendantOperationsFull(
              tempState,
              productionOrder.productionOrderId
            )
            // eslint-disable-next-line no-param-reassign
            tempState.productionOrders[id] = updateDatesOfItem(productionOrder, [
              ...childrenOperations,
              ...attendantOperationsAfterMove,
            ])

            adjustActivity(tempState, productionOrder.activityId)
            const activityAfterMove = tempState.activities[productionOrder.activityId]
            adjustActivitySiblings(tempState, activityBeforeMove, activityAfterMove)
            return tempState
          },
          nextState
        )

        updateConflictsForResources(nextState, affectedResources)
        setOperationConflictsByStatus(nextState)
        setConflictsByOrderPosition(nextState)
      } catch (err) {
        return checkErrorIsEmpty(err, state, payload.item, ActionTypes.TIMELINE_PRODUCTIONORDER_MOVE)
      }
      return nextState
    }
    case ActionTypes.TIMELINE_PRODUCTIONORDER_LOCKED: {
      const { productionOrderId, locked } = payload
      const nextState = newTimelineStateWithNew(payload, state)
      const productionOrder = clone(nextState.productionOrders[productionOrderId])
      productionOrder.locked = locked
      nextState.productionOrders[productionOrderId] = productionOrder

      forEach(productionOrder.operations, operationId => {
        const operation = clone(nextState.operations[operationId])
        operation.locked = locked
        nextState.operations[operationId] = operation
      })
      adjustActivity(nextState, productionOrder.activityId)
      return nextState
    }

    default:
      return state
  }
}
