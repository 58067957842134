import { normalize } from "normalizr"
import { get, values } from "lodash"
import { mergeAllAdditionalProps } from "../../additionalProperties/utils"

import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import { put } from "../../utils/http"
import operationsItem from "../../schemas/planningObjects/operations/item"

export default [
  APIGenericSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_OPERATION_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/v3/operations`,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.operationId}`,
    prepareResponse: (res, state) => {
      const propertyTypes = get(state, "entities.additionalProperties.OPERATION.propertyTypes")
      return {
        ...res,
        operation: mergeAllAdditionalProps(res.operation, "additionalProperties", propertyTypes),
      }
    },
  }),
  APIGenericSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_OPERATION_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/v3/operations`,
    method: put,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareRequest: ({ data, id }) => {
      const entities = normalize(data, operationsItem)
      const operation = get(entities, `entities.operations.${id}`)
      const requestOperation = {
        ...operation,
        additionalProperties: values(operation.additionalProps),
      }
      const params = { operation: requestOperation }
      return params
    },
    prepareResponse: (res, state) => {
      const propertyTypes = get(state, "entities.additionalProperties.OPERATION.propertyTypes")
      return {
        ...res,
        operation: {
          worker: null,
          workerId: null,
          ...mergeAllAdditionalProps(res.operation, "additionalProperties", propertyTypes),
        },
      }
    },
  }),
]
