import { useEffect, useState } from "react"

export const useHelperText = (error, forceDisplayFormError, showEmptyHelperPerDefault, formatMessage) => {
  const [helperText, setHelperText] = useState(error) // used for showing errors under input
  useEffect(() => {
    setHelperText(error)
  }, [error])
  let errText = helperText
  if (showEmptyHelperPerDefault && !helperText) {
    // if no error show empty line - used for fields alignment
    errText = " "
  }
  if (forceDisplayFormError) {
    // skip error from datepicker and take error from top component - used in forms
    errText = error
  }

  // cause datePicker send 2 argument
  const setError = err => setHelperText(err && formatMessage({ id: `validations.${err}` }))
  return [errText, setError]
}
