import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/es/storage"

import workList from "./workList"
import resourceList from "./resourceList"

export default combineReducers({
  workList: persistReducer(
    {
      key: "workList",
      storage,
    },
    workList
  ),
  resourceList: persistReducer(
    {
      key: "resourceList",
      storage,
    },
    resourceList
  ),
})
