import { schema } from "normalizr"

import orderPosition from "../orderPositions/item"
import activities from "../activities"

export default new schema.Entity(
  "orderPositionDetails",
  {
    orderPosition,
    activities,
  },
  {
    idAttribute: value => value.orderPosition.orderPositionId,
  }
)
