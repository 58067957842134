import { filter, find, map, mapValues, reduce, pickBy, sortBy, uniq } from "lodash"
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range"

import { forcedTimezone } from "../../../utils/timeline/forcedTimezone"
import StatusTypes from "../../../types/StatusTypes"

export const getResourceConflicts = (
  id,
  itemsWithStringDates,
  { allowMultipleReservation = false, maxMultipleReservations = null }
) => {
  if (allowMultipleReservation && maxMultipleReservations == null) return []
  const legalCoincidents = allowMultipleReservation ? maxMultipleReservations : 1
  const activeOperations = filter(itemsWithStringDates, operation => operation.status !== StatusTypes.done)
  const events = reduce(
    activeOperations,
    (acc, { operationId, startDate, endDate }) => {
      acc.push(
        { operationId, delta: 1, timestamp: new Date(startDate) },
        { operationId, delta: -1, timestamp: new Date(endDate) }
      )
      return acc
    },
    []
  )
  const eventsSorted = sortBy(events, ["timestamp", "delta"])
  const envelope = []
  reduce(
    eventsSorted,
    (acc, { operationId, delta, timestamp }) => {
      envelope.push({ ...acc, end: timestamp })
      return {
        ids: delta === 1 ? [operationId, ...acc.ids] : acc.ids.filter(eid => eid !== operationId),
        start: timestamp,
      }
    },
    { ids: [], start: null, end: null }
  )

  const conflicts = []
  // Here, the actual limit is applied
  const realConflicts = filter(envelope, ({ ids }) => ids.length > legalCoincidents)
  const rest = reduce(
    realConflicts,
    (acc, curr, ndx) => {
      if (ndx === 0) return curr
      if (acc.end === curr.start) {
        return {
          ids: uniq([...acc.ids, ...curr.ids]),
          start: acc.start,
          end: curr.end,
        }
      }
      conflicts.push(acc)
      return curr
    },
    null
  )
  rest && conflicts.push(rest)
  const mergedConflicts = []
  const restOfMerge = reduce(
    conflicts,
    (acc, curr) => {
      if (acc.end && curr.start && acc.end.valueOf() === curr.start.valueOf()) return mergeTwoConflicts(acc, curr)
      acc.start && mergedConflicts.push(createConflict(acc))
      return curr
    },
    { ids: [], start: null, end: null }
  )
  restOfMerge.start && mergedConflicts.push(createConflict(restOfMerge))

  return mergedConflicts
}

const mergeTwoConflicts = ({ ids: ids1, start }, { ids: ids2, end }) => ({
  ids: uniq([...ids1, ...ids2]),
  start,
  end,
})

const createConflict = ({ ids, start, end }) => ({
  startDate: start.toISOString(),
  endDate: end.toISOString(),
  conflicting: ids,
  id: sortBy(ids).join("-"),
})

/*
 * This function saves a timezone-sensitive final delivery date in the orderPositions for later conflict calulations
 */
export const setExactDeliveryDateOfOrderPositions = nextState => {
  const momentAtMidnight = moment.tz(new Date(), forcedTimezone).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  })
  nextState.orderPositions = mapValues(nextState.orderPositions, orderPosition => {
    if (!orderPosition.deliveryDate) {
      return orderPosition
    }

    /*
     * This is a more performant variant of moment.tz(deliveryDate, forcedTimezone).set(midnight)
     * It is faster because we save the initialize of a moment.tz object whtih the loop
     */
    const deliveryDateSplitted = orderPosition.deliveryDate.split("-") // endDate is a day in format YYYY-MM-DD
    const endOfDeliveryDay = momentAtMidnight
      .set({
        year: deliveryDateSplitted[0],
        month: parseInt(deliveryDateSplitted[1], 10) - 1,
        date: deliveryDateSplitted[2],
      })
      .format()

    return {
      ...orderPosition,
      endOfDeliveryDay,
    }
  })
}

export const setConflictsByOrderPosition = nextState => {
  setExactDeliveryDateOfOrderPositions(nextState)
  const today = new Date()
  nextState.conflicts = {
    ...nextState.conflicts,
    datesMissedByPosition: map(
      filter(nextState.orderPositions, ({ endDate, endOfDeliveryDay, status }) => {
        if (status !== StatusTypes.done && endDate && new Date(endDate) >= today) {
          return new Date(endDate) > new Date(endOfDeliveryDay)
        }
        if (status !== StatusTypes.done && endDate && new Date(endDate) < today) {
          return new Date(endOfDeliveryDay) < today
        }
        return false
      }),
      dateConflict => ({
        ...dateConflict,
        delayedUntilToday: new Date(dateConflict.endDate) < today && new Date(dateConflict.endOfDeliveryDay) < today,
        name: nextState.articles[dateConflict.article].articleName,
      })
    ),
  }
}

export const setConflictsByResource = (nextState, resourceId) => {
  const resource = nextState.resources[resourceId]
  nextState.conflicts = {
    ...nextState.conflicts,
    operationsByResource: {
      ...nextState.conflicts.operationsByResource,
      [resourceId]: getResourceConflicts(
        "operationId",
        filter(nextState.operations, operation => operation.resource === resourceId),
        resource
      ),
    },
  }
}

export const setOperationConflictsByStatus = nextState => {
  const now = new Date().valueOf() + 60 * 60 * 10 // conflict if the operation has not started after ten minutes
  const { resources, operations } = nextState
  const operationsDelayed = pickBy(operations, ({ resource, startDate, status }) => {
    const operationResource = find(resources, resourceItem => resourceItem.resourceId === resource)
    return status < StatusTypes.started && Date.parse(startDate) < now && !operationResource.progressesForbidden
  })

  nextState.conflicts = {
    ...nextState.conflicts,
    operationsDelayed,
  }
}
