import { put, call, select, takeLatest } from "redux-saga/effects"
import { get, keys } from "lodash"
import ActionTypes from "../../constants/ActionTypes"
import { API_SERVER_USER } from "../../constants/api"
import { get as getHttp } from "../../utils/http"
import { formatAPIResponse } from "../../utils/api"
import { createAction } from "../../utils/redux"
import { getUserData } from "../selectors"
import { initialModulesAreas } from "../../constants/config"
import { goToLogin, checkForValidSession } from "./authentication"

function tryRequestLicenceModules(userId, token) {
  return getHttp({
    url: `${API_SERVER_USER}/licenceModule?userId=${userId}`,
    token,
  }).then(formatAPIResponse)
}

export function* fetchLicenceModules(token, userId) {
  try {
    let accessibleLicenceModules = yield call(tryRequestLicenceModules, userId, token)

    accessibleLicenceModules = [...accessibleLicenceModules, ...initialModulesAreas]

    yield put(createAction(ActionTypes.ACCESSIBLE_LICENCE_MODULES_LIST_SUCCESS, { accessibleLicenceModules }))
  } catch (e) {
    // two times F5 cancel this call and cause to logout the user
    // therefore we run this async
    put(createAction(ActionTypes.USER_LOGOUT_REQUEST, { userId, token }))
  }
}

function* requestLicenceModules() {
  const { token, userId } = yield select(getUserData)

  yield fetchLicenceModules(token, userId)
}

function* requestLicenceModulesUpdate({ payload }) {
  const users = get(payload, "entities.userDetails") || {}
  const userIds = keys(users)
  const { token, userId } = yield select(getUserData)

  try {
    yield checkForValidSession(token)
    if (userIds.includes(`${userId}`) && token && userId) {
      yield call(fetchLicenceModules, token, userId)
    }
  } catch (e) {
    yield call(goToLogin)
  }
}

export default [
  takeLatest(ActionTypes.ACCESSIBLE_LICENCE_MODULES_LIST_REQUEST, requestLicenceModules),
  // update logged in user's accessible licence modules if logged in user's licence modules changed
  takeLatest(ActionTypes.USERS_UPDATE_SUCCESS, requestLicenceModulesUpdate),
  /*
  update logged in user's accessible licence modules even if the user's modules did not change
  (the payload does not privide information to identify whether the logged in user is affected by the update)
  do not want to overoptimize here
  */
  takeLatest(ActionTypes.LICENCE_MODULES_DETAILS_UPDATE_SUCCESS, requestLicenceModules),
]
