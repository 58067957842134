import { without } from "lodash"
import { FileType } from "../../types/FileType"

import ActionTypes from "../../constants/ActionTypes"

interface State {
  files: FileType[]
  isError: boolean
  isPending: boolean
}

interface actionSet {
  type: string
  payload?: FileType
}

const initialState: State = {
  files: [],
  isError: false,
  isPending: false,
}

export default (state: State = initialState, action: actionSet) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.FILE_UPLOAD_REQUEST: {
      return {
        ...state,
        isPending: true,
      }
    }

    case ActionTypes.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isPending: false,
        isError: true,
      }

    case ActionTypes.FILE_UPLOAD_SUCCESS: {
      const { fileIdentifier, fileName, originFileName, size, type } = payload
      return {
        ...state,
        isPending: false,
        files: [...state.files, { fileIdentifier, fileName, originFileName, size, type }],
      }
    }

    case ActionTypes.FILE_UPLOAD_REMOVE_FILE: {
      return {
        ...state,
        files: without(state.files, payload),
      }
    }

    case ActionTypes.FILE_UPLOAD_CLEAR_FILELIST: {
      return {
        ...state,
        files: [],
      }
    }

    default:
      return state
  }
}
