import { push } from "connected-react-router"
import { takeLatest, put } from "redux-saga/effects"
import { matchRoutes } from "react-router-config"
import ActionTypes from "../../../constants/ActionTypes"
import { Routes } from "../../../constants/routes"

import { timelineRoutes } from "../../../reducers/timeline/selection"
import { setSelectedUserFilter } from "../../../actions/ui/timelines"
import { clearBreadcrumbs } from "../../../actions/ui/breadcrumbs"

const allTimelineRoutes = [...timelineRoutes, { path: Routes.PLANNING_TIMELINE }, { path: Routes.PRODUCTION_TIMELINE }]

function closeDrawer(url) {
  return function* navigateAwayFromDetails() {
    yield put(push(url))
  }
}

function navigate(base, idName) {
  return function* navigateToDetails(action) {
    const id = action.payload[idName]
    yield put(push(`${base}/${id}`))
  }
}

let oldPath = ""
function checkWhetherWeNeedToResetTimelineFilters() {
  return function* check(action) {
    const newPath = action.payload.location.pathname
    const match = matchRoutes(timelineRoutes, newPath)

    if (match.length) {
      if (oldPath !== "" && matchRoutes(allTimelineRoutes, oldPath).length === 0) {
        // We jump in from outside the timeline
        yield put({ type: ActionTypes.TIMELINE_FILTER_RESET })
        yield put({ type: ActionTypes.UI_TIMELINE_FILTER_CLEAR })
        yield put(setSelectedUserFilter(null))
      }
    }
    oldPath = newPath
  }
}

function* resetBreadcrumbs() {
  yield put(clearBreadcrumbs())
}

export default [
  takeLatest(ActionTypes.UI_TIMELINE_CLOSE_PLANNING_DRAWER, closeDrawer(Routes.PLANNING_TIMELINE)),
  takeLatest(ActionTypes.UI_TIMELINE_CLOSE_PRODUCTION_DRAWER, closeDrawer(Routes.PRODUCTION_TIMELINE)),
  takeLatest(
    ActionTypes.UI_TIMELINE_PLANNING_DETAILS_PROJECT_NAVIGATE,
    navigate(Routes.PLANNING_TIMELINE_PROJECTS, "projectId")
  ),
  takeLatest(
    ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ORDER_POSITION_NAVIGATE,
    navigate(Routes.PLANNING_TIMELINE_ORDERPOSITIONS, "orderPositionId")
  ),
  takeLatest(
    ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ACTIVITY_NAVIGATE,
    navigate(Routes.PLANNING_TIMELINE_ACTIVITIES, "activityId")
  ),
  takeLatest(
    ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_PRODUCTION_ORDER_NAVIGATE,
    navigate(Routes.PRODUCTION_TIMELINE_PRODUCTIONORDERS, "productionOrderId")
  ),
  takeLatest(
    ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_OPERATION_NAVIGATE,
    navigate(Routes.PRODUCTION_TIMELINE_OPERATIONS, "operationId")
  ),
  takeLatest("@@router/LOCATION_CHANGE", checkWhetherWeNeedToResetTimelineFilters()),
  takeLatest("@@router/LOCATION_CHANGE", resetBreadcrumbs),
]
