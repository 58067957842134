import { HubConnectionBuilder } from "@microsoft/signalr"
import { get, forEach, map } from "lodash"
import { API_SERVER_NOTIFICATIONS, API_SERVER_NOTIFICATIONS_RECEIVE_ONLY } from "../constants/api"
import ActionTypes from "../constants/ActionTypes"
import { preparePrefixUrl } from "../utils/api"

const CONFLICTS_COUNT = "conflictsCountAsync"

const fallbackStart = socket => {
  setTimeout(() => {
    if (socket && socket.state === "Disconnected") {
      socket.start().catch(() => {
        fallbackStart(socket)
      })
    }
  }, 3000)
}

const createSocket = (store, api, token) => {
  const socket = new HubConnectionBuilder()
    // .configureLogging(LogLevel.Debug)
    .withUrl(api, {
      accessTokenFactory: () => token,
    })
    .build()
  socket.start().then(() => initializeHandlers(socket, store))
  socket.onclose(err => {
    const actualToken = get(store.getState(), "user.authentication.token", null)
    if (err) {
      // eslint-disable-next-line no-console
      console.log("Connection closed with error: ", err)
    }
    if (err || actualToken) {
      fallbackStart(socket)
    }
  })
  return socket
}

const initializeHandlers = (socket, store) => {
  socket.on(CONFLICTS_COUNT, message => {
    store.dispatch({
      type: ActionTypes.SOCKET_RECEIVED_CONFLICTS_COUNT,
      payload: message,
    })
  })
  socket.invoke(CONFLICTS_COUNT, null)
}

const handleOutgoing = (socket, action) => {
  if (!socket) {
    return
  }
  // We don't have a real case for this yet, so it will change to something less general
  if (action.type === ActionTypes.SEND_WEBSOCKET_MESSAGE) {
    socket.send(action.payload)
  }
}

const createSocketsMiddleware = store => {
  // Sometimes we will have cases when we have multiple instances working with one database (i.e BMW).
  // In order to be able to receive messages from all instances, we keep a connection for each additional api, but only for listening.
  const apis = [API_SERVER_NOTIFICATIONS, ...(API_SERVER_NOTIFICATIONS_RECEIVE_ONLY || [])]
  const {
    simulation: { id: simulationId },
  } = store.getState()
  const apisWithPrefix = map(apis, api => preparePrefixUrl(api, simulationId))
  const sockets = {}
  return next => action => {
    if (action.type === ActionTypes.USER_AUTO_LOGIN_SUCCESS || action.type === ActionTypes.USER_LOGIN_SUCCESS) {
      const token =
        action.type === ActionTypes.USER_LOGIN_SUCCESS
          ? get(action, "payload.user.token")
          : get(action, "payload.token")
      forEach(apisWithPrefix, api => {
        const socket = sockets[api]
        if (!socket || socket.state === "Disconnected") {
          sockets[api] = createSocket(store, api, token)
        }
      })
    }

    if (
      action.type === ActionTypes.USER_AUTO_LOGIN_FAILURE ||
      action.type === ActionTypes.USER_AUTO_LOGIN_TERMINAL_FAILURE ||
      action.type === ActionTypes.USER_LOGOUT_REQUEST
    ) {
      forEach(apisWithPrefix, api => {
        const socket = sockets[api]
        if (socket && socket.state !== "Disconnected") {
          socket.stop()
        }
      })
    }

    handleOutgoing(sockets[preparePrefixUrl(API_SERVER_NOTIFICATIONS, simulationId)], action)

    return next(action)
  }
}

export default createSocketsMiddleware
