import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import CircularProgress from "@mui/material/CircularProgress"

const styles = {
  loading: {
    left: "50%",
    position: "absolute",
    top: "50%",
  },
}

// eslint-disable-next-line no-unused-vars
const Spinner = React.forwardRef(({ className, classes, disableShrink, size, thickness, zIndex }, ref) => {
  const margin = -(size / 2)
  return (
    <CircularProgress
      style={{
        zIndex,
        marginLeft: margin,
        marginTop: margin,
      }}
      className={cn(classes.loading, className)}
      size={size}
      variant="indeterminate"
      disableShrink={disableShrink}
      thickness={thickness}
    />
  )
})

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
  zIndex: PropTypes.number,
  thickness: PropTypes.number,
  disableShrink: PropTypes.bool,
}

Spinner.defaultProps = {
  zIndex: 9,
  size: 72,
  thickness: 3.6,
  disableShrink: true,
}

export default withStyles(styles)(Spinner)
