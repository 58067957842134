import React from "react"
import Link, { LinkProps } from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import { lightSkyBlue } from "../../constants/colors"

interface LinkWithTypographyType extends LinkProps {
  caption: string
}

const LinkWithTypography = ({ caption, onClick, href, target, sx, tabIndex }: LinkWithTypographyType) => (
  <Link
    sx={{ color: lightSkyBlue, ...sx }}
    href={href}
    target={target}
    color="inherit"
    onClick={onClick}
    tabIndex={tabIndex}
  >
    <Typography variant="body2">{caption}</Typography>
  </Link>
)

LinkWithTypography.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  sx: PropTypes.object,
  tabIndex: PropTypes.string,
}

LinkWithTypography.defaultProps = {
  onClick: (): unknown => null,
  href: "#",
  target: "_self",
  sx: {},
  tabIndex: "",
}

export default LinkWithTypography
