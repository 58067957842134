import React from "react"
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore"
import withStyles from "@mui/styles/withStyles"

const styles = {
  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    color: "white",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

const SettingsBackupRestoreCusomized = ({ classes, ...props }) => (
  <div className={classes.icon}>
    <SettingsBackupRestore {...props} />
  </div>
)

export default withStyles(styles)(SettingsBackupRestoreCusomized)
