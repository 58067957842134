import { flatten, includes, keyBy, map, omitBy } from "lodash"
import { normalize } from "normalizr"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"
import { projectSchema } from "../../schemas/timeline"
import { cloneStore, updateConflictsForResources } from "./helpers/updateHelpers"
import { getAffectedResources } from "./helpers/helpers"

const filterItemsOfProjects = (items, projectsAffected) =>
  omitBy(items, ({ projectId }) => includes(projectsAffected, projectId))

const saveChangedState = (state, payload) => {
  const { projects, affectedProjects } = payload
  const { entities, result: newProjects } = normalize(projects, projectSchema)

  const stateWithoutAffected = {
    ...cloneStore(state),
    activities: filterItemsOfProjects(state.activities, affectedProjects),
    operations: filterItemsOfProjects(state.operations, affectedProjects),
    orderPositions: filterItemsOfProjects(state.orderPositions, affectedProjects),
    productionOrders: filterItemsOfProjects(state.productionOrders, affectedProjects),
    projects: filterItemsOfProjects(state.projects, affectedProjects),
  }
  const nextState = {
    ...stateWithoutAffected,
    projects: {
      ...stateWithoutAffected.projects,
      ...keyBy(newProjects, "projectId"),
    },
    orderPositions: {
      ...stateWithoutAffected.orderPositions,
      ...entities.orderPositions,
    },
    activities: {
      ...stateWithoutAffected.activities,
      ...entities.activities,
    },
    productionOrders: {
      ...stateWithoutAffected.productionOrders,
      ...entities.productionOrders,
    },
    operations: {
      ...stateWithoutAffected.operations,
      ...entities.operations,
    },
  }
  const affectedResources = flatten(
    map(affectedProjects, projectId => getAffectedResources(projectId, state, nextState))
  )
  updateConflictsForResources(nextState, affectedResources)

  return nextState
}

export default (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    // This action reloads specific projects in timeline. Useful when we make changes via graphql and don't want to reload all the data again
    case ActionTypes.TIMELINE_UPDATE_PROJECTS: {
      return saveChangedState(state, payload)
    }

    // save the state with redux-undo, TIMELINE_UPDATE_PROJECTS - action filtered
    case ActionTypes.TIMELINE_UPDATE_PROJECTS_SAVE_TO_PAST: {
      return saveChangedState(state, payload)
    }
    default:
      return state
  }
}
