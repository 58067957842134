import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.BASEDATA_TECHNOLOGY_DETAILS_UPDATE_SUCCESS: {
      return {
        ...state,
        [payload.result.technology]: {
          ...state[payload.result.technology],
          archived: payload.data.technology.archived,
        },
      }
    }

    default:
      return state
  }
}
