import React, { Suspense, lazy, useEffect } from "react"
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { get } from "lodash"
import { useAuth } from "react-oidc-context"

import { Routes } from "../../constants/routes"
import Login from "../../pages/Login"
import Logout from "../../pages/Logout"
import checkVersion from "./versionUpdate"
import LoadingPage from "../../pages/Login/LoadingPage"
import Autologout from "../../pages/Autologout/Autologout"
import SnackbarManager from "../../components/SnackbarManager"
import { Loading } from "../../components/DetailsForms"
import Spinner from "../../components/Spinner"

const PlaneusTv = lazy(() => import("../../pages/PlaneusTv/PlaneusTv"))
const Terminal = lazy(() => import("../../pages/Terminal/Terminal"))
const VideoTutorials = lazy(() => import("../../pages/VideoTutorials/VideoTutorials"))
const ResetPassword = lazy(() => import("../../pages/Login/ResetPassword/ResetPassword"))
const ChangeLogs = lazy(() => import("../../pages/ChangeLogs/ChangeLogs"))
const ForgotPassword = lazy(() => import("../../pages/Login/ResetPassword/ForgotPassword"))
const FakeUser = lazy(() => import("../../pages/FakeUser"))
const AuthenticatedApp = lazy(() => import("../AuthenticatedApp"))

const isSSOCustomer = process.env.REACT_APP_SSOCUSTOMER === "true"

const App = React.memo(() => {
  const history = useHistory()
  const location = useLocation()
  const { authenticated, isLoaded, user, isPendingSSO, isPendingCheckToken } = useSelector(
    state => state.user.authentication
  )
  const authAdamos = useAuth()

  useEffect(() => {
    const isFakeUser = get(user, "isFakeUser", false)
    if (isFakeUser && location.pathname.indexOf("planeustvscreen") === -1) {
      history.push(Routes.FAKE_USER)
    }
  }, [authenticated])

  useEffect(() => {
    const interval = setInterval(checkVersion, 1000 * 60 * 5)
    return () => clearInterval(interval)
  }, [])

  if (isSSOCustomer && !isLoaded && isPendingCheckToken) return null

  const loadingOnSignin =
    authAdamos.activeNavigator === "signinSilent" || authAdamos.activeNavigator === "signinRedirect"
  return (
    <Suspense
      fallback={
        <Loading>
          <Spinner />
        </Loading>
      }
    >
      <Switch>
        <Route component={Logout} path={Routes.LOGOUT} />
        <Route component={Autologout} exact path={[Routes.AUTO_LOGOUT_TERMINAL, Routes.AUTO_LOGOUT]} />
        <Route component={PlaneusTv} path={Routes.TVSCREEN} />
        <Route component={Terminal} path={Routes.TERMINAL} />
        <Route component={VideoTutorials} path={Routes.VIDEO_TUTORIALS} />
        <Route component={ChangeLogs} path={Routes.CHANGE_LOGS} />
        <Route component={FakeUser} path={Routes.FAKE_USER} />
        {(authAdamos.isLoading || isPendingSSO || loadingOnSignin) && <Route component={LoadingPage} />}
        {authenticated && <Route component={AuthenticatedApp} />}
        <Route component={Login} path={Routes.LOGIN} />
        <Route component={ForgotPassword} path={Routes.FORGOT_PASSWORD} />
        <Route component={ResetPassword} path={Routes.RESET_PASSWORD} />
        <Redirect push to={Routes.LOGIN} />
      </Switch>
      <SnackbarManager />
    </Suspense>
  )
})

export default App
