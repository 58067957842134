import { schema } from "normalizr"
import userRoleList from "../userRoles"
import licenceModule from "../licenceModules/item"

export default new schema.Entity(
  "licenceModuleDetails",
  {
    userRoleList,
    licenceModule,
  },
  {
    idAttribute: value => value.licenceModule.moduleLicenceId,
  }
)
