import { push } from "connected-react-router"
import { generatePath } from "react-router"
import { call, delay, put, select, takeLatest } from "redux-saga/effects"
import { get } from "lodash"

import { API_SERVER_USER } from "../../constants/api"
import { Routes } from "../../constants/routes"
import ActionTypes from "../../constants/ActionTypes"
import { formatAPIResponse } from "../../utils/api"
import { put as putFetch } from "../../utils/http"
import { createAction } from "../../utils/redux"
import { getState, getTerminalId, getUserData } from "../selectors"
import { getValue } from "../../utils/storage"
import { fetchLicenceModules } from "./accessibleLicenceModules"
import RouteSegments from "../../pages/Terminal/Worklist/RouteSegments"
import hash from "../../utils/hash"
import { goToTerminalLogin, tryLogout } from "./authentication"
import { setTerminalMode } from "../../actions/app"

function* goToTerminal() {
  const { userId, token } = yield select(getUserData)
  const terminal = yield select(getTerminalId)

  // If user has scan license, we want to redirect directly to scan screen
  yield call(fetchLicenceModules, token, userId)
  const accessibleLicenceModules = yield select(state => state.user.accessibleLicenceModules)
  const hasScan = get(accessibleLicenceModules, "entities.modules.workbenchscan")
  yield put(
    push(
      generatePath(`${Routes.TERMINAL_CHOOSE_WORK}${hasScan ? RouteSegments.SCAN : ""}`, {
        terminalId: terminal,
      })
    )
  )
}

function* goDirectlyToTerminal(action) {
  const { terminal } = action.payload

  const {
    user: {
      terminal: { terminal: termimalFromStore },
    },
  } = yield select(getState)
  yield delay(1000)
  if (terminal || termimalFromStore) {
    yield put(push(generatePath(Routes.TERMINAL_CHOOSE_WORK, { terminalId: terminal })))
  } else {
    yield put(push(generatePath(Routes.LOGIN)))
  }
}

function* login(action) {
  try {
    const { pin, terminal } = action.payload
    const terminalLockToken = getValue("terminalToken") // terminalLockToken token
    const user = yield call(tryLogin, terminal, pin, terminalLockToken)
    yield put(createAction(ActionTypes.PIN_LOGIN_SUCCESS, { user }))
  } catch (e) {
    const { message } = e
    const errorPayload = {
      message,
      originalAction: action,
    }
    yield put(createAction(ActionTypes.PIN_LOGIN_FAILURE, errorPayload))
  }
}

function tryLogin(terminal, pin, terminalLockToken) {
  const options = {
    url: `${API_SERVER_USER}/terminals/${terminal}/login`,
    customHeaders: {
      AuthorizationPin: typeof pin === "string" && pin.length > 4 ? hash(pin) : pin,
      AuthorizationTerminal: terminalLockToken,
    },
  }

  return putFetch(options).then(formatAPIResponse)
}

function* logoutToTerminal({ payload: { terminal, userId, token } }) {
  try {
    yield call(tryLogout, userId, token, true)
    yield call(goToTerminalLogin, terminal)
  } catch (e) {
    yield put(createAction(ActionTypes.LOGOUT_TO_TERMINAL_FAILURE))
    yield call(goToTerminalLogin, terminal)
  }
  yield put(setTerminalMode())
}

export default [
  takeLatest(ActionTypes.PIN_LOGIN, login),
  takeLatest(ActionTypes.PIN_LOGIN_SUCCESS, goToTerminal),
  takeLatest(ActionTypes.PIN_AUTO_LOGIN, goDirectlyToTerminal),
  takeLatest(ActionTypes.LOGOUT_TO_TERMINAL_REQUEST, logoutToTerminal),
]
