import ActionTypes from "../../constants/ActionTypes"
import AppMode from "../../types/AppMode"

const initialState = {
  mode: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_STANDARD_MODE: {
      return {
        ...state,
        mode: AppMode.standard,
      }
    }
    case ActionTypes.SET_TERMINAL_MODE: {
      return {
        ...state,
        mode: AppMode.terminal,
      }
    }
    default:
      return state
  }
}
