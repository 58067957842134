import React from "react"
import Typography from "@mui/material/Typography"
import InputLabel from "@mui/material/InputLabel"
import makeStyles from "@mui/styles/makeStyles"
import cn from "classnames"
import PropTypes from "prop-types"

import TooltipOverflow from "../../TooltipOverflow/TooltipOverflow"

const useStyles = makeStyles({
  minHeight: {
    minHeight: "1.1876em",
    whiteSpace: "pre-wrap",
  },
})

const LabelValueText = ({ className, label, value }) => {
  const classes = useStyles()
  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <TooltipOverflow>
        <Typography className={cn(className, classes.minHeight)} variant="body2" component="div">
          {value}
        </Typography>
      </TooltipOverflow>
    </>
  )
}

LabelValueText.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
}

LabelValueText.defaultProps = {
  className: "",
  value: "",
}

export default LabelValueText
