import { normalize } from "normalizr"
import { get } from "lodash"
import userDetailsSchema from "../../schemas/userDetails/item"
import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.USERS_UPDATE_SUCCESS: {
      const normalizationResult = normalize(payload.data[0], userDetailsSchema)
      const normalizedUserDetails = get(normalizationResult, `entities.userDetails[${payload.data[0].userId}]`, {})
      return {
        ...state,
        [payload.data[0].userId]: {
          ...normalizedUserDetails,
          lastFetchedAt: new Date(),
        },
      }
    }
    case ActionTypes.USERS_DETAILS_SUCCESS: {
      const normalizationResult = normalize(payload.data[0], userDetailsSchema)
      const normalizedUserDetails = get(normalizationResult, `entities.userDetails[${payload.data[0].userId}]`, {})
      return {
        ...state,
        [payload.data[0].userId]: {
          ...normalizedUserDetails,
          rfidNumber: normalizedUserDetails.rfidNumber || null,
        },
      }
    }
    default:
      return state
  }
}
