import { get, includes, orderBy, reduce } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { setConflictsByOrderPosition } from "./helpers/conflicts"
import { initialState } from "./initialState"

import {
  moveActivity,
  updateActivity,
  adjustActivitySiblings,
  adjustOrderPosition,
  cloneStore,
} from "./helpers/helpers"
import { newTimelineStateWithNew } from "./helpers/operationsHelpers"

export default (state = initialState, action, holidays, globalsettings) => {
  const { payload, type } = action
  const { selection } = state
  const { unlockedActivities } = state.settings
  const freeMoveTimeline = get(globalsettings, "settings.freeMoveTimeline", false)
  switch (type) {
    case ActionTypes.TIMELINE_ACTIVITY_MOVE: {
      const {
        item: { start: newStart, itemId: activityId },
      } = payload
      const newStartDate = new Date(newStart)

      const activity = state.activities[activityId]
      const { startDate: oldStart } = activity
      const oldStartDate = new Date(oldStart)

      const difference = newStartDate - oldStartDate

      const sortedActivities = orderBy(
        selection,
        [({ id }) => new Date(state.activities[id].startDate)],
        [difference > 0 ? "asc" : "desc"]
      )

      let currentDifference = difference
      let nextState = newTimelineStateWithNew(payload, state)
      /* Move all selected activities */
      nextState = reduce(
        sortedActivities,
        (tempState, { id }) => {
          const originalActivity = tempState.activities[id]
          const { endDate: oldEnd, startDate: oldActivityStart } = originalActivity
          const [, allProductionOrderFixed] = moveActivity(tempState, id, currentDifference)
          if (allProductionOrderFixed) {
            currentDifference = difference
            return tempState
          }
          const movedActivity = tempState.activities[id]
          const { endDate: newEnd, startDate: newActivityStart } = movedActivity
          currentDifference =
            difference > 0
              ? new Date(newEnd) - new Date(oldEnd)
              : new Date(newActivityStart) - new Date(oldActivityStart)

          return tempState
        },
        nextState
      )

      const updatedActivity = nextState.activities[activityId]
      if (!includes(unlockedActivities, activityId) || !freeMoveTimeline) {
        adjustActivitySiblings(nextState, activity, updatedActivity)
      }
      adjustOrderPosition(nextState, activity.orderPositionId)
      setConflictsByOrderPosition(nextState)

      return nextState
    }

    case ActionTypes.TIMELINE_ACTIVITY_MOVE_PREVIEW: {
      const {
        item: { start: newStart, itemId: activityId },
      } = payload
      const newStartDate = new Date(newStart)

      const activity = state.activities[activityId]
      const { startDate: oldStart } = activity
      const oldStartDate = new Date(oldStart)

      const difference = newStartDate - oldStartDate

      /* Move all selected activities */
      const [nextState] = moveActivity(cloneStore(state), activityId, difference)
      const activityPreview = nextState.activities[activityId]

      return { ...state, activityPreview }
    }

    case ActionTypes.TIMELINE_ACTIVITY_MOVE_PREVIEW_CLEAR: {
      return { ...state, activityPreview: null }
    }

    case ActionTypes.TIMELINE_ACTIVITY_RESIZE: {
      const {
        item: { itemId: activityId, start: newStart, end: newEnd },
      } = payload
      // we don't move production activities
      if (new Date(newStart) > new Date(newEnd)) {
        return state
      }
      const activity = state.activities[activityId]

      const nextState = newTimelineStateWithNew(payload, state)
      const updatedActivity = updateActivity(nextState, activity, newStart, newEnd)

      nextState.activities = {
        ...nextState.activities,
        [activityId]: updatedActivity,
      }

      if (!includes(unlockedActivities, activityId)) {
        adjustActivitySiblings(nextState, activity, nextState.activities[activityId])
      }
      adjustOrderPosition(nextState, activity.orderPositionId)
      setConflictsByOrderPosition(nextState)

      return nextState
    }

    default:
      return state
  }
}
