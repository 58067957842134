export const initialState = {
  articles: {},
  activityPreview: null,
  blockFocusOnNextRouteChange: false, // signals whether the next LOCATION_CHANGE should trigger focusing of item
  capacityPlans: {},
  conflicts: {
    datesMissedByPosition: [],
    operationsByResource: {},
    operationsDelayed: {},
  },
  customers: {},
  dataTimestamp: 0,
  filters: {
    clever: null,
    // this filters are derived from actions and cannot be set by the user directly
    productionOrders: [],
    resourceGroups: [],
  },
  holidays: [],
  operations: {},
  operationPreview: {},
  operationsResourcesSuggestions: {}, // must be empty
  orderPositions: {},
  projects: {},
  resourceGroups: {},
  resources: {},
  selection: [],
  settings: {
    unlockedActivities: [],
    unlockedOperations: [],
  },
  status: {
    isLoaded: false,
    isPending: false,
  },
  valueAdds: {},
}
