export const initialState = {
  isPending: false,
  isLoaded: false,
  error: null, // normally only true or false
  entities: [],
}

export function createAPIRequestReducer(APIActionSequenceName) {
  return function APIRequestReducer(state = initialState, action) {
    switch (action.type) {
      case `${APIActionSequenceName}_DO_REQUEST`: {
        return {
          ...state,
          error: null,
          isPending: true,
          isLoaded: false,
        }
      }

      case `${APIActionSequenceName}_SUCCESS`: {
        return {
          ...state,
          isPending: false,
          isLoaded: true,
          error: null,
          entities: action.payload.result,
        }
      }

      case `${APIActionSequenceName}_FAILURE`:
        return {
          ...state,
          isPending: false,
          isLoaded: false,
          error: {
            status: 403, // need to find a better status number
          },
        }

      default:
        return state
    }
  }
}

export const genericRequestState = {
  isPending: false,
  isFinished: false,
  isError: false,
}
/*
 * More generic version of the request reducer without assumptions of the request content
 */
export function createGenericRequestReducer(APIActionSequenceName) {
  return function GenericRequestReducer(state = genericRequestState, action) {
    switch (action.type) {
      case `${APIActionSequenceName}_REQUEST`:
        return {
          ...state,
          isError: false,
          isPending: true,
        }

      case `${APIActionSequenceName}_SUCCESS`: {
        return {
          isPending: false,
          isFinished: true,
          isError: false,
        }
      }

      case `${APIActionSequenceName}_FAILURE`:
        return {
          ...state,
          isPending: false,
          isError: true,
        }

      default:
        return state
    }
  }
}
