import { head, last, slice } from "lodash"

import { filterTimeSlicesInRange } from "./filterTimeSlicesInRange"

/* Efficiently trim given sorted and merged time items */
export const trimTimeSlicesIntoRange = (orderedSlices, start, end) => {
  const orderedSlicesWithoutOutliers = filterTimeSlicesInRange(orderedSlices, start, end)

  if (orderedSlicesWithoutOutliers.length === 0) {
    return []
  }

  const startDate = typeof start === "string" ? new Date(start) : start
  const endDate = typeof end === "string" ? new Date(end) : end

  const firstItem = head(orderedSlicesWithoutOutliers)
  const lastItem = last(orderedSlicesWithoutOutliers)
  const itemsInBetween = slice(orderedSlicesWithoutOutliers, 1, -1)

  const trimmedItems = [
    {
      ...firstItem,
      end: firstItem.end > endDate ? endDate : firstItem.end,
      start: firstItem.start < startDate ? startDate : firstItem.start,
    },
    ...itemsInBetween,
  ]

  if (firstItem !== lastItem) {
    trimmedItems.push({
      ...lastItem,
      end: lastItem.end > endDate ? endDate : lastItem.end,
      start: lastItem.start < startDate ? startDate : lastItem.start,
    })
  }

  return trimmedItems
}
