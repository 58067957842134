import { values, without } from "lodash"

import ActionTypes from "../constants/ActionTypes"
import { initialState } from "./api"

export function createPlanningObjectsReducer(planningObjectType) {
  return function PlanningObjectsReducer(state = initialState, action) {
    switch (action.type) {
      case ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS: {
        const withoutDeleted = without(state.entities, ...action.payload[planningObjectType])
        if (values(withoutDeleted).length !== values(state.entities).length) {
          return {
            ...state,
            entities: without(state.entities, ...action.payload[planningObjectType]),
          }
        }
        return state
      }
      default:
        return state
    }
  }
}
