import { useTypedSelector } from "../hooks/reduxHooks"

export const isBaselineReadOnly = (simulationId: number, baselineId: number) =>
  simulationId && simulationId === baselineId

export const useBaselineReadOnly = () => {
  const simulationId = useTypedSelector(state => state.simulation.id)
  const baselineId = useTypedSelector(state => state.simulation.baselineId)
  return isBaselineReadOnly(simulationId, baselineId)
}
