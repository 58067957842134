import projects from "./projects/index"
import technologies from "./technologies/index"
import activities from "./activities/index"
import templates from "./templates/index"

import customers from "./customers"
import deletePlanningObjects from "./deletePlanningObjects"
import holidays from "./holidays"
import imports from "./imports"
import resources from "./resources"
import orderPositions from "./orderPositions"
import operations from "./operations"
import productionOrders from "./productionOrders"
import amountUnits from "./amountUnits"
import licenceModules from "./licenceModules"
import userList from "./userList"
import roles from "./roles"
import modules from "./modules"

export default [
  ...technologies,
  ...imports,
  ...templates,
  ...resources,
  ...customers,
  ...deletePlanningObjects,
  ...holidays,
  ...projects,
  ...orderPositions,
  ...activities,
  ...productionOrders,
  ...operations,
  ...amountUnits,
  ...licenceModules,
  ...userList,
  ...roles,
  ...modules,
]
