import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import DocumentTitle from "react-document-title"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import { Fade } from "@mui/material"

import InfoLabel from "../../components/InfoLabel"
import { PlaneusCompleteLogo } from "../../theme/Planeus"
import Spinner from "../../components/Spinner"
import { lightblue } from "../../constants/colors"

const IS_TEST_SYSTEM = window.planeusConfig && window.planeusConfig.environment === "TESTSYSTEM"
const IS_DEMO_SYSTEM = window.planeusConfig && window.planeusConfig.environment === "DEMOSYSTEM"

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexGrow: 1,
    backgroundColor: lightblue,
    background: "linear-gradient(150deg, #00a5e5 -10%, #00346e 100%)",
  },
  label: {
    position: "absolute",
    top: 0,
    right: 20,
  },
  "@media screen and (max-height: 500px)": {
    label: {
      height: 20,
    },
  },
  logo: {
    width: 240,
    position: "absolute",
    left: "50%",
    top: "20%",
    marginLeft: -120,
  },
  spinner: {
    color: "white",
  },
})

const LoadingPage = () => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <DocumentTitle title={formatMessage({ id: "modules.login" })} />
      {IS_TEST_SYSTEM && (
        <InfoLabel
          label={<FormattedMessage id="app.appBar.testSystem" />}
          className={classes.label}
          show={IS_TEST_SYSTEM}
        />
      )}
      {IS_DEMO_SYSTEM && (
        <InfoLabel label={<FormattedMessage id="app.appBar.demoSystem" />} className={classes.label} />
      )}
      <Fade timeout={800} in>
        <Grid item xs={11} sm={8} md={4} lg={3} xl={2}>
          <PlaneusCompleteLogo className={classes.logo} />
          <Spinner className={classes.spinner} thickness={5} disableShrink={false} />
        </Grid>
      </Fade>
    </div>
  )
}

export default LoadingPage
