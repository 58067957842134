import moment from "moment"
import { getFormattedDate } from "./utils"

export const useAcceptValueOnBlur = (valueToAccept, changeValue, onChange, onBlur, format) => () => {
  const mom = valueToAccept && valueToAccept.isValid() ? moment(valueToAccept) : null
  const valueToWrite = getFormattedDate(mom, format)
  onChange(valueToWrite)
  if (onBlur) {
    onBlur(valueToWrite)
  }
  if (!valueToWrite) {
    changeValue(null)
  }
}
