import { persistStore } from "redux-persist"

let persistor = null
export function createPersistor(store) {
  persistor = persistStore(store)
  return persistor
}

export function getPersistor() {
  return persistor
}
