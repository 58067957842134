import ActionTypes from "../constants/ActionTypes"

export function appInit(token) {
  return {
    type: ActionTypes.APP_INIT,
    payload: {
      token,
    },
  }
}

export function setStandardMode() {
  return {
    type: ActionTypes.SET_STANDARD_MODE,
  }
}
export function setTerminalMode() {
  return {
    type: ActionTypes.SET_TERMINAL_MODE,
  }
}

export function setSimulation(id) {
  return {
    type: ActionTypes.APP_SIMULATION_SET,
    payload: {
      id,
    },
  }
}

export function setSimulationBaseline(id) {
  return {
    type: ActionTypes.APP_SIMULATION_BASELINE_SET,
    payload: {
      id,
    },
  }
}

export function resetSimulation(id) {
  return {
    type: ActionTypes.APP_SIMULATION_RESET,
    payload: {
      id,
    },
  }
}
