import {
  clone,
  get,
  filter,
  first,
  forEach,
  includes,
  indexOf,
  last,
  pick,
  map,
  orderBy,
  reduce,
  reject,
  tail,
  transform,
  uniq,
  intersection,
} from "lodash"

import { initialState } from "./initialState"
import { setConflictsByOrderPosition, setOperationConflictsByStatus, getResourceConflicts } from "./helpers/conflicts"

import ActionTypes from "../../constants/ActionTypes"
import StatusTypes from "../../types/StatusTypes"
import TimelineTypes from "../../types/TimelineTypes"

import {
  adjustActivitySiblings,
  adjustOperationSiblings,
  adjustProductionOrder,
  cloneStore,
  getAffectedResources,
  getSelectedOperations,
  moveOperation,
  updateOperation,
  updateConflictsForResources,
  getPlanningObject,
  checkIfInFrozenZone,
  checkIfInSameProductionOrder,
  getSelectedCompactOperations,
  adjustSelectedOperations,
} from "./helpers/helpers"
import { Availability } from "../../utils/timeline/availability"
import { forcedTimezone } from "../../utils/timeline/forcedTimezone"
import { calculateGapsWithinRange } from "../../utils/timeline/calculateGapsWithinRange"
import errors from "../../utils/errors"
import {
  changeAttendantOperations,
  checkErrorIsEmpty,
  checkResourceChanged,
  getAttendantOperations,
  getAffectedResourcesForAllSelectedOperation,
  newTimelineStateWithNew,
  writeAttendantOperations,
  excludeAttendantOperations,
} from "./helpers/operationsHelpers"

/*
 * ATTENTION:
 * Be carefully to always use moment() or Date() when comparing stringed dates which each other because only this way code becomes timezone safe
 */

export default (state = initialState, action, holidays, globalsettings) => {
  const { payload } = action
  const { unlockedOperations } = state.settings
  const freeMoveProductionTimeline = get(globalsettings, "settings.freeMoveProductionTimeline", false)
  const moveResourcesAcrossGroups = get(globalsettings, "settings.moveResourcesAcrossGroups", false)
  const forceResourceChangeAttendantOperation = get(payload, "item.forceResourceChangeAttendantOperation", false)

  switch (action.type) {
    /**
     * One or more operations were moved.
     * For each of them:
     ** Check if the resource is still the same, and if not check if that was legal.
     ** Move the siblings of the operation.
     ** Move it´s productionOrder.
     ** Move it´s action.
     * Finally update the conflicts.
     */
    case ActionTypes.TIMELINE_OPERATION_MOVE: {
      const { start: newStart, itemId: operationId, timeline } = payload.item || state.movedItem
      let movedItems = payload.movedItems || [payload.item]
      if (!first(movedItems)) {
        movedItems = [state.movedItem]
      }
      const isResourceTimeline = timeline === TimelineTypes.Resource
      const newStartDate = new Date(newStart)
      const { startDate: oldStart } = state.operations[operationId]
      const oldStartDate = new Date(oldStart)
      const timeDifference = newStartDate - oldStartDate

      const nextState = newTimelineStateWithNew(payload, state)
      // Iterate over all selected items and handle their changes
      let selectedOperations = getSelectedCompactOperations(state)
      if (!forceResourceChangeAttendantOperation) {
        selectedOperations = excludeAttendantOperations(selectedOperations, state)
      }

      const [selectedOperationsSpan] = reduce(
        tail(selectedOperations),
        ([selectedOperationsKeyed, opPrev], opNext) => {
          const { id: operationIdPrev } = opPrev
          const { id: operationIdNext } = opNext
          const span =
            new Date(nextState.operations[operationIdNext].startDate) -
            new Date(nextState.operations[operationIdPrev].endDate)
          // eslint-disable-next-line no-param-reassign
          selectedOperationsKeyed[`${operationIdPrev}-${operationIdNext}`] = span
          return [selectedOperationsKeyed, opNext]
        },
        [{}, first(selectedOperations)]
      )

      const movedForward = timeDifference > 0
      let firstOperationId = null
      if (movedForward) {
        firstOperationId = get(first(selectedOperations), "id")
      } else {
        firstOperationId = get(last(selectedOperations), "id")
      }

      try {
        /** start of section */
        /** we move first operation */
        /** we adjust siblings (moving of all further operations) */
        /**
         * resource is resourceId in this case
         * @todo rename resource to resourceID
         */
        const operation = clone(nextState.operations[firstOperationId])
        const { activity, resource } = getPlanningObject(state, operation)

        const previousAvailabilityOfResource = {
          capacityPlans: map(resource.availabilityPlans, ({ availabilityPlan, ...others }) => ({
            ...state.capacityPlans[availabilityPlan],
            ...others,
          })),
          gaps: resource.resourceGaps,
        }

        let [affectedResources] = checkResourceChanged(
          state,
          nextState,
          selectedOperations,
          isResourceTimeline,
          moveResourcesAcrossGroups,
          movedItems,
          forceResourceChangeAttendantOperation
        )

        const freeMove = unlockedOperations.includes(firstOperationId) && freeMoveProductionTimeline
        if (!freeMove) {
          const { productionOrderId } = nextState.operations[firstOperationId]
          const attendantOperations = getAttendantOperations(nextState, productionOrderId) // attOperation
          const { movedOperation } = moveOperation(
            nextState,
            firstOperationId,
            timeDifference,
            0,
            previousAvailabilityOfResource
          )
          const changedAttendantOperations = changeAttendantOperations(nextState, movedOperation, attendantOperations) // attOperation
          writeAttendantOperations(nextState.operations, changedAttendantOperations)
          // eslint-disable-next-line no-param-reassign
          nextState.operations[firstOperationId] = movedOperation
          adjustOperationSiblings(nextState, operation, movedOperation, false, attendantOperations)
          /**
           * Selected operation has not to be in the same production order
           * */
          adjustSelectedOperations(nextState, selectedOperations, selectedOperationsSpan, movedForward)
        } else {
          forEach(selectedOperations, ({ id }) => {
            const { movedOperation } = moveOperation(nextState, id, timeDifference, 0, previousAvailabilityOfResource)
            nextState.operations[id] = movedOperation
            adjustOperationSiblings(nextState, operation, nextState.operations[firstOperationId], true)
          })
        }
        forEach(selectedOperations, op => {
          const { productionOrderId: currentProductionId } = nextState.operations[op.id]
          adjustProductionOrder(nextState, currentProductionId)
          const { activityId: currentActivityId } = nextState.productionOrders[currentProductionId]
          const activityAfterMove = nextState.activities[currentActivityId]
          adjustActivitySiblings(nextState, activity, activityAfterMove)
        })

        const currentAffectedResources = getAffectedResourcesForAllSelectedOperation({
          selectedOperations,
          state,
          nextState,
        })
        affectedResources = uniq([...affectedResources, ...currentAffectedResources])

        // we take only endDates, because is for selected operation
        // we keep always the span between selected operations
        /** end of section */
        updateConflictsForResources(nextState, affectedResources)
        setOperationConflictsByStatus(nextState)
        setConflictsByOrderPosition(nextState)
        nextState.operationPreview = null
      } catch (err) {
        return checkErrorIsEmpty(err, state, payload.item || state.movedItem, ActionTypes.TIMELINE_OPERATION_MOVE)
      }
      return nextState
    }
    /**
     * Multiple operations were moved.
     * For each of them:
     * Multiple
     ** Only from one resource
     ** Move the siblings of the operation.
     ** Move it´s productionOrder.
     ** Move it´s action.
     * Finally update the conflicts.
     */
    case ActionTypes.TIMELINE_OPERATIONS_FREE_MOVE: {
      /**
       * 1. check if operation from one production oder
       * 1. move every operation with moveOperation(tempState, id, timeDifference, 0, previousAvailabilityOfResource)
       * 2. update production oder
       *
       *
       * */
      const orderedSelectedOperations = getSelectedCompactOperations(state)
      if (!orderedSelectedOperations.length) {
        return state
      }
      // validation: productionOrder must be the same by all operations and not in frozen zone
      const firstOperation = state.operations[orderedSelectedOperations[0].id]
      const { activity, productionOrder: firstProductionOrder } = getPlanningObject(state, firstOperation)
      checkIfInSameProductionOrder(state, orderedSelectedOperations)
      checkIfInFrozenZone(state, orderedSelectedOperations)
      // moving
      const newStartDate = new Date(payload.startDate)
      const { id: operationId } = orderedSelectedOperations[0]
      const { startDate: oldStart } = state.operations[operationId]
      const oldStartDate = new Date(oldStart)
      const timeDifference = newStartDate - oldStartDate
      let affectedResources = []

      // Iterate over all selected items and handle their changes
      // Selected operations are free movable
      const tempState = cloneStore(state)
      let timeDifferenceAncestorEnd = timeDifference
      forEach(orderedSelectedOperations, ({ id }) => {
        const operation = clone(tempState.operations[id])
        const { endDate: oldEndDate } = operation
        const { resource } = getPlanningObject(state, operation) //  resource

        const previousAvailabilityOfResource = {
          capacityPlans: map(resource.availabilityPlans, ({ availabilityPlan, ...others }) => ({
            ...state.capacityPlans[availabilityPlan],
            ...others,
          })),
          gaps: resource.resourceGaps,
        }

        // eslint-disable-next-line no-param-reassign
        tempState.operations[id] = operation
        const { movedOperation } = moveOperation(
          tempState,
          id,
          timeDifferenceAncestorEnd,
          0,
          previousAvailabilityOfResource,
          false,
          true
        )
        // eslint-disable-next-line no-param-reassign

        tempState.operations[id] = movedOperation
        adjustOperationSiblings(tempState, operation, tempState.operations[id], true)
        timeDifferenceAncestorEnd = new Date(movedOperation.endDate) - new Date(oldEndDate)
      })

      adjustProductionOrder(tempState, firstProductionOrder.productionOrderId)
      const activityAfterMove = tempState.activities[firstProductionOrder.activityId]
      adjustActivitySiblings(tempState, activity, activityAfterMove)

      const currentAffectedResources = getAffectedResources(firstProductionOrder.projectId, state, tempState)
      affectedResources = uniq([...affectedResources, ...currentAffectedResources])

      updateConflictsForResources(tempState, affectedResources)
      setOperationConflictsByStatus(tempState)
      setConflictsByOrderPosition(tempState)
      tempState.operationPreview = null
      return tempState
    }
    /**
     * Move of prarallel operation between resources
     *  - operation can be move between resources from the same group
     *  - the resource are already filtered in the component.
     *  - only one operation can be moved
     *  - if confilct, gap, other operation we jump back
     * We don't:
     * - adjust sibling
     * - adjust production orders
     * - adjustActivitySiblings
     * - adjust conflict - see on the top
     * - resize
     * - jump left or right
     * */
    case ActionTypes.TIMELINE_PARALLEL_OPERATION_MOVE: {
      const { itemId: id, timeline, group: newResourceId, mainOperationId } = payload.item || state.movedItem
      const affectedResources = []
      const tempState = newTimelineStateWithNew(payload, state)

      try {
        const operation = clone(tempState.operations[id])
        const mainOperation = tempState.operations[mainOperationId]
        const { startDate: mainOperationStart, endDate: mainOperationEnd } = mainOperation
        const { resource: resourceId } = operation // old resource
        const newResource = state.resources[newResourceId] // new resource
        const mainOperationStartDate = new Date(mainOperationStart)
        const mainOperationEndDate = new Date(mainOperationEnd)
        const { productionOrderId } = tempState.operations[id]
        const productionOrder = state.productionOrders[productionOrderId]
        const activity = state.activities[productionOrder.activityId]
        /**
         * Check if operation was moved to another resource.
         * Only allow moving operations between resources of the same resourceGroup.
         * Reject the drop, if it´s dropped somewhere else.
         */

        if (timeline === TimelineTypes.Resource && resourceId !== newResourceId) {
          const doResourcesHaveSameGroups = !intersection(
            state.resources[resourceId].resourceGroups,
            state.resources[newResourceId].resourceGroups
          ).length
          if (!moveResourcesAcrossGroups && doResourcesHaveSameGroups) {
            throw new errors.UserError("pages.timeline.cantChangeResource.badGroup")
          }
          if (!includes(state.resources[newResourceId].resourceGroups, operation.resourceGroup)) {
            const [newResourceGroup] = state.resources[newResourceId].resourceGroups
            operation.resourceGroup = newResourceGroup
          }
          affectedResources.push(resourceId)
          affectedResources.push(newResourceId)
        }
        /**
         * check for frozen time:
         * if exist -> jump back
         * */
        const { availabilityPlans, resourceGaps } = newResource
        /**
         * check for gap:
         * if exist -> jump back
         * only if we don't have the free time for the operation
         * */
        const capacityPlans = map(availabilityPlans, ({ availabilityPlan, ...others }) => ({
          ...state.capacityPlans[availabilityPlan],
          ...others,
        }))
        const configuration = {
          capacityPlans,
          gaps: resourceGaps,
          holidays: state.holidays,
        }
        const gapStream = new Availability(configuration, mainOperationStartDate, forcedTimezone)
        const gap = calculateGapsWithinRange(gapStream, mainOperationStartDate, mainOperationEndDate) // in sec.
        const duration = (mainOperationEndDate - mainOperationStartDate) / 1000
        if (duration <= gap) {
          throw new errors.UserError("pages.timeline.cantChangeResource.gap")
        }
        /**
         * check for operation on the target resource:
         * if exist -> jump back
         * */
        const operationsOnResource = filter(
          tempState.operations,
          op =>
            op.operationId !== operation.operationId &&
            op.resource === newResourceId &&
            new Date(op.startDate) < new Date(operation.endDate) &&
            new Date(op.endDate) > new Date(operation.startDate)
        )
        const operationWithConflict = getResourceConflicts(
          "operationId",
          [...operationsOnResource, operation],
          newResource
        )

        if (operationWithConflict.length) {
          throw new errors.UserError("pages.timeline.cantChangeResource.busy")
        }

        tempState.operations[id] = updateOperation(
          tempState,
          { ...operation, resource: newResourceId },
          mainOperationStart,
          mainOperationEnd
        )

        adjustProductionOrder(tempState, productionOrderId)
        const activityAfterMove = tempState.activities[productionOrder.activityId]
        adjustActivitySiblings(tempState, activity, activityAfterMove)
        updateConflictsForResources(tempState, affectedResources)
        setOperationConflictsByStatus(tempState)
        setConflictsByOrderPosition(tempState)
      } catch (err) {
        return checkErrorIsEmpty(err, state, payload.item, ActionTypes.TIMELINE_PARALLEL_OPERATION_MOVE)
      }
      return tempState
    }

    case ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW: {
      const { item } = payload
      const { itemId: id, start } = item
      const selectedOperation = state.operations[id]
      const timeDifference = new Date(start) - new Date(selectedOperation.startDate)

      // we have hier only one opertaion that can be moved/previewed
      const selectedResource = state.resources[selectedOperation.resource]
      const previousAvailabilityOfResource = {
        capacityPlans: map(selectedResource.availabilityPlans, ({ availabilityPlan, ...others }) => ({
          ...state.capacityPlans[availabilityPlan],
          ...others,
        })),
        gaps: selectedResource.resourceGaps,
      }

      const { movedOperation: resultedOperation } = moveOperation(
        state,
        id,
        timeDifference,
        0,
        previousAvailabilityOfResource
      )

      return { ...state, operationPreview: resultedOperation }
    }

    case ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW_CLEAR: {
      return { ...state, operationPreview: null }
    }

    /**
     * One operation was resized.
     * For each of them:
     * Move the siblings of the operation.
     * Move it´s productionOrder.
     * Move it´s action.
     * Finally update the conflicts.
     *
     * @todo Why is the selection omitted here?
     */
    case ActionTypes.TIMELINE_OPERATION_RESIZE: {
      const { itemId: operationId, start: newStart, end: newEnd } = payload.item || state.movedItem
      if (new Date(newStart) > new Date(newEnd)) {
        return state
      }
      const nextState = newTimelineStateWithNew(payload, state)

      const operation = clone(nextState.operations[operationId])
      const { projectId, productionOrderId } = operation
      const productionOrder = nextState.productionOrders[productionOrderId]
      const activity = nextState.activities[productionOrder.activityId]

      const attendantOperations = getAttendantOperations(nextState, productionOrderId)
      const updatedOperation = updateOperation(nextState, operation, newStart, newEnd)
      const changedAttendantOperations = changeAttendantOperations(nextState, updatedOperation, attendantOperations)
      writeAttendantOperations(nextState.operations, changedAttendantOperations)
      nextState.operations[operationId] = updatedOperation
      try {
        // Calculate related entities & their conflicts
        setOperationConflictsByStatus(nextState)
        adjustOperationSiblings(
          nextState,
          operation,
          updatedOperation,
          !!unlockedOperations.includes(operationId),
          attendantOperations
        )

        adjustProductionOrder(nextState, productionOrderId)
        const activityAfterMove = nextState.activities[productionOrder.activityId]
        adjustActivitySiblings(nextState, activity, activityAfterMove)
        const affectedResources = getAffectedResources(projectId, state, nextState)
        updateConflictsForResources(nextState, affectedResources)

        setConflictsByOrderPosition(nextState)
      } catch (err) {
        return checkErrorIsEmpty(err, state, payload.item, ActionTypes.TIMELINE_OPERATION_RESIZE)
      }
      return nextState
    }

    // Close gaps function
    case ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_LEFT: {
      const nextState = reduce(
        getSelectedOperations(state),
        (tempState, operation) => {
          const { operationId } = operation
          const operationsInThisResource = orderBy(
            filter(reject(tempState.operations, { status: StatusTypes.done }), { resource: operation.resource }),
            [op => new Date(op.startDate)],
            ["desc"]
          )
          const thisOperationStartDate = new Date(operation.startDate)
          const itemsLeftOfThisOperation = filter(
            operationsInThisResource,
            op => new Date(op.startDate) < thisOperationStartDate
          )

          transform(
            map(itemsLeftOfThisOperation, "operationId"),
            (prevOperationIds, currentOperationId) => {
              const currentOperation = tempState.operations[currentOperationId]
              const prevOperationId = last(prevOperationIds)
              const { productionOrderId } = currentOperation
              let targetDate = Date.parse(tempState.operations[prevOperationId].startDate)

              const productionOrder = tempState.productionOrders[productionOrderId]
              const operationsInOrder = pick(tempState.operations, productionOrder.operations)
              const resourcesInProductionOrders = uniq(map(operationsInOrder, "resource"))

              if (resourcesInProductionOrders.length > 1) {
                return false // abort gap closing when we stumble over an productionOrder using different resources
              }

              const activity = tempState.activities[productionOrder.activityId]
              const indexOfProductionOrder = indexOf(activity.productionOrders, productionOrderId)
              if (indexOfProductionOrder > 0) {
                const prevProductionOrderId = activity.productionOrders[indexOfProductionOrder - 1]
                const prevProductionOrder = tempState.productionOrders[prevProductionOrderId]
                // avoid moving a productionOrder before its predecessor
                targetDate = Math.max(Date.parse(prevProductionOrder.startDate), targetDate)
              }

              const moveDifference = -(Date.parse(currentOperation.endDate) - targetDate)
              const { movedOperation } = moveOperation(
                tempState,
                currentOperation.operationId,
                moveDifference,
                0,
                {},
                moveDifference > 0
              )
              // eslint-disable-next-line no-param-reassign
              tempState.operations[currentOperationId] = movedOperation

              adjustOperationSiblings(
                tempState,
                currentOperation,
                tempState.operations[currentOperationId],
                !!includes(unlockedOperations, currentOperationId)
              )

              adjustProductionOrder(tempState, currentOperation.productionOrderId)
              prevOperationIds.push(currentOperationId)
              return prevOperationIds
            },
            [operationId]
          )
          updateConflictsForResources(tempState, [operation.resource])
          return tempState
        },
        cloneStore(state)
      )
      setConflictsByOrderPosition(nextState)
      return nextState
    }

    case ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_RIGHT: {
      const nextState = reduce(
        getSelectedOperations(state),
        (tempState, operation) => {
          const { operationId } = operation
          const operationsInThisResource = orderBy(
            filter(reject(tempState.operations, { status: StatusTypes.done }), { resource: operation.resource }),
            op => new Date(op.startDate)
          )
          const thisOperationStartDate = new Date(operation.startDate)
          const itemsRightOfThisOperation = filter(
            operationsInThisResource,
            op => new Date(op.startDate) > thisOperationStartDate
          )

          transform(
            map(itemsRightOfThisOperation, "operationId"),
            (prevOperationIds, currentOperationId) => {
              const currentOperation = tempState.operations[currentOperationId]
              const prevOperationId = last(prevOperationIds)
              const { productionOrderId } = currentOperation
              let targetDate = Date.parse(tempState.operations[prevOperationId].endDate)

              const productionOrder = tempState.productionOrders[productionOrderId]
              const operationsInOrder = pick(tempState.operations, productionOrder.operations)
              const resourcesInProductionOrders = uniq(map(operationsInOrder, "resource"))

              if (resourcesInProductionOrders.length > 1) {
                return false // abort gap closing when we stumble over an productionOrder using different resources
              }

              const activity = tempState.activities[productionOrder.activityId]
              const indexOfProductionOrder = indexOf(activity.productionOrders, productionOrderId)

              if (indexOfProductionOrder > 0) {
                const prevProductionOrderId = activity.productionOrders[indexOfProductionOrder - 1]
                const prevProductionOrder = tempState.productionOrders[prevProductionOrderId]
                // avoid moving a productionOrder before its predecessor
                targetDate = Math.max(Date.parse(prevProductionOrder.endDate), targetDate)
              }

              const moveDifference = -(Date.parse(currentOperation.startDate) - targetDate)

              const { movedOperation } = moveOperation(
                tempState,
                currentOperation.operationId,
                moveDifference,
                0,
                {},
                moveDifference < 0
              )
              // eslint-disable-next-line no-param-reassign
              tempState.operations[currentOperationId] = movedOperation

              adjustOperationSiblings(
                tempState,
                currentOperation,
                tempState.operations[currentOperationId],
                !!includes(unlockedOperations, currentOperationId)
              )

              adjustProductionOrder(tempState, currentOperation.productionOrderId)
              prevOperationIds.push(currentOperationId)
              return prevOperationIds
            },
            [operationId]
          )
          updateConflictsForResources(tempState, [operation.resource])
          return tempState
        },
        cloneStore(state)
      )
      setConflictsByOrderPosition(nextState)
      return nextState
    }

    default:
      return state
  }
}
