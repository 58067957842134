import { schema } from "normalizr"
import article from "../../basedata/articles/item"
import userSchema from "../../users/item"
import customer from "../../basedata/customers/item"
import processTemplate from "../../basedata/templates/item"
import project from "../projects/item"
import resource from "../../basedata/resources/item"
import resourceGroup from "../../basedata/resourceGroups/item"
import technology from "../../basedata/technologies/item"
import valueAdd from "../../valueAdds/item"

export default new schema.Entity(
  "operations",
  {
    article,
    createdBy: userSchema,
    workerId: userSchema,
    customer,
    processTemplate,
    project,
    resource,
    resourceGroup,
    technology,
    valueAdd,
  },
  { idAttribute: "operationId" }
)
