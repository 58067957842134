import { put as putEffect, takeLatest } from "redux-saga/effects"
import { get as lodashGet, toLower } from "lodash"
import ActionTypes from "../../../constants/ActionTypes"
import { APIRequestSequence } from "../../api"
import { API_SERVER_PLANNING_PROCESS } from "../../../constants/api"
import { put } from "../../../utils/http"
import productionOrderDetails from "../../../schemas/planningObjects/productionOrderDetails/index"
import { fetchPlanningObjectsProductionOrderDetails } from "../../../actions/planningObjects/productionOrders"

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/productionOrders`,
    schema: productionOrderDetails,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.productionOrderId}`,
    prepareResponse: productionOrder => [productionOrder],
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/productionOrders`,
    schema: productionOrderDetails,
    method: put,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [{ ...response, productionOrder: { ownedBy: null, ...response.productionOrder } }],
    prepareUrl: (endPoint, { data }) => `${endPoint}/${data.productionOrder.productionOrderId}`,
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_REVOKE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/productionOrders`,
    schema: productionOrderDetails,
    method: put,
    prepareRequest: payload => payload.productionOrder,
    prepareResponse: project => [project],
    prepareUrl: (endPoint, { productionOrderId }) => `${endPoint}/${productionOrderId}/planoff`,
  }),
  takeLatest(
    ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE_FAILURE,
    function* refreshProductionOrderOnFailure(action) {
      const {
        payload: { message, originalAction }, // http 404 not exist
      } = action
      const productionOrderId = lodashGet(originalAction, "payload.data.productionOrder.productionOrderId")
      if (toLower(message) === "objectnotexists" && productionOrderId) {
        yield putEffect(fetchPlanningObjectsProductionOrderDetails(productionOrderId))
      }
    }
  ),
]
