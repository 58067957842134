const getValue = key => {
  try {
    const data = localStorage.getItem(key)
    if (data) {
      return data
    }
    return false
  } catch (e) {
    return false
  }
}

const setValue = (key, data) => {
  try {
    localStorage.setItem(key, data)
    return data
  } catch (e) {
    return false
  }
}

const getObject = key => {
  try {
    const data = localStorage.getItem(key)
    if (data) {
      const result = JSON.parse(data)
      return result
    }
    return false
  } catch (e) {
    return false
  }
}

const setObject = (key, data) => {
  try {
    const newData = { ...getObject(key), ...data }
    localStorage.setItem(key, JSON.stringify(newData))
    return data
  } catch (e) {
    return false
  }
}

const setArray = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
    return data
  } catch (e) {
    return false
  }
}

export { setArray, getValue, setValue, getObject, setObject }
