import { forEach, isArray, keyBy, merge, mergeWith, omit, pick } from "lodash"
import { combineReducers } from "redux"

import ActionTypes from "../constants/ActionTypes"
import additionalProperties from "./entities/additionalProperties"
import holidays from "./entities/holidays"
import resourcesReducer from "./entities/resource"
import technologiesReducer from "./entities/technology"
import templatesReducer from "./entities/templatesReducer"
import usersDetailsReducer from "./entities/usersDetailsReducer"
import orderPositions from "./entities/orderPositions"
import productionOrders from "./entities/productionOrders"

export const initialState = {
  activities: {},
  activityDetails: {},
  additionalProperties: {},
  amountUnits: {},
  articles: {},
  articleDetails: {},
  articleGroups: {},
  dashboardCards: {},
  capacityPlans: {},
  customers: {},
  customerDetails: {},
  holidays: {},
  imports: {},
  licenceModules: {},
  licenceModuleDetails: {},
  operations: {},
  orderPositions: {},
  orderPositionDetails: {},
  processTemplateItems: {},
  projects: {},
  projectDetails: {},
  productionOrders: {},
  productionOrderDetails: {},
  roles: {},
  resources: {},
  resourceGaps: {},
  resourceGroupDetails: {},
  status: {},
  statusList: {},
  technologies: {},
  technologyItems: {},
  templates: {},
  terminations: {},
  valueAdds: {},
  users: {}, // without createdBy property and not from /users API call
  userList: {}, // as a workaround because entity users already exists
  userDetails: {},
  modules: {},
  licences: {},
  modulesUserManagement: {},
  templatesReducer: {},
}

const updateItems = (state, items, idField) => {
  if (items.length === 0) {
    return state
  }

  const nextState = { ...state }

  forEach(items, item => {
    const currentId = item[idField]
    if (state[currentId]) {
      nextState[currentId] = {
        ...state[currentId],
        endDate: item.endDate,
        startDate: item.startDate,
      }
    }
  })

  return nextState
}

const updateResources = (state, items) => {
  if (items.length === 0) {
    return state
  }

  const nextState = { ...state }

  forEach(items, item => {
    const currentId = item.operationId
    if (state[currentId]) {
      nextState[currentId] = {
        ...state[currentId],
        resource: item.resource,
      }
    }
  })

  return nextState
}

export function complementEntities(state, action) {
  const newState = {
    ...state,
  }

  const doNotMergeArraysCustomizer = (objValue, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue
    }
    return undefined
  }

  forEach(action.payload.entities, (entities, entityKey) => {
    newState[entityKey] = mergeWith({ ...newState[entityKey] }, entities, doNotMergeArraysCustomizer)
  })

  return newState
}

const entityReducer = combineReducers({
  additionalProperties,
  holidays,
  resources: resourcesReducer,
  technologies: technologiesReducer,
  userDetails: usersDetailsReducer,
  templates: templatesReducer,
  orderPositions,
  productionOrders,
})

const generalReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.NORMALIZE_MIDDLEWARE_SAVE:
      return complementEntities(state, action)

    case ActionTypes.ENTITIES_UPDATE_PERSISTS:
      return {
        ...state,
        activities: updateItems(state.activities, payload.activities, "activityId"),
        operations: updateResources(
          updateItems(state.operations, payload.operations, "operationId"),
          payload.operations
        ),
        orderPositions: updateItems(state.orderPositions, payload.orderPositions, "orderPositionId"),
        productionOrders: updateItems(state.productionOrders, payload.productionOrders, "productionOrderId"),
        projects: updateItems(state.projects, payload.projects, "projectId"),
      }

    case ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS: {
      const {
        activities,
        operations,
        orderPositions: orderPositionsDeleted,
        productionOrders: productionOrdersDeleted,
        projects,
      } = payload
      return {
        ...state,
        activities: omit(state.activities, activities),
        operations: omit(state.operations, operations),
        orderPositions: omit(state.orderPositions, orderPositionsDeleted),
        productionOrders: omit(state.productionOrders, productionOrdersDeleted),
        projects: omit(state.projects, projects),
      }
    }

    case ActionTypes.PLANNING_OBJECTS_OPERATION_DETAILS_UPDATE_SUCCESS:
    case ActionTypes.PLANNING_OBJECTS_OPERATION_DETAILS_LOAD_SUCCESS: {
      const {
        article,
        customer,
        operation,
        processTemplate,
        project,
        resource,
        resourceGroup,
        technologie,
        termination,
        users,
        valueAdd,
      } = payload
      return {
        ...state,
        articles: merge(
          {
            ...state.articles,
          },
          article && {
            [article.articleId]: article,
          }
        ),
        customers: merge(
          {
            ...state.customers,
          },
          customer && {
            [customer.customerId]: customer,
          }
        ),
        operations: merge(
          {
            ...state.operations,
          },
          operation && {
            [operation.operationId]: operation,
          }
        ),
        processTemplates: merge(
          {
            ...state.processTemplates,
          },
          processTemplate && {
            [processTemplate.templateId]: processTemplate,
          }
        ),
        projects: merge(
          {
            ...state.projects,
          },
          project && {
            [project.projectId]: project,
          }
        ),
        resources: merge(
          {
            ...state.resources,
          },
          resource && {
            [resource.resourceId]: resource,
          }
        ),
        resourceGroups: merge(
          {
            ...state.resourceGroups,
          },
          resourceGroup && {
            [resourceGroup.resourceGroupId]: resourceGroup,
          }
        ),
        technologies: merge(
          {
            ...state.technologies,
          },
          technologie && {
            [technologie.technologyId]: technologie,
          }
        ),
        terminations: merge(
          {
            ...state.terminations,
          },
          termination && {
            [termination.terminationId]: termination,
          }
        ),
        users: merge(
          {
            ...state.users,
          },
          keyBy(users, "userId")
        ),
        valueAdds: merge(
          {
            ...state.valueAdds,
          },
          valueAdd && {
            [valueAdd.valueAddId]: valueAdd,
          }
        ),
      }
    }

    default:
      return state
  }
}

function rootEntitiesReducer(state, action) {
  const intermediateState = generalReducer(state, action)
  const partState = entityReducer(
    pick(intermediateState, [
      "additionalProperties",
      "holidays",
      "resourceGaps",
      "resources",
      "technologies",
      "users",
      "userDetails",
      "templates",
      "orderPositions",
      "productionOrders",
    ]),
    action
  )
  return { ...intermediateState, ...partState }
}
export default rootEntitiesReducer
