import { useCallback } from "react"
import { getFormattedDate } from "./utils"

export const useOnAccept = (onChange, format) =>
  useCallback(
    mom => {
      onChange(getFormattedDate(mom, format))
    },
    [onChange]
  )
