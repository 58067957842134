import React, { Fragment } from "react"
import PropTypes from "prop-types"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import LabelValueText from "../LabelValueTextField/LabelValueText"

const helperText = (excludeTextHelper, forceDisplayError, touched, error, caption) => {
  if (excludeTextHelper) return null
  return (forceDisplayError || touched) && !!error ? (
    <FormHelperText>{error}</FormHelperText>
  ) : (
    <FormHelperText>{caption}</FormHelperText>
  )
}

const Text = ({
  changedWarning,
  className,
  endAdornment,
  excludeTextHelper,
  disabled,
  disableUnderline,
  forceDisplayError,
  fullWidth,
  input,
  // eslint-disable-next-line no-unused-vars,react/prop-types
  input: { value, onChange },
  inputProps,
  inputRef,
  inputLabelProps,
  meta: { pristine, touched, error },
  noWrap,
  label,
  required,
  reset,
  readOnly,
  type,
  autoComplete,
  caption,
  showAsText,
  defaultValue,
}) => (
  <Fragment>
    {showAsText ? (
      <LabelValueText className={className} label={label} value={value} noWrap={noWrap} />
    ) : (
      <FormControl
        variant="standard"
        className={className}
        error={(forceDisplayError || touched) && !!error}
        fullWidth={fullWidth}
        data-test-id={input.name}
      >
        {label && (
          <InputLabel
            style={{ whiteSpace: "noWrap" }}
            htmlFor={input.name}
            required={required}
            {...inputLabelProps}
            disabled={disabled}
          >
            <span style={inputLabelProps.style ? inputLabelProps.style : { whiteSpace: "noWrap" }}>{label}</span>
          </InputLabel>
        )}

        <Input
          id={input.name}
          endAdornment={
            !pristine ? (
              <Fragment>
                {changedWarning}
                {endAdornment}
              </Fragment>
            ) : (
              endAdornment
            )
          }
          disabled={disabled}
          disableUnderline={disableUnderline}
          fullWidth={fullWidth}
          readOnly={readOnly}
          autoComplete={autoComplete}
          {...inputProps}
          {...input}
          type={type}
          value={reset ? "" : value?.toString() || defaultValue}
          inputProps={inputProps}
          inputRef={inputRef}
        />

        {helperText(excludeTextHelper, forceDisplayError, touched, error, caption)}
      </FormControl>
    )}
  </Fragment>
)

Text.propTypes = {
  autoComplete: PropTypes.string,
  changedWarning: PropTypes.any,
  excludeTextHelper: PropTypes.bool,
  endAdornment: PropTypes.any,
  defaultValue: PropTypes.any,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    pristine: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.bool]),
  }),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noWrap: PropTypes.bool,
  caption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
  reset: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  forceDisplayError: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  inputRef: PropTypes.object,
  inputLabelProps: PropTypes.object,
  showAsText: PropTypes.bool,
}

Text.defaultProps = {
  autoComplete: undefined,
  caption: "",
  changedWarning: null,
  className: undefined,
  defaultValue: "",
  disabled: false,
  disableUnderline: false,
  endAdornment: null,
  excludeTextHelper: false,
  forceDisplayError: false,
  fullWidth: false,
  inputLabelProps: {},
  inputProps: {},
  inputRef: null,
  label: null,
  meta: {},
  noWrap: false,
  readOnly: false,
  required: false,
  reset: false,
  showAsText: false,
  type: "text",
}

export default Text
