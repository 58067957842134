import { schema } from "normalizr"
import { baseDataTabs, planningObjectsTabs } from "../constants/modules"

const articleSchema = new schema.Entity("articles", {}, { idAttribute: "articleId" })

const valueAddSchema = new schema.Entity("valueAdds", {}, { idAttribute: "valueAddId" })

const resourceGroupSchema = new schema.Entity("resourceGroups", {}, { idAttribute: "resourceGroupId" })

const resourceSchema = new schema.Entity(
  baseDataTabs.resources,
  {
    resourceGroup: resourceGroupSchema,
  },
  { idAttribute: "resourceId" }
)

const operationSchema = new schema.Entity(
  planningObjectsTabs.operations,
  {
    resource: resourceSchema,
    valueAdd: valueAddSchema,
  },
  { idAttribute: "operationId" }
)

const productionOrderSchema = new schema.Entity(
  planningObjectsTabs.productionOrders,
  {
    operations: [operationSchema],
    article: articleSchema,
  },
  { idAttribute: "productionOrderId" }
)

const activitySchema = new schema.Entity(
  planningObjectsTabs.activities,
  {
    productionOrders: [productionOrderSchema],
  },
  { idAttribute: "activityId" }
)

const locationSchema = new schema.Entity("location", { idAttribute: "locationId" })

export const orderPositionSchema = new schema.Entity(
  planningObjectsTabs.orderPositions,
  {
    activities: [activitySchema],
    location: locationSchema,
  },
  { idAttribute: "orderPositionId" }
)

export const projectSchema = new schema.Array({
  orderPositions: [orderPositionSchema],
})

export const orderPositionListSchema = new schema.Array({
  article: articleSchema,
  project: new schema.Entity("projects", {}, { idAttribute: "projectId" }),
})
