import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import ActionTypes from "../../../constants/ActionTypes"

export const initialState = {
  start: "",
  end: "",
  timeUnit: "hours",
  filterResources: [],
  selectedUserFilterId: null,
}

const reducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.UI_AVAILABLE_HOURS_PER_DAY_FILTER_CLEAR: {
      return {
        ...initialState,
      }
    }
    case ActionTypes.UI_AVAILABLE_HOURS_PER_DAY_FILTER_SET: {
      return {
        ...state,
        ...payload,
      }
    }
    case ActionTypes.UI_AVAILABLE_HOURS_PER_DAY_USER_FILTER_SET: {
      return {
        ...state,
        selectedUserFilterId: payload || null,
      }
    }
    default:
      return state
  }
}

export default persistReducer(
  {
    key: "ui_availableHoursPerDay",
    storage,
  },
  reducer
)
