import React from "react"
import PropTypes from "prop-types"

import Body from "./Body"

const NotAvailable = ({ message }) => <Body>{message}</Body>

NotAvailable.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

export default NotAvailable
