import React from "react"
import PropTypes from "prop-types"
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers"

import { useIntl } from "react-intl"
import { noop } from "lodash"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, useTheme } from "@mui/material"

import { useChangeValue } from "./useChangeValue"
import { useAcceptValueOnBlur } from "./useAcceptValueOnBlur"
import { useHelperText } from "./useHelperText"
import { useOnAccept } from "./useOnAccept"

const useStyle = makeStyles({
  popper: {
    zIndex: "1404",
  },
})

const CustomPicker = ({
  keyboardPickerEnabled,
  onBlur,
  onChange,
  value,
  errorProps: { error, forceDisplayFormError, showEmptyHelper },
  fullWidth,
  required,
  name,
  ...props
}) => {
  const theme = useTheme()
  const classes = useStyle()
  const { formatMessage } = useIntl()
  const [valueToAccept, changeValue] = useChangeValue(value)
  const [helperText, setHelperText] = useHelperText(error, forceDisplayFormError, showEmptyHelper, formatMessage)

  const Picker = keyboardPickerEnabled ? DatePicker : MobileDatePicker

  const acceptValuesOnBlur = useAcceptValueOnBlur(valueToAccept, changeValue, onChange, onBlur, "YYYY-MM-DD")
  return (
    <Picker
      closeOnSelect
      showToolbar={false}
      inputFormat="DD.MM.YYYY" // keyboard edit
      onChange={changeValue}
      onAccept={useOnAccept(onChange, "YYYY-MM-DD")}
      onError={setHelperText}
      value={valueToAccept}
      PopperProps={{ className: classes.popper }}
      desktopModeMediaQuery={`(min-width: ${theme.breakpoints.values.md}px)`}
      renderInput={params => (
        <TextField
          {...params}
          error={!!helperText && helperText !== " "}
          name={name}
          data-test-id={name}
          onBlur={acceptValuesOnBlur}
          required={required}
          fullWidth={fullWidth}
          helperText={helperText}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            // eslint-disable-next-line no-useless-computed-key
            ["data-test-id"]: Picker.render.name,
            type: "text",
            placeholder: formatMessage({ id: "common.dateFormat" }),
          }}
        />
      )}
      {...props}
    />
  )
}

CustomPicker.propTypes = {
  errorProps: PropTypes.object,
  keyboardPickerEnabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
}

CustomPicker.defaultProps = {
  keyboardPickerEnabled: true,
  onBlur: noop,
  value: null,
  variant: "inline",
  errorProps: { error: null, forceDisplayFormError: false, showEmptyHelper: false },
  fullWidth: false,
  required: false,
  name: "datePickerField",
}

export default CustomPicker
