import { CSSProperties } from "react"
import { Message, User } from "../../components/UserName/UserName"
import ProgressTypes from "../../types/ProgressTypes"

export type exportType = (row: { value: string }) => { id: string }

export interface ProgressTypeDetail {
  title: ProgressTypes
  messageId: string
}

export const progressTypes: Record<string, ProgressTypeDetail> = {
  output: { title: ProgressTypes.OUTPUT, messageId: "term.output" },
  reject: { title: ProgressTypes.REJECT, messageId: "term.reject" },
  time: { title: ProgressTypes.TIME, messageId: "term.time" },
  setup: { title: ProgressTypes.SETUP, messageId: "term.setup" },
  trouble: { title: ProgressTypes.TROUBLE, messageId: "term.trouble" },
}

export interface Column {
  id: string
  translationId?: string
  accessor:
    | string
    | ((row: Record<string, unknown>) => boolean)
    | ((row: Record<string, unknown>) => number)
    | ((row: Record<string, unknown>) => string)
    | ((row: Record<string, unknown>) => JSX.Element)
    | ((row: { splitted: boolean; isSplit: boolean }) => { splitted: boolean; isSplit: boolean })
  Header: JSX.Element
  Cell?: (value: unknown, ...props: Record<string, unknown>[]) => JSX.Element | string
  filter?: (rows: Record<string, unknown>, id: number, filterValue: unknown) => unknown
  Filter?: ((props: unknown) => JSX.Element) | JSX.Element
  filterable?: boolean
  width?: number
  style?: CSSProperties
  summary?: boolean
  disableFilters?: boolean
  disableSortBy?: boolean
  showTooltip?: boolean
  debounce?: boolean
  export?:
    | unknown
    | (({ value }: { value: User }, formatMessage: (arg0: Message) => string) => string)
    | ((value: string) => { id: string })
    | exportType
}
