import AppMode from "../types/AppMode"

export const getUserData = state => {
  const { mode } = state.appConfig
  if (mode === AppMode.terminal) {
    return {
      token: state.user.terminalAuthentication.token,
      userId: state.user.terminalAuthentication.user.userId,
    }
  }
  return {
    token: state.user.authentication.token,
    userId: state.user.authentication.user.userId,
  }
}

export const getState = state => state

export const getTerminalId = state => state.user.terminal.terminal

export const getSimulationId = state => state.simulation.id
