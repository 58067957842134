// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
declare global {
  interface Window {
    planeusConfig: {
      api: string
    }
  }
}

const API_SERVER: string = window.planeusConfig ? window.planeusConfig.api : "http://192.168.1.118"

export const API_SERVER_DOC = API_SERVER
export const API_SERVER_USER = API_SERVER
export const API_SERVER_BASEDATA = API_SERVER
export const API_SERVER_PLANNING_PROCESS = API_SERVER
export const API_SERVER_DASHBOARD = API_SERVER
export const API_SERVER_NOTIFICATIONS = `${API_SERVER}/notification`
export const API_SERVER_NOTIFICATIONS_RECEIVE_ONLY = ""
