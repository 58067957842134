import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"

const styles = {
  bubble: {
    borderRadius: "50%",
    height: 19,
    width: 19,
  },
}

const Bullet = ({ classes, color }) => <div className={classes.bubble} style={{ backgroundColor: color }} />

Bullet.propTypes = {
  color: PropTypes.string.isRequired,
}

export default withStyles(styles)(Bullet)
