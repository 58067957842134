import moment from "moment"
import TimelineWindowSizes from "../../constants/TimelineWindowSizes"

export function middleDate(range) {
  const { start, end } = range
  const startTime = Date.parse(start)
  const difference = Date.parse(end) - startTime
  return new Date(startTime + difference / 2)
}

export function newMiddle(range, direction) {
  const middle = middleDate(range)
  const { start, end } = range
  const difference = Date.parse(end) - Date.parse(start)
  return new Date(middle.getTime() + difference * direction)
}

export function createUTCDate(...args) {
  return new Date(Date.UTC(...args))
}

export function todayRange(range) {
  const { start, end } = range
  const difference = (Date.parse(end) - Date.parse(start)) / 2
  const now = moment()
  return {
    end: now.clone().add(difference, "ms").format(),
    start: now.clone().subtract(difference, "ms").format(),
  }
}

export function snapWindowRange(nativeMiddleDate, windowSize) {
  const middle = moment(nativeMiddleDate)
  middle.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  const year = middle.year()
  const month = middle.month()
  const currentQuarter = middle.quarter() - 1
  const offset = middle.weekday()

  switch (windowSize) {
    case TimelineWindowSizes.QUARTER:
      return {
        start: createUTCDate(year, currentQuarter * 3, 1, 0, 0, 0),
        end: createUTCDate(year, (currentQuarter + 1) * 3, 0, 0, 0, 0),
      }

    case TimelineWindowSizes.MONTH:
      return {
        start: createUTCDate(year, month, 1, 0, 0, 0),
        end: createUTCDate(year, month + 1, 0, 0, 0, 0),
      }

    case TimelineWindowSizes.WEEK:
      return {
        start: middle.add(-offset, "days").toDate(),
        end: middle.add(7, "days").toDate(),
      }

    case TimelineWindowSizes.THREE_DAYS:
      return {
        start: middle.add(-1, "days").toDate(),
        end: middle.add(3, "days").toDate(),
      }

    case TimelineWindowSizes.DAY:
      return {
        start: middle.toDate(),
        end: middle.add(1, "days").toDate(),
      }

    default:
      // eslint-disable-next-line no-console
      console.error("[Timeline] Unknown window size", windowSize)
      return null
  }
}

export function calculateNextRange(currentRange, windowSize, direction) {
  const { start, end } = currentRange
  const difference = Date.parse(end) - Date.parse(start)
  return {
    start: new Date(Date.parse(start) + difference * direction),
    end: new Date(Date.parse(end) + difference * direction),
  }
}
