import ActionTypes from "../../../constants/ActionTypes"

import { APIRequestSequence } from "../../api"
import { API_SERVER_PLANNING_PROCESS } from "../../../constants/api"
import activityDetails from "../../../schemas/planningObjects/activityDetails"
import { put } from "../../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_LOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities`,
    schema: activityDetails,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.activityId}`,
    prepareResponse: response => [response],
  }),

  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_ACTIVITY_DETAILS_UPDATE,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/activities`,
    schema: activityDetails,
    method: put,
    prepareRequest: payload => payload.data,
    prepareResponse: response => [{ ...response, activity: { ownedBy: null, ...response.activity } }],
    prepareUrl: (endPoint, { data }) => `${endPoint}/${data.activity.activityId}`,
  }),
]
