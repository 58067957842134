import { createBrowserHistory } from "history"
import { split } from "lodash"

export const simulationPrefix = "simulation"

const getBaseName = () => {
  const {
    location: { pathname },
  } = window
  const localWords = split(pathname, "/")
  if (localWords[1] === simulationPrefix && Number(localWords[2]) > 0) {
    return `/${simulationPrefix}/${localWords[2]}`
  }
  return undefined
}
export const history = createBrowserHistory({
  basename: getBaseName(),
})
