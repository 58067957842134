import React from "react"

import { FormattedMessage } from "react-intl"
import { Column } from "pages/Shared/interfaces"
import { DateCell } from "components/DataGrid/cellRenderers"
import { DateHeader } from "components/DataGrid/filterHeaders"

export const handOverDate: Column = {
  id: "handOverDate",
  translationId: "term.handOverDate",
  accessor: "handOverDate",
  Cell: DateCell,
  Filter: DateHeader,
  Header: <FormattedMessage id="term.handOverDate" />,
}
