import keyMirror from "keymirror"
import accessProxy from "../utils/accessProxy"

const TimelineTypes = keyMirror({
  Planning: null,
  Production: null,
  Resource: null,
})

export default accessProxy(TimelineTypes)
