import { schema } from "normalizr"
import article from "../../basedata/articles/item"
import valueAdd from "../../valueAdds/item"
import articleAmountUnit from "../amountUnits/item"
import technology from "../../basedata/technologies/item"
import customer from "../../basedata/customers/item"
import processTemplate from "../../basedata/processTemplateItems/item"
import status from "../../status/item"
import project from "../projects/item"
import userSchema from "../../users/item"

export default new schema.Entity(
  "activities",
  {
    article,
    articleAmountUnit,
    createdBy: userSchema,
    customer,
    ownedBy: userSchema,
    processTemplate,
    project,
    status,
    technology,
    updatedBy: userSchema,
    valueAdd,
  },
  { idAttribute: "activityId" }
)
