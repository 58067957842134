import React from "react"

import { FormattedMessage } from "react-intl"
import { banfNumber } from "pages/Shared/Columns/SpecificColumns/BanfNumber"
import { TerminationNames } from "../../../types/TerminationTypes"
import { Column } from "../interfaces"
import { TerminationCell } from "../../../components/DataGrid/cellRenderers"
import {
  productionOrderNumberColumn,
  articleNumberColumn,
  articleNameColumn,
  articleAmountColumn,
  articleGroupNameColumn,
  orderPositionNumberColumn,
  locationNameColumn,
  startDateColumn,
  endDateColumn,
  processCustomerNumberColumn,
  processCustomerNameColumn,
  statusIdColumn,
  planAnywayColumn,
  createdByFullNameColumn,
  createdOnColumn,
  orderDateColumn,
  terminationDateColumn,
  ownedByFullNameColumn,
  processNumberColumn,
  processNameColumn,
  pendingColumn,
} from "../../../components/DataGrid/columns"

const ColumnsConfigProductionOrders: Column[] = [
  productionOrderNumberColumn,
  articleNumberColumn,
  articleNameColumn,
  articleGroupNameColumn,
  {
    id: "parentProcessItem.parentProcessItem.article.articleName",
    translationId: "pages.planningObjects.productionOrders.fields.orderPositionArticleName",
    accessor: "parentProcessItem.parentProcessItem.article.articleName",
    Header: <FormattedMessage id="pages.planningObjects.productionOrders.fields.orderPositionArticleName" />,
  },
  articleAmountColumn,
  orderPositionNumberColumn,
  locationNameColumn,
  startDateColumn,
  endDateColumn,
  {
    id: "termination.terminationName",
    translationId: "term.termination",
    accessor: "termination.terminationId",
    Cell: TerminationCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.termination" />,
    export: ({ value }: { value: string }) => ({ id: `type.termination.${TerminationNames[value]}` }),
  },
  processCustomerNumberColumn,
  processCustomerNameColumn,
  statusIdColumn,
  pendingColumn,
  processNumberColumn,
  processNameColumn,
  ownedByFullNameColumn,
  terminationDateColumn,
  orderDateColumn,
  createdOnColumn,
  createdByFullNameColumn,
  planAnywayColumn,
  banfNumber,
].filter(col => !!col)

export default ColumnsConfigProductionOrders
