import { get } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

export const initialState = {
  error: null,
  loginError: null,
  isLoaded: false,
  isPending: false,
  isPendingCheckToken: false,
  isPendingSSO: false,
  pinAuthenticated: false, // PIN authenticated
  token: null,
  user: {
    firstName: null,
    lastName: null,
    userEmail: null,
    userId: null,
    username: null,
    language: null,
  },
}

export default function (state = initialState, action) {
  const { payload } = action
  const message = get(action.payload, "message", null)
  const error = message ? { message } : null

  switch (action.type) {
    case ActionTypes.PIN_LOGIN: {
      return {
        ...initialState,
      }
    }
    case ActionTypes.PIN_LOGIN_SUCCESS: {
      const { user } = payload
      return {
        ...state,
        ...user,
        isPending: false,
        isLoaded: true,
        pinAuthenticated: true,
      }
    }
    case ActionTypes.PIN_LOGIN_FAILURE: {
      return {
        ...initialState,
        error,
        isPending: false,
        isLoaded: true,
      }
    }
    case ActionTypes.APP_INIT: {
      const { token } = payload
      return {
        ...state,
        isPendingSSO: !!token, // SSO
        isPendingCheckToken: !token, // normal
      }
    }
    case ActionTypes.LOGOUT_TO_TERMINAL_REQUEST:
      return {
        ...initialState,
      }
    case ActionTypes.LOGOUT_TO_TERMINAL_FAILURE:
      return {
        ...initialState,
      }
    case ActionTypes.USER_AUTO_LOGIN_TERMINAL_FAILURE:
      return {
        ...initialState,
        isPendingSSO: false,
        isPendingCheckToken: false,
        isLoaded: true,
        pinAuthenticated: false,
      }
    case ActionTypes.USER_AUTO_LOGIN_TERMINAL_SUCCESS: {
      const { pinAuthenticated, user, token } = payload
      return {
        ...state,
        isPending: false,
        isPendingSSO: false,
        isPendingCheckToken: false,
        isLoaded: true,
        pinAuthenticated,
        token: token || state.token,
        user,
      }
    }
    default:
      return state
  }
}
