import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import React from "react"
import { useIntl } from "react-intl"

const LocalizationProviderDatePicker = ({ children }) => {
  const { formatMessage } = useIntl()
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{
        cancelButtonLabel: formatMessage({ id: "action.cancel" }),
        okButtonLabel: formatMessage({ id: "action.ok" }),
        clearButtonLabel: formatMessage({ id: "action.clear" }),
      }}
    >
      {children}
    </LocalizationProvider>
  )
}

export default LocalizationProviderDatePicker
