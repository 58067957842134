import { get } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case ActionTypes.USERS_ADD_FAILURE:
    case ActionTypes.USERS_UPDATE_FAILURE: {
      const errorCode = get(action, "payload.response.errorCode")
      return {
        ...state,
        errorCode,
        pending: false,
      }
    }
    case ActionTypes.USERS_ADD_REQUEST:
    case ActionTypes.USERS_UPDATE_REQUEST: {
      return { ...state, errorCode: null, pending: true }
    }
    case ActionTypes.USERS_ADD_SUCCESS:
    case ActionTypes.USERS_UPDATE_SUCCESS: {
      return { ...state, errorCode: null, pending: false }
    }
    case ActionTypes.USER_FORM_ERROR_RESET: {
      return { ...state, errorCode: null, pending: false }
    }
    default:
      return state
  }
}
