import React from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useAuth } from "react-oidc-context"
import { generatePath, useHistory, useParams } from "react-router-dom"

import { logOut, terminalLogout } from "../../actions/authentication"
import { Routes } from "../../constants/routes"
import { getUserData } from "../../sagas/selectors"

const Logout = React.memo(() => {
  const auth = useAuth()
  const { userId, token } = useSelector(getUserData, shallowEqual)
  const dispatch = useDispatch()
  const { terminal } = useParams()
  const history = useHistory()
  const hasTerminalId = terminal && terminal !== ":terminal"
  if (auth.isAuthenticated) {
    auth.removeUser() // signoutRedirect
  }
  if (hasTerminalId && userId) {
    dispatch(terminalLogout(terminal, userId, token))
  } else if (userId) {
    dispatch(logOut(userId, token))
  } else if (hasTerminalId) {
    history.push(generatePath(Routes.TERMINAL_START, { terminalId: terminal }))
  } else {
    history.push(Routes.LOGIN)
  }
  return null
})

export default Logout
