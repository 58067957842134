import { compact, head, map, some, tail } from "lodash"

import ActionTypes from "../../../constants/ActionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case ActionTypes.UI_ARTICLE_DIALOG_CHANGE_STANDARD_TECHNOLOGY: {
      const technologyIndex = action.technology
      return {
        ...state,
        values: {
          ...state.values,
          technologyList: map(state.values.technologyList, (technology, index) => ({
            ...technology,
            isStandard: index === technologyIndex,
          })),
        },
      }
    }

    case ActionTypes.UI_ARTICLE_DIALOG_DELETED_TECHNOLOGY: {
      const { technologyList } = state.values
      if (some(technologyList, { isStandard: true }) || technologyList.length === 0) {
        return state
      }
      return {
        ...state,
        values: {
          ...state.values,
          technologyList: compact([{ ...head(technologyList), isStandard: true }, ...tail(technologyList)]),
        },
      }
    }

    default:
      return state
  }
}
