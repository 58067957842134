import { invert } from "lodash"

const ColorTypes = {
  RESOURCE_GROUP: 1,
  ARTICLE: 2,
}

export const ColorNames = invert(ColorTypes)

export default ColorTypes
