import { difference } from "lodash"
import ActionTypes from "../../constants/ActionTypes"
import messages from "../../i18n/messages"

const initialState = [...messages]

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.USER_LANGUAGES_SUCCESS: {
      return [...state, ...difference(payload, state)]
    }
    default:
      return state
  }
}
