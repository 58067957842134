import React from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import { Typography } from "@mui/material"
import classNames from "classnames"

const styles = () => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 64,
  },
  label: {
    color: "white",
    textTransform: "uppercase",
    padding: "0 15px",
  },
  "@media screen and (max-width: 600px)": {
    label: {
      fontSize: 11,
      padding: "0 5px",
    },
  },
})

const InfoLabel = ({ label, classes, className }) => (
  <div className={classNames(classes.root, className)}>
    <Typography variant="h6" className={classes.label}>
      {label}
    </Typography>
  </div>
)

InfoLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
}

InfoLabel.defaultProps = {
  className: null,
}

export default withStyles(styles)(InfoLabel)
