import { every, filter, first, includes, inRange, last, map, mapValues, pick, pickBy, sortBy, uniqBy } from "lodash"
import { matchRoutes } from "react-router-config"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"
import ItemTypes from "../../types/TimelineItemTypes"
import { Routes } from "../../constants/routes"
import StatusTypes from "../../types/StatusTypes"

export const timelineRoutes = [
  {
    id: "projectId",
    path: `${Routes.PLANNING_TIMELINE_PROJECTS}/:projectId`,
  },
  {
    id: "orderPositionId",
    path: `${Routes.PLANNING_TIMELINE_ORDERPOSITIONS}/:orderPositionId`,
  },
  {
    id: "activityId",
    path: `${Routes.PLANNING_TIMELINE_ACTIVITIES}/:activityId`,
  },
  {
    id: "productionOrderId",
    path: `${Routes.PRODUCTION_TIMELINE_PRODUCTIONORDERS}/:productionOrderId`,
  },
  {
    id: "operationId",
    path: `${Routes.PRODUCTION_TIMELINE_OPERATIONS}/:operationId`,
  },
  {
    id: "conflictId",
    path: `${Routes.PRODUCTION_TIMELINE_CONFLICTS}/:conflictId`,
  },
  {
    id: "dateConflictId",
    path: `${Routes.PLANNING_TIMELINE_CONFLICTS}/:dateConflictId`,
  },
]

const setSelection = (state, selection) => ({
  ...state,
  selection: [selection],
})

const addItemToSelection = (state, selection) => ({
  ...state,
  selection: [...state.selection, selection],
})

const addRangeToSelection = (state, selection) => ({
  ...state,
  selection: uniqBy([...state.selection, ...selection], "id"),
})

/* Add date objects to all items (needed because of timezones) */
const getDatedItem = item => ({
  ...item,
  endDate: new Date(item.endDate),
  startDate: new Date(item.startDate),
})

/* Select all items that have a common parent object */
const getItemsInGroup = (items, iteratee) => mapValues(pickBy(items, iteratee), getDatedItem)

/* Select all items LEFT of the previous selected items */
const getItemsInRangeLeft = (items, selectedItem, leftItem) => {
  const startRange = selectedItem.startDate.getTime()
  const endRange = leftItem.startDate.getTime() + 1
  return filter(items, item => inRange(item.startDate, startRange, endRange))
}

/* Select all items RIGHT of the previous selected items */
const getItemsInRangeRight = (items, selectedItem, rightItem) => {
  const startRange = rightItem.startDate.getTime()
  const endRange = selectedItem.startDate.getTime() + 1
  return filter(items, item => inRange(item.startDate.getTime(), startRange, endRange))
}
const getSelectedItems = (items, selection) => pick(items, map(selection, "id"))

const getRangeOfSelectedItems = (
  sortedItemsInGroup,
  selectedItem,
  firstAlreadySelectedItem,
  lastAlreadySelectedItem
) => {
  if (firstAlreadySelectedItem.startDate > selectedItem.startDate) {
    // Select range to the left
    return getItemsInRangeLeft(sortedItemsInGroup, selectedItem, firstAlreadySelectedItem)
  }
  // Select range to the right
  return getItemsInRangeRight(sortedItemsInGroup, selectedItem, lastAlreadySelectedItem)
}

const selectionConsistsOfMatchingActivities = (activities, selection, newSelectedItemId) => {
  const onlySimiliarItemsSelected = every(
    selection,
    selectedItem => selectedItem.type === ItemTypes.PlanningStep || selectedItem.type === ItemTypes.ProcessStep
  )
  const selectedActivity = activities[newSelectedItemId]
  const selectedActivities = getSelectedItems(activities, selection)

  const commonOrderPositon = every(selectedActivities, { orderPositionId: selectedActivity.orderPositionId })
  return onlySimiliarItemsSelected && commonOrderPositon
}

const selectionConsistsOfProductionOrders = (productionOrders, selection) =>
  every(selection, { type: ItemTypes.ProductionOrder })

const selectionConsistsOfMatchingProductionOrders = (productionOrders, selection, newSelectedItemId) => {
  const onlySimiliarItemsSelected = every(selection, { type: ItemTypes.ProductionOrder })
  const selectedProductionOrder = productionOrders[newSelectedItemId]
  const selectedProductionOrders = getSelectedItems(productionOrders, selection)
  const commonActivity = every(selectedProductionOrders, { activityId: selectedProductionOrder.activityId })

  return onlySimiliarItemsSelected && commonActivity
}

const selectionConsistsOfMatchingOperations = (operations, selection, newSelectedItemId) => {
  const onlySimiliarItemsSelected = every(selection, { type: ItemTypes.Operation })
  const selectedOperation = operations[newSelectedItemId]
  const selectedOperations = getSelectedItems(operations, selection)

  const commonResource =
    every(selectedOperations, {
      resource: selectedOperation.resource,
    }) ||
    every(selectedOperations, {
      productionOrderId: selectedOperation.productionOrderId,
    })

  return onlySimiliarItemsSelected && commonResource
}

export default (state = initialState, action) => {
  const { payload } = action
  const { activities, productionOrders, operations, selection, valueAdds } = state
  switch (action.type) {
    case ActionTypes.TIMELINE_SELECTION_ADD: {
      const { id, sourceTimeline, type } = payload

      if (includes(map(selection, "id"), id)) {
        // if the new selected item is already selected, unselect it
        return {
          ...state,
          selection: filter(selection, item => item.itemId !== id),
        }
      }

      switch (type) {
        case ItemTypes.Project: {
          const onlySimiliarItemsSelected = every(selection, { type: ItemTypes.Project })
          return onlySimiliarItemsSelected ? addItemToSelection(state, payload) : setSelection(state, payload)
        }
        case ItemTypes.PlanningStep:
        case ItemTypes.ProcessStep: {
          return selectionConsistsOfMatchingActivities(activities, selection, id)
            ? addItemToSelection(state, payload)
            : setSelection(state, payload)
        }
        case ItemTypes.ProductionOrder: {
          const isValidAddOperation =
            sourceTimeline === "production"
              ? selectionConsistsOfProductionOrders(productionOrders, selection)
              : selectionConsistsOfMatchingProductionOrders(productionOrders, selection, id)
          return isValidAddOperation ? addItemToSelection(state, payload) : setSelection(state, payload)
        }

        case ItemTypes.Operation: {
          return selectionConsistsOfMatchingOperations(operations, selection, id)
            ? addItemToSelection(state, payload)
            : setSelection(state, payload)
        }
        default: {
          return setSelection(state, payload)
        }
      }
    }

    case ActionTypes.TIMELINE_SELECTION_RANGE: {
      const { id, sourceTimeline, type } = payload

      switch (type) {
        case ItemTypes.Operation: {
          if (selectionConsistsOfMatchingOperations(operations, selection, id)) {
            const selectedItem = getDatedItem(operations[id])
            const itemsInGroup = getItemsInGroup(
              operations,
              sourceTimeline === "production"
                ? {
                    productionOrderId: selectedItem.productionOrderId,
                  }
                : { resource: selectedItem.resource }
            )
            const selectedItems = sortBy(getSelectedItems(itemsInGroup, selection))
            const sortedItemsInGroup = sortBy(itemsInGroup, ["startDate", "endDate"])

            const rangeInBetween = getRangeOfSelectedItems(
              sortedItemsInGroup,
              selectedItem,
              first(selectedItems),
              last(selectedItems)
            )

            return addRangeToSelection(
              state,
              map(rangeInBetween, item => ({
                id: item.operationId,
                type: ItemTypes.Operation,
              }))
            )
          }
          return setSelection(state, payload)
        }
        case ItemTypes.PlanningStep:
        case ItemTypes.ProcessStep: {
          if (selectionConsistsOfMatchingActivities(activities, selection, id)) {
            const selectedItem = getDatedItem(activities[id])
            const itemsInGroup = getItemsInGroup(activities, { orderPositionId: selectedItem.orderPositionId })
            const selectedItems = sortBy(getSelectedItems(itemsInGroup, selection))
            const sortedItemsInGroup = sortBy(itemsInGroup, ["startDate", "endDate"])

            const rangeInBetween = getRangeOfSelectedItems(
              sortedItemsInGroup,
              selectedItem,
              first(selectedItems),
              last(selectedItems)
            )

            return addRangeToSelection(
              state,
              map(rangeInBetween, item => ({
                id: item.activityId,
                type: valueAdds[item.valueAdd].productionProcessStep ? ItemTypes.ProcessStep : ItemTypes.PlanningStep,
              }))
            )
          }

          return setSelection(state, payload)
        }
        default: {
          return setSelection(state, payload)
        }
      }
    }

    case ActionTypes.TIMELINE_SELECTION_CLEAR: {
      return {
        ...state,
        selection: [],
      }
    }

    case ActionTypes.TIMELINE_SELECTION_DIRECTION: {
      const { direction, id, type } = payload
      if (type === ItemTypes.Operation) {
        const operation = operations[id]
        const { resource } = operation
        const selectionDate = new Date(operation.startDate)

        const filterSelector =
          direction === "left"
            ? op =>
                op.resource === resource && op.status !== StatusTypes.done && new Date(op.startDate) <= selectionDate
            : op =>
                op.resource === resource && op.status !== StatusTypes.done && new Date(op.startDate) >= selectionDate

        const itemsToSelect = filter(operations, filterSelector)
        const selectedItems = map(itemsToSelect, item => ({
          id: item.operationId,
          type: ItemTypes.Operation,
        }))
        return {
          ...state,
          selection: selectedItems,
        }
      }

      return { ...state }
    }

    case ActionTypes.TIMELINE_SELECTION_SET: {
      return {
        ...state,
        selection: [payload],
      }
    }

    case ActionTypes.UI_TIMELINE_PLANNING_DETAILS_PROJECT_NAVIGATE:
    case ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ORDER_POSITION_NAVIGATE:
    case ActionTypes.UI_TIMELINE_PLANNING_DETAILS_ACTIVITY_NAVIGATE:
    case ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_PRODUCTION_ORDER_NAVIGATE:
    case ActionTypes.UI_TIMELINE_PRODUCTION_DETAILS_OPERATION_NAVIGATE: {
      /* This is needed to prevent a focus of an item when we click on an item in the timeline
         while we still want the focus when the item is target via URL change from outside the timeline */
      return {
        ...state,
        blockFocusOnNextRouteChange: true,
      }
    }

    case "@@router/LOCATION_CHANGE": {
      if (payload.location.pathname && payload.location.pathname.indexOf("freeMovableMultiple") > -1) {
        return state
      }
      const matches = matchRoutes(timelineRoutes, payload.location.pathname)
      // const query = payload.search.slice(1)
      const focus = !state.blockFocusOnNextRouteChange

      const getSelection = () => {
        const { params } = matches[0].match

        const { activityId, conflictId, dateConflictId, operationId, orderPositionId, projectId, productionOrderId } =
          params
        if (activityId) {
          /*
            We do not know yet whether this an PlanningStep or a ProcessStep. However, it does no harm when setting it wrong here
          */
          return {
            focus,
            id: parseInt(activityId, 10),
            type: ItemTypes.ProcessStep,
          }
        }
        if (conflictId) {
          return {
            focus,
            id: conflictId,
            type: ItemTypes.ResourceConflict,
          }
        }
        if (operationId) {
          return {
            focus,
            id: parseInt(operationId, 10),
            type: ItemTypes.Operation,
          }
        }
        if (orderPositionId) {
          return {
            focus,
            id: parseInt(orderPositionId, 10),
            type: ItemTypes.OrderPosition,
          }
        }
        if (projectId) {
          return {
            focus,
            id: parseInt(projectId, 10),
            type: ItemTypes.Project,
          }
        }
        if (productionOrderId) {
          return {
            focus,
            id: parseInt(productionOrderId, 10),
            type: ItemTypes.ProductionOrder,
          }
        }
        if (dateConflictId) {
          return {
            id: parseInt(dateConflictId, 10),
            type: ItemTypes.DateConflict,
          }
        }

        return {
          id: null,
          type: null,
        }
      }
      if (matches.length) {
        return {
          ...state,
          selection: [getSelection()],
        }
      }
      return {
        ...state,
        selection: [],
      }
    }
    default:
      return state
  }
}
