import ActionTypes from "../../constants/ActionTypes"

export const deleteProductionOrder = id => ({
  type: ActionTypes.PLANNING_OBJECTS_DELETE_REQUEST,
  payload: {
    id,
    type: "productionOrders",
  },
})

export const fetchPlanningObjectsProductionOrderDetails = productionOrderId => ({
  type: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_LOAD_REQUEST,
  payload: {
    productionOrderId,
  },
})

export const updateProductionOrders = data => ({
  type: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_UPDATE_REQUEST,
  payload: { data },
})

export const revokeProductionOrder = productionOrderMix => ({
  type: ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_REVOKE_REQUEST,
  payload: {
    productionOrderId: productionOrderMix.productionOrder.productionOrderId,
    productionOrder: productionOrderMix,
  },
})
