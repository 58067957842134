import React, { useCallback } from "react"

import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Input from "@mui/material/Input"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"

import { isPlainObject, map, find, includes, remove } from "lodash"
import withStyles from "@mui/styles/withStyles"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"

import DatePicker from "../DatePicker/DatePicker"
import DateTimePicker from "../DatePicker/DateTimePicker"
import StatusTypes from "../../types/StatusTypes"

const numberOperations = map(["=", "<", ">"], val => (
  <MenuItem key={val} value={val}>
    {val}
  </MenuItem>
))
const shrinkedItem = { flexShrink: 1 }

const InputForHeader = withStyles({
  input: { height: "100%", marginLeft: 2, width: "100%" },
})(({ classes, ...props }) => <Input classes={classes} disableUnderline {...props} />)

const SelectForHeader = withStyles(theme => ({
  select: { paddingRight: theme.spacing(2), paddingBottom: 3 },
}))(({ classes, ...props }) => <Select variant="standard" classes={classes} disableUnderline {...props} />)

export const NumberHeader = props => {
  const {
    column: { filterValue = { comparator: "=" }, setFilter },
  } = props
  return (
    <Grid container wrap="nowrap">
      <Grid item style={shrinkedItem}>
        <SelectForHeader
          onChange={event => {
            setFilter({ ...filterValue, comparator: event.target.value })
          }}
          value={filterValue.comparator}
        >
          {numberOperations}
        </SelectForHeader>
      </Grid>
      <Grid item style={shrinkedItem}>
        <InputForHeader
          onChange={event => {
            setFilter({ ...filterValue, to: event.target.value })
          }}
          type="number"
          value={filterValue.to || ""}
        />
      </Grid>
    </Grid>
  )
}

const datePickerProps = {
  componentsProps: {
    actionBar: {
      actions: ["clear"],
    },
  },
  inputProps: { style: { boxSizing: "content-box" } },
  keyboardPickerEnabled: false,
  variant: "dialog",
}

const parseDate = date => (date ? moment(date).format("YYYY-MM-DD") : null)
const parseDateTime = date => (date ? moment(date).format("YYYY-MM-DD HH:mm") : null)

export const DateHeader = props => {
  const {
    column: { filterValue = { dateFrom: null, dateTo: null }, setFilter },
  } = props

  return (
    <Grid container wrap="nowrap">
      <DatePicker
        {...datePickerProps}
        onChange={useCallback(
          date => {
            setFilter({ ...filterValue, dateFrom: parseDate(date) })
          },
          [setFilter]
        )}
        value={filterValue.dateFrom}
      />
      <DatePicker
        {...datePickerProps}
        onChange={useCallback(
          date => {
            setFilter({ ...filterValue, dateTo: parseDate(date) })
          },
          [setFilter]
        )}
        value={filterValue.dateTo}
      />
    </Grid>
  )
}

export const DateTimeHeader = props => {
  const {
    column: { filterValue = { dateFrom: null, dateTo: null }, setFilter },
  } = props
  return (
    <Grid container wrap="nowrap">
      <DateTimePicker
        {...datePickerProps}
        onChange={useCallback(
          date => {
            setFilter({ ...filterValue, dateFrom: parseDateTime(date), isDateTime: true })
          },
          [setFilter]
        )}
        value={filterValue.dateFrom}
      />
      <DateTimePicker
        {...datePickerProps}
        onChange={useCallback(
          date => {
            setFilter({ ...filterValue, dateTo: parseDateTime(date), isDateTime: true })
          },
          [setFilter]
        )}
        value={filterValue.dateTo}
      />
    </Grid>
  )
}

export const SelectHeader =
  items =>
  ({ column: { filterValue = { enumValue: -1 }, setFilter } }) =>
    (
      <SelectForHeader
        onChange={event => {
          setFilter({ enumValue: event.target.value })
        }}
        style={{ width: "100%" }}
        value={filterValue.enumValue}
      >
        <MenuItem value={-1}>
          <FormattedMessage id="common.all" />
        </MenuItem>
        {map(items, (label, value) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectForHeader>
    )

const removeStatus = (selected, status) => {
  remove(selected, x => x === status)
}

const switchCompletedStatus = selected => {
  const notDone = `${StatusTypes.notDone}`
  const done = `${StatusTypes.done}`
  const notDoneIndex = selected.indexOf(notDone)
  const doneIndex = selected.indexOf(done)

  if (includes(selected, notDone)) {
    if (notDoneIndex < doneIndex) {
      removeStatus(selected, notDone)
    } else removeStatus(selected, done)
  }
  if (includes(selected, done)) {
    if (doneIndex < notDoneIndex) {
      removeStatus(selected, done)
    } else removeStatus(selected, notDone)
  }
  return selected
}

export const SelectHeaderCustomValues =
  (items, excludeKey = null, skipTranslation = false) =>
  ({ column: { filterValue = { enumValue: -1 }, setFilter } }) => {
    const filteredItems = items.filter(item => item.value !== excludeKey)
    const enumValue = isPlainObject(filterValue) ? filterValue.enumValue : filterValue
    return (
      <SelectForHeader
        onChange={event => {
          setFilter({ enumValue: event.target.value })
        }}
        style={{ width: "100%" }}
        value={enumValue}
      >
        <MenuItem value={-1}>
          <FormattedMessage id="common.all" />
        </MenuItem>
        {map(filteredItems, (item, i) => (
          <MenuItem key={`${i}_${item.value}`} value={item.value}>
            {skipTranslation ? item.label : <FormattedMessage id={item.label} />}
          </MenuItem>
        ))}
      </SelectForHeader>
    )
  }

export const SelectHeaderMultipleCustomValuesFunc = items => props =>
  <SelectHeaderMultipleCustomValues items={items} {...props} />

const SelectHeaderMultipleCustomValues = props => {
  const { items, excludeKey, skipTranslation, column, classes } = props
  // enumValue customer have saved status filter with enum value and not selectedValues
  const enumValue = column?.filterValue?.enumValue
  const selectedValues = column?.filterValue?.selectedValues || []
  const { setFilter } = column
  const filterValue = enumValue ? { selectedValues: [enumValue] } : { selectedValues }

  const filteredItems = items.filter(item => item.value !== excludeKey)
  const { formatMessage } = useIntl()
  return (
    <SelectForHeader
      onChange={event => {
        setFilter({ selectedValues: switchCompletedStatus(event.target.value) })
      }}
      style={{ width: "100%" }}
      value={filterValue.selectedValues}
      multiple
      classes={classes}
      size="small"
      renderValue={selectedItems => {
        if (selectedItems.length === 0) {
          return <FormattedMessage id="common.all" />
        }
        if (selectedItems.length === 1) {
          return <FormattedMessage id={find(filteredItems, ["value", selectedItems[0]]).label} />
        }
        if (selectedItems.length > 1) {
          return `(${selectedItems.length.toString()}) ${formatMessage({
            id: find(filteredItems, ["value", selectedItems[0]]).label,
          })}`
        }
        return ""
      }}
      displayEmpty
    >
      {map(filteredItems, (item, i) => (
        <MenuItem
          key={`${i}_${item.value}`}
          value={item.value}
          disabled={
            includes(filterValue.selectedValues, `${StatusTypes.notDone}`) && item.value !== `${StatusTypes.notDone}`
          }
          sx={{ px: 0.125, py: 0.5 }}
        >
          <Checkbox checked={filterValue.selectedValues.indexOf(item.value) > -1} sx={{ padding: 0.25 }} />
          <ListItemText
            primaryTypographyProps={{ fontSize: "14px" }}
            sx={{ px: 1 }}
            primary={skipTranslation ? item.label : <FormattedMessage id={item.label} />}
          />
        </MenuItem>
      ))}
    </SelectForHeader>
  )
}

export const SelectYesNot = SelectHeaderCustomValues([
  { value: true, label: "common.yes" },
  { value: false, label: "common.no" },
])

export const SelectYesNotInvert = SelectHeaderCustomValues([
  { value: false, label: "common.yes" },
  { value: true, label: "common.no" },
])

export const SelectOperationType = SelectHeaderCustomValues([
  { value: true, label: "common.primary" },
  { value: false, label: "common.secondary" },
])
