import Button from "@mui/material/Button"
import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { FormattedMessage } from "react-intl"

// it's not allowed to change this styles
// 40pt height of Icon
// 16 px of margin in all sites
const styles = {
  ssoButton: {
    fontFamily: "'Saira', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    background: "#008DAB",
    padding: 5,
    color: "#fff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
    position: "relative",
    transition: "ease all 0.2s",
    margin: "auto",
    textTransform: "none",
    marginTop: 24,
    "&:hover": {
      background: "#fff",
      color: "#008DAB",
      textDecoration: "none",
      "-webkit-box-shadow": "0px 3px 8px 2px rgb(0 0 0 / 20%)",
      boxShadow: "0px 3px 8px 2px rgb(0 0 0 / 20%)",
    },
  },
  adamosLogo: {
    width: 40,
    height: 40,
    backgroundImage: 'url("images/Adamos_on_white.svg")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
    marginRight: 10,
  },
  adamosEnter: {
    position: "absolute",
    width: 20,
    height: 18,
    right: 12,
    backgroundImage: "url('images/login_white.svg')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  },
}

const useStyles = makeStyles(styles)

const AdamosButton = props => {
  const classes = useStyles()
  return (
    <Button className={classes.ssoButton} color="primary" disableRipple variant="contained" {...props}>
      <i className={classes.adamosLogo} />
      <FormattedMessage id="pages.login.form.adamosLogin" />
      <i className={classes.adamosEnter} />
    </Button>
  )
}

export default AdamosButton
