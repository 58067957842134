import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import schema from "../../schemas/planningObjects/amountUnits"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"

export default [
  APIRequestSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_AMOUNT_UNITS_LIST,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/amounts`,
    schema,
  }),
]
