interface AnyObject {
  [name: string]: unknown
}

interface ResultObject {
  [name: string]: string
}

const keyMirror = function (object: AnyObject) {
  const result: ResultObject = {}
  if (!(object instanceof Object && !Array.isArray(object))) {
    throw new Error("Input must be an object.")
  }
  Object.keys(object).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(object, key)) {
      return
    }
    result[key] = key
  })
  return result
}

export default keyMirror
