import ActionTypes from "../../constants/ActionTypes"

export default (action, state = { isPending: false }, objectType) => {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_DELETE_REQUEST: {
      const { type } = action.payload
      return {
        ...state,
        isPending: type === objectType,
      }
    }
    case ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS: {
      return {
        ...state,
        isPending: false,
      }
    }
    default:
      return state
  }
}
