import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import { FormattedMessage } from "react-intl"
import { reduxForm, Field } from "redux-form"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import InputLabel from "@mui/material/InputLabel"
import { trim } from "lodash"

import FormInput from "../../components/form/Text"
import { Routes } from "../../constants/routes"
import AdamosButtonWithOr from "./AdamosButtonWithOr"
import LinkWithTypography from "../../components/LinkWithTypography/LinkWithTypography"

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    display: "block",
  },
  button: {
    width: "100%",
    padding: 14,
    fontSize: "1rem",
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  paper: {
    padding: 4,
    marginBottom: theme.spacing(3),
  },
  label: {
    color: "white",
    fontWeight: "300",
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
})

const required = value => (value ? undefined : <FormattedMessage id="validations.required" />)
const initialValues = { username: "", password: "" }

@withStyles(styles)
@reduxForm({
  form: "login",
  initialValues,
})
class LoginForm extends PureComponent {
  static propTypes = {
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSSOCustomer: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoginSSO: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.inputNameField = React.createRef()
  }

  componentDidMount() {
    this.inputNameField.current.focus()
  }

  render() {
    const { classes, handleSubmit, isSSOCustomer, onSubmit, onLoginSSO, change } = this.props
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel className={classes.label} htmlFor="username" required>
          <FormattedMessage id="pages.login.form.username.placeholder" />
        </InputLabel>
        <Paper className={classes.paper}>
          <FormControl variant="standard" className={classes.formControl}>
            <Field
              name="username"
              component={FormInput}
              inputRef={this.inputNameField}
              inputProps={{ autoFocus: true }}
              className={classes.textField}
              type="text"
              required
              onChange={(e, newValue) => {
                e.preventDefault()
                change("username", trim(newValue))
              }}
              normalize={trim}
              validate={required}
              disableUnderline
            />
          </FormControl>
        </Paper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <InputLabel className={classes.label} htmlFor="password" required>
              <FormattedMessage id="common.password" />
            </InputLabel>
          </Grid>
          <Grid item>
            <LinkWithTypography
              sx={{ color: "#fff" }}
              href={Routes.FORGOT_PASSWORD}
              caption={<FormattedMessage id="pages.login.forgotPassword" />}
              tabIndex="-1"
            />
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <FormControl variant="standard" className={classes.formControl}>
            <Field
              name="password"
              component={FormInput}
              type="password"
              fullWidth
              required
              onChange={(e, newValue) => {
                e.preventDefault()
                change("password", trim(newValue))
              }}
              validate={required}
              disableUnderline
            />
          </FormControl>
        </Paper>
        <Button className={classes.button} color="primary" disableRipple type="submit" variant="contained">
          <FormattedMessage id="action.next" />
        </Button>
        {isSSOCustomer && <AdamosButtonWithOr onClick={onLoginSSO} />}
      </form>
    )
  }
}

export default LoginForm
