import { schema } from "normalizr"
import createdBy from "../createdBy/item"
import moduleRoleList from "../moduleRoleList"

export default new schema.Entity(
  "userDetails",
  {
    createdBy,
    moduleRoleList,
  },
  {
    idAttribute: "userId",
  }
)
