import React from "react"
import SvgIcon from "@mui/material/SvgIcon"

const SplittedIcon = React.memo(({ isSplit, splitted, ...props }) => {
  if (splitted) {
    return (
      <SvgIcon {...props}>
        <g id="Icon_gesplittet_Mutter" data-name="Icon gesplittet Mutter" transform="translate(-773 176)">
          <g transform="translate(773 -176)" stroke="#707070" strokeWidth="1" opacity="0">
            <rect width="24" height="24" stroke="none" />
            <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
          </g>
          <path d="M12,1H0V-1H12Z" transform="translate(785 -167) rotate(90)" fill="#cdcdcd" />
          <path d="M15.177,1H0V-1H15.177Z" transform="translate(786.632 -168.075) rotate(128)" fill="#cdcdcd" />
          <path
            d="M15.177,0H0V2H15.177Z"
            transform="matrix(0.616, 0.788, -0.788, 0.616, 784.156, -168.691)"
            fill="#cdcdcd"
          />
          <circle cx="4" cy="4" r="4" transform="translate(781 -174)" fill="#e8384f" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(774 -158)" fill="#cdcdcd" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(791 -158)" fill="#cdcdcd" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(782.5 -158)" fill="#cdcdcd" />
        </g>
      </SvgIcon>
    )
  }
  if (isSplit) {
    return (
      <SvgIcon {...props}>
        <g id="Icon_gesplittet_Kind" data-name="Icon gesplittet Kind" transform="translate(-773 176)">
          <g transform="translate(773 -176)" stroke="#707070" strokeWidth="1" opacity="0">
            <rect width="24" height="24" stroke="none" />
            <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
          </g>
          <path d="M12,1H0V-1H12Z" transform="translate(785 -167) rotate(90)" fill="#cdcdcd" />
          <path d="M15.177,1H0V-1H15.177Z" transform="translate(786.632 -168.075) rotate(128)" fill="#cdcdcd" />
          <path
            d="M15.177,0H0V2H15.177Z"
            transform="matrix(0.616, 0.788, -0.788, 0.616, 784.156, -168.691)"
            fill="#cdcdcd"
          />
          <circle cx="4" cy="4" r="4" transform="translate(781 -174)" fill="#cdcdcd" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(774 -158)" fill="#37c5ab" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(791 -158)" fill="#37c5ab" />
          <circle cx="2.5" cy="2.5" r="2.5" transform="translate(782.5 -158)" fill="#37c5ab" />
        </g>
      </SvgIcon>
    )
  }
  return ""
})

export default SplittedIcon
