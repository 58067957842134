import { isArray, map } from "lodash"
import { genericRequestState } from "./api"
import ActionTypes from "../constants/ActionTypes"
import ColumnsConfigActivities from "../pages/Shared/Columns/ColumnsConfigActivities"
import ColumnsConfigOperations from "../pages/Shared/Columns/ColumnsConfigOperations"
import ColumnsConfigProductionOrders from "../pages/Shared/Columns/ColumnsConfigProductionOrders"
import ColumnsConfigProjects from "../pages/Shared/Columns/ColumnsConfigProjects"
import ColumnsConfigOrderPositions from "../pages/Shared/Columns/ColumnsConfigOrderPositions"
import { AvailabilityCalcNames, AvailabilityCalcTypes, EfficiencyCalcNames, EfficiencyCalcTypes } from "../types/Oee"
import ColorTypes, { ColorNames } from "../types/ColorTypes"
import TerminationTypes, { TerminationNames } from "../types/TerminationTypes"

const specificInitialState = {
  fetch: { ...genericRequestState },
  update: { ...genericRequestState },
  settings: {
    checkPlausibility: false,
    oeeAvailabilityCalc: AvailabilityCalcTypes.FULL,
    oeeEfficiencyCalc: EfficiencyCalcTypes.PLAN,
  },
}

const defaultTemplates = {
  workbenchTicketDescriptionOperations: [
    "project.projectNumber",
    "project.projectName",
    "article.articleNumber",
    "article.articleName",
    "productionOrderNumber",
    "valueAdd.valueAddName",
  ],
  workbenchTicketDescriptionActivities: [
    "projectNumber",
    "projectName",
    "article.articleNumber",
    "article.articleName",
    "valueAdd.valueAddName",
    "customer.customerName",
  ],
  resourcePlanningItemLabel: ["productionOrder.productionOrderNumber", "article.articleName"],
  projectsColumns: map(ColumnsConfigProjects, item => item.id),
  orderPositionsColumns: map(ColumnsConfigOrderPositions, item => item.id),
  productionOrdersColumns: map(ColumnsConfigProductionOrders, item => item.id),
  operationsColumns: map(ColumnsConfigOperations, item => item.id),
  activitiesColumns: map(ColumnsConfigActivities, item => item.id),
  operationsColumnsExternal: map(ColumnsConfigOperations, item => item.id),
  activitiesColumnsExternal: map(ColumnsConfigActivities, item => item.id),
}

const parseJSON = (settings, fieldName) => {
  if (settings[fieldName] && isArray(JSON.parse(settings[fieldName])) && JSON.parse(settings[fieldName]).length) {
    return JSON.parse(settings[fieldName])
  }
  if (defaultTemplates[fieldName]) {
    return defaultTemplates[fieldName]
  }
  return null
}

export const readSettings = settings => ({
  ...settings,
  workbenchTicketDescriptionActivities: parseJSON(settings, "workbenchTicketDescriptionActivities"),
  workbenchTicketDescriptionOperations: parseJSON(settings, "workbenchTicketDescriptionOperations"),
  resourcePlanningItemLabel: parseJSON(settings, "resourcePlanningItemLabel"),
  projectsColumns: parseJSON(settings, "projectsColumns"),
  orderPositionsColumns: parseJSON(settings, "orderPositionsColumns"),
  productionOrdersColumns: parseJSON(settings, "productionOrdersColumns"),
  operationsColumns: parseJSON(settings, "operationsColumns"),
  activitiesColumns: parseJSON(settings, "activitiesColumns"),
  operationsColumnsExternal: parseJSON(settings, "operationsColumnsExternal"),
  activitiesColumnsExternal: parseJSON(settings, "activitiesColumnsExternal"),
  oeeAvailabilityCalc: AvailabilityCalcTypes[settings.oeeAvailabilityCalc],
  oeeEfficiencyCalc: EfficiencyCalcTypes[settings.oeeEfficiencyCalc],
  colorType: ColorTypes[settings.colorType],
  defaultTermination: TerminationNames[settings.defaultTermination]
    ? settings.defaultTermination
    : TerminationTypes.Forward,
})

export const writeSettings = settings => ({
  ...settings,
  workbenchTicketDescriptionActivities: JSON.stringify(settings.workbenchTicketDescriptionActivities),
  workbenchTicketDescriptionOperations: JSON.stringify(settings.workbenchTicketDescriptionOperations),
  resourcePlanningItemLabel: JSON.stringify(settings.resourcePlanningItemLabel),
  projectsColumns: JSON.stringify(settings.projectsColumns),
  orderPositionsColumns: JSON.stringify(settings.orderPositionsColumns),
  productionOrdersColumns: JSON.stringify(settings.productionOrdersColumns),
  operationsColumns: JSON.stringify(settings.operationsColumns),
  activitiesColumns: JSON.stringify(settings.activitiesColumns),
  operationsColumnsExternal: JSON.stringify(settings.operationsColumnsExternal),
  activitiesColumnsExternal: JSON.stringify(settings.activitiesColumnsExternal),
  oeeAvailabilityCalc: AvailabilityCalcNames[settings.oeeAvailabilityCalc],
  oeeEfficiencyCalc: EfficiencyCalcNames[settings.oeeEfficiencyCalc],
  colorType: ColorNames[settings.colorType],
  defaultTermination: TerminationNames[settings.defaultTermination]
    ? settings.defaultTermination
    : TerminationTypes.Forward,
})

//* we use now graphQL, but we need still redux to not refactor all of code *//
export default (state = specificInitialState, action) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.GLOBALSETTINGS_FETCH_SUCCESS: {
      return {
        ...state,
        settings: readSettings(payload),
        fetch: {
          isPending: false,
          isFinished: true,
          isError: false,
        },
      }
    }

    default:
      return {
        ...state,
      }
  }
}
