import keyMirror from "keymirror"
import accessProxy from "../utils/accessProxy"

const TimelineItemTypes = keyMirror({
  ProductionOrder: null,
  Operation: null,
  Activity: null,
  PlanningStep: null,
  ProcessStep: null,
  Project: null,
  OrderPosition: null,
  ResourceConflict: null,
  DateConflict: null,
  OperationStatusConflict: null,
  FrozenZone: null,
})

export default accessProxy(TimelineItemTypes)
