import ActionTypes from "../../constants/ActionTypes"

const initialState = {
  updateLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TIMELINE_SET_LOADING: {
      return {
        ...state,
        updateLoading: true,
      }
    }
    case ActionTypes.TIMELINE_UNSET_LOADING: {
      return {
        ...state,
        updateLoading: false,
      }
    }
    default:
      return state
  }
}
