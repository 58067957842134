import ActionTypes from "../../../constants/ActionTypes"
import { APIRequestSequence } from "../../api"
import { API_SERVER_PLANNING_PROCESS } from "../../../constants/api"
import importsSchema from "../../../schemas/imports/index"

export default [
  APIRequestSequence({
    actionType: ActionTypes.IMPORTS_LIST,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/imports`,
    schema: importsSchema,
  }),
]
