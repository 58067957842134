import PropTypes from "prop-types"
import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"

momentDurationFormatSetup(moment)

export const FormattedDuration = ({ value, short, inclusiveMinutes, inclusiveSeconds, inclusiveDays }) => {
  const valueDuration = moment.duration(value, "seconds")
  if (inclusiveDays && !short) {
    if (valueDuration.asSeconds() >= 3600) {
      if (valueDuration.asMinutes() >= 1440) return valueDuration.format("dd[d]:hh[h]:mm[m]:ss[s]")
      return valueDuration.format("hh[h]:mm[m]:ss[s]")
    }
    return valueDuration.format("mm[m]:ss[s]", {
      trim: false,
    })
  }
  if (inclusiveMinutes && inclusiveSeconds && !short) {
    return valueDuration.format("hh[h]:mm[m]:ss[s]", {
      trim: false,
    })
  }
  if (inclusiveMinutes && !inclusiveSeconds && !short) {
    return valueDuration.format("hh[h]:mm[m]", {
      trim: false,
    })
  }
  if (!inclusiveMinutes && !inclusiveSeconds && !short) {
    return valueDuration.format("hh[h]", {
      trim: false,
    })
  }
  if (inclusiveMinutes && inclusiveSeconds && short) {
    return valueDuration.format("hh:mm:ss", {
      trim: false,
    })
  }
  if (inclusiveMinutes && !inclusiveSeconds && short) {
    return valueDuration.format("hh:mm", {
      trim: false,
    })
  }
  if (!inclusiveMinutes && !inclusiveSeconds && short) {
    return valueDuration.format("hh", {
      trim: false,
    })
  }
  return valueDuration.format()
}

const FormattedDurationPropTypes = {
  value: PropTypes.number.isRequired,
  inclusiveSeconds: PropTypes.bool,
  inclusiveMinutes: PropTypes.bool,
  short: PropTypes.bool,
}

const FormattedDurationDefaultProps = {
  short: false,
  inclusiveSeconds: false,
  inclusiveMinutes: true,
}

FormattedDuration.propTypes = FormattedDurationPropTypes
FormattedDuration.defaultProps = FormattedDurationDefaultProps
