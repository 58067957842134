import { split } from "lodash"
import ActionTypes from "../constants/ActionTypes"

const getSimulationIdFromUrl = () => {
  const {
    location: { pathname },
  } = window
  const localWords = split(pathname, "/")
  if (localWords[1] === "simulation" && Number(localWords[2]) > 0) {
    return Number(localWords[2])
  }
  return null
}

interface State {
  id: number
  baselineId: number
}

interface actionSet {
  type: string
  payload: {
    id: number
  }
}

interface actionReset {
  type: string
  payload: {
    id: number
  }
}

type Action = actionSet | actionReset

const initialState = {
  id: getSimulationIdFromUrl(),
  baselineId: 0,
}

const reducer = (state: State = initialState, action: Action): State => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (action.type === ActionTypes.APP_SIMULATION_SET) {
    return {
      ...state,
      id: action.payload.id,
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (action.type === ActionTypes.APP_SIMULATION_BASELINE_SET) {
    return {
      ...state,
      baselineId: action.payload.id,
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (action.type === ActionTypes.APP_SIMULATION_RESET) {
    return initialState
  }
  return state
}

export default reducer
