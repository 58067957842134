import ActionTypes from "../../constants/ActionTypes"
import { setValue } from "../../utils/storage"

const initialState = {
  terminal: null,
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.PIN_LOGIN: {
      return {
        ...state,
        terminal: payload.terminal,
      }
    }

    case ActionTypes.PIN_AUTO_LOGIN: {
      return {
        ...state,
        terminal: payload.terminal || state.terminal,
      }
    }
    case ActionTypes.SET_TERMINAL_ID: {
      return {
        ...state,
        terminal: payload.terminal || state.terminal,
      }
    }

    case ActionTypes.TERMINAL_LOCK_SUCCESS: {
      setValue("terminalToken", payload) // terminalLockToken token
      return state
    }

    default:
      return state
  }
}
