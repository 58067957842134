import { get } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

export const initialState = {
  authenticated: false, // password authenticated
  error: null,
  loginError: null,
  isLoaded: false,
  isPending: false,
  isPendingCheckToken: false,
  isPendingSSO: false,
  token: null,
  user: {
    firstName: null,
    lastName: null,
    userEmail: null,
    userId: null,
    username: null,
    language: null,
  },
}

export default function (state = initialState, action) {
  const { payload } = action
  const message = get(action.payload, "message", null)
  const error = message ? { message } : null

  switch (action.type) {
    case ActionTypes.USER_DETAILS_SELF_UPDATE: {
      const { language, location, roles } = payload
      return { ...state, user: { ...state.user, language, location, roles } }
    }
    case ActionTypes.USER_CHANGE_LANGUAGE_SUCCESS: {
      const { language } = payload.data[0]
      return { ...state, user: { ...state.user, language } }
    }

    case ActionTypes.SCREEN_LOGIN_SUCCESS: {
      const { user } = payload
      return {
        ...state,
        ...user,
        authenticated: false,
        isPending: false,
        isLoaded: true,
      }
    }

    case ActionTypes.APP_INIT: {
      const { token } = payload
      return {
        ...state,
        isPendingSSO: !!token, // SSO
        isPendingCheckToken: !token, // normal
      }
    }
    case ActionTypes.USER_AUTO_LOGIN_FAILURE:
      return {
        ...initialState,
        error,
        isPendingSSO: false,
        isPendingCheckToken: false,
        isLoaded: true,
      }

    case ActionTypes.USER_AUTO_LOGIN_SUCCESS: {
      const { pinAuthenticated, rfidAuthenticated, user, token } = payload
      return {
        ...state,
        authenticated: !pinAuthenticated && !rfidAuthenticated,
        isPending: false,
        isPendingSSO: false,
        isPendingCheckToken: false,
        isLoaded: true,
        token: token || state.token,
        user,
      }
    }

    case ActionTypes.USER_LOGIN_FAILURE: {
      return {
        ...initialState,
        loginError: error,
        isPending: false,
        isLoaded: true,
      }
    }

    case ActionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        isPending: true,
        error: null,
        loginError: null,
      }

    case ActionTypes.USER_LOGIN_SUCCESS: {
      const { user } = payload
      return {
        ...state,
        ...user,
        authenticated: true,
        isPending: false,
        isLoaded: true,
      }
    }

    case ActionTypes.USER_LOGOUT_FAILURE:
      return {
        ...initialState,
      }

    case ActionTypes.USER_LOGOUT_REQUEST:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
