import React, { Fragment } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import cn from "classnames"
import Typography from "@mui/material/Typography"
import { grey } from "../../constants/colors"

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
    color: grey,
  },
  cardContent: {
    flex: 1,
    fontSize: 12,
    marginTop: 7,
  },
  alignHorizontal: {
    display: "flex",
    flexDirection: "row",
  },
})

const PaperSheet = ({ children, classes, subHeaderElement, title, customContentStyle }) => (
  <Fragment>
    {title && (
      <Typography className={classes.title} color="textPrimary" variant="h6">
        {title}
      </Typography>
    )}
    <Paper className={classes.root} elevation={1}>
      {subHeaderElement ? <Typography variant="subtitle2">{subHeaderElement}</Typography> : null}
      <div className={classes.alignHorizontal}>
        <Typography className={cn({ [classes.cardContent]: !customContentStyle })} variant="caption">
          {children}
        </Typography>
      </div>
    </Paper>
  </Fragment>
)

PaperSheet.propTypes = {
  customContentStyle: PropTypes.bool,
  subHeaderElement: PropTypes.node,
  title: PropTypes.node,
}

PaperSheet.defaultProps = {
  customContentStyle: false,
  subHeaderElement: null,
  title: null,
}

export default withStyles(styles)(PaperSheet)
