import React from "react"
import PropTypes from "prop-types"

const fullHeight = {
  height: "100%",
}
const style = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
}

const Form = ({ children, onSubmit }) => (
  <form onSubmit={onSubmit} style={fullHeight}>
    <div style={style}>{children}</div>
  </form>
)

Form.propTypes = {
  onSubmit: PropTypes.func,
}

Form.defaultProps = {
  onSubmit: () => {},
}
export default Form
