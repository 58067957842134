// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react"
import { useIntl } from "react-intl"
import { isEmpty, toUpper } from "lodash"

export interface User {
  isFakeUser: boolean
  fullName: string
  lastName: string
  firstName: string
}

interface Literals {
  fullName: string
  initials: string
}

export interface Message {
  id: string
}

const getFullName = (user: User, formatMessage: (arg0: Message) => string) => {
  if (user.isFakeUser) {
    return formatMessage({ id: "entity.user.fakeUser" })
  }
  if (user.fullName) {
    return user.fullName
  }

  if (user.lastName || user.firstName) {
    return `${user.lastName} ${user.firstName}`
  }

  return null
}

const getInitials = (user: User) => {
  if (user.isFakeUser || user.fullName === "fakeUser") {
    return "--"
  }

  const firstNameInitial = user.firstName || "-"
  const lastNameInitial = user.lastName || "-"
  return toUpper(`${firstNameInitial[0]}${lastNameInitial[0]}`)
}

export const getLiterals = (user: User, formatMessage: (arg0: Message) => string): Literals => {
  if (isEmpty(user)) {
    return null
  }

  return {
    fullName: getFullName(user, formatMessage),
    initials: getInitials(user),
  }
}

export const getUsername = (user: User, formatMessage: (arg0: Message) => string): string => {
  if (isEmpty(user)) {
    return null
  }

  return getFullName(user, formatMessage)
}

interface UserNameType {
  user: User
}

const UserName = ({ user }: UserNameType): string => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { formatMessage } = useIntl()
  return getUsername(user, formatMessage)
}

export default UserName
