import React from "react"
import PropTypes from "prop-types"
import { DateTimePicker, MobileDateTimePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

import { useIntl } from "react-intl"
import { useChangeValue } from "./useChangeValue"
import { useAcceptValueOnBlur } from "./useAcceptValueOnBlur"
import { useHelperText } from "./useHelperText"
import { useOnAccept } from "./useOnAccept"

const CustomPicker = ({
  formatDateTime,
  keyboardPickerEnabled,
  onBlur,
  onChange,
  value,
  errorProps: { error, forceDisplayFormError, showEmptyHelper },
  fullWidth,
  required,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [valueToAccept, changeValue] = useChangeValue(value, formatDateTime)
  const [helperText, setHelperText] = useHelperText(error, forceDisplayFormError, showEmptyHelper, formatMessage)

  const Picker = keyboardPickerEnabled ? DateTimePicker : MobileDateTimePicker

  const acceptValueOnBlur = useAcceptValueOnBlur(valueToAccept, changeValue, onChange, onBlur, formatDateTime)
  return (
    <Picker
      closeOnSelect
      showToolbar={false}
      inputFormat="DD.MM.YYYY HH:mm"
      placeholder={formatMessage({ id: "common.datetimeFormat" })}
      onChange={changeValue}
      onAccept={useOnAccept(onChange, formatDateTime)}
      onError={setHelperText}
      value={valueToAccept}
      toolbarTitle={formatMessage({ id: "common.datetimeChoose" })}
      renderInput={params => (
        <TextField
          {...params}
          error={!!helperText && helperText !== " "}
          data-test-id="dateTimePickerField"
          onBlur={acceptValueOnBlur}
          required={required}
          fullWidth={fullWidth}
          helperText={helperText}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            // eslint-disable-next-line no-useless-computed-key
            ["data-test-id"]: Picker.render.name,
            type: "text",
            placeholder: formatMessage({ id: "common.datetimeFormat" }),
          }}
        />
      )}
      {...props}
    />
  )
}

CustomPicker.propTypes = {
  errorProps: PropTypes.object,
  formatDateTime: PropTypes.string,
  keyboardPickerEnabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
}

CustomPicker.defaultProps = {
  formatDateTime: "",
  onBlur: undefined,
  keyboardPickerEnabled: true,
  value: null,
  variant: "inline",
  errorProps: { error: null, forceDisplayFormError: false, showEmptyHelper: false },
  fullWidth: false,
  required: false,
}

export default CustomPicker
