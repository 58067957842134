import { schema } from "normalizr"

import createdBy from "../../users/item"
import resourceGroup from "../resourceGroups/item"
import resourceStatusSchema from "../resourceStatusList/item"

export default new schema.Entity(
  "resources",
  {
    createdBy,
    resourceGroup,
    status: resourceStatusSchema,
  },
  {
    idAttribute: "resourceId",
  }
)
