import { difference, forEach, map, mapValues, pick, omit, without } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"

import { adjustOrderPosition, adjustProductionOrder, cloneStore } from "./helpers/helpers"

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_DELETE_SUCCESS: {
      const {
        activities: deletedActivities,
        operations: deletedOperations,
        orderPositions: deletedOrderPositions,
        productionOrders: deletedProductionOrders,
        projects: deletedProjects,
      } = action.payload
      const nextState = cloneStore(state)

      nextState.operations = omit(nextState.operations, deletedOperations)
      nextState.productionOrders = omit(nextState.productionOrders, deletedProductionOrders)
      nextState.productionOrders = mapValues(nextState.productionOrders, productionOrder => ({
        ...productionOrder,
        operations: without(productionOrder.operations, ...deletedOperations),
      }))
      nextState.activities = omit(nextState.activities, deletedActivities)
      nextState.activities = mapValues(nextState.activities, activity => ({
        ...activity,
        productionOrders: without(activity.productionOrders, ...deletedProductionOrders),
      }))
      nextState.orderPositions = omit(nextState.orderPositions, deletedOrderPositions)
      nextState.orderPositions = mapValues(nextState.orderPositions, orderPosition => ({
        ...orderPosition,
        activities: without(orderPosition.activities, ...deletedActivities),
      }))
      nextState.projects = omit(nextState.projects, deletedProjects)
      nextState.projects = mapValues(nextState.projects, project => ({
        ...project,
        orderPositions: without(project.orderPositions, ...deletedOrderPositions),
      }))

      let orderPositionsToAdjust = map(pick(state.activities, deletedActivities), "orderPositionId")
      let productionOrdersToAdjust = map(pick(state.operations, deletedOperations), "productionOrderId")
      orderPositionsToAdjust = difference(orderPositionsToAdjust, deletedOrderPositions)
      productionOrdersToAdjust = difference(productionOrdersToAdjust, deletedProductionOrders)
      forEach(orderPositionsToAdjust, orderPositionId => adjustOrderPosition(nextState, orderPositionId))
      forEach(productionOrdersToAdjust, productionOrderId => adjustProductionOrder(nextState, productionOrderId))
      return nextState
    }
    default:
      return state
  }
}
