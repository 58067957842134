import { useCallback, useRef } from "react"
import { get, map, omitBy } from "lodash"
import moment from "moment"
import { useIntl } from "react-intl"

import { DateCell, DateTimeCell, DecimalCell, IntegerCell } from "./cellRenderers"
import download from "../../utils/download"

export const exportToCsv = (props, intl) => {
  const { columns, sortedRows } = props
  exportToDownload(
    map(sortedRows, ({ original }) => original),
    columns,
    intl
  )
}

export const useExport = () => {
  const intl = useIntl()
  const gridRef = useRef(null)
  const exportData = useCallback(() => {
    exportToCsv(gridRef.current, intl)
  }, [gridRef])
  return [gridRef, exportData]
}

export const exportGqlToCsv = (props, intl) => {
  const { columns, fetchAll } = props
  return fetchAll().then(result => exportToDownload(result, columns, intl))
}

export const exportBoolean = ({ value }) => ({
  id: value ? "common.yes" : "common.no",
})

const normalizeString = str => {
  if (!str) {
    return '""'
  }
  const retval = str.toString().replace(/\s/g, " ")
  return `${JSON.stringify(retval)}`
}

export const exportToDownload = (rows, columns, intl) => {
  const { formatMessage, formatNumber } = intl
  const filteredColumns = omitBy(columns, col => col.id === "newlyCreated" || col.id === "switchedOff")
  const headerRow = map(filteredColumns, item => {
    const id = get(item, "Header.props.id")

    if (id) {
      return formatMessage({ id })
    }

    return item.Header
  }).join(";")

  const exportData = map(rows, row =>
    map(filteredColumns, column => {
      const accessor = get(column, "accessor")
      const exportFunc = get(column, "export")
      const Cell = get(column, "Cell")
      if (!accessor) {
        return ""
      }

      const value = typeof accessor === "function" ? accessor(row) : get(row, accessor)

      if (exportFunc && typeof exportFunc === "function") {
        const result = exportFunc({ value }, formatMessage)
        const translateId = get(result, "id")
        if (translateId) {
          return formatMessage({ id: translateId })
        }
        return result
      }

      if (value && Cell === DateTimeCell) {
        return moment(value).format("YYYY-MM-DD HH:mm:ss")
      }

      if (value && Cell === DateCell) {
        return moment(value).format("YYYY-MM-DD")
      }

      if (value && (Cell === IntegerCell || Cell === DecimalCell)) {
        return formatNumber(value)
      }
      if (typeof value === "object" && value) {
        let valuesAsString = ""
        Object.keys(value).forEach((prop, index) => {
          const divider = index > 0 ? "\t / " : ""
          valuesAsString += `${divider}${value[prop]}`
        })
        return normalizeString(valuesAsString)
      }
      return normalizeString(value)
    }).join(";")
  ).join("\r\n")
  const str = `${headerRow}\r\n${exportData}`
  const dataArr = new Uint8Array(str.length)
  str.split("").forEach((a, b) => {
    dataArr[b] = a.charCodeAt()
  })
  download(dataArr, `planeusexport-${moment().format("YYYY-MM-DD-HH:mm:ss")}.csv`, "text/csv")
}
