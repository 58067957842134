interface RouteSegmentsType {
  DETAILS: string
  FEEDBACK: string
  HISTORY: string
  REJECTTYPE: string
  REJECTTYPEUPDATE: string
  TAKEPHOTO: string
  TAKEPHOTO_EDIT: string
  TROUBLETYPE: string
  TROUBLETYPEUPDATE: string
  TROUBLEFEEDBACK: string
  UPDATEPROGRESS: string
  WORKITEM: string
  SCAN: string
}

const RouteSegments: RouteSegmentsType = {
  DETAILS: "/workitem/:workId/details",
  FEEDBACK: "/workitem/:workId/feedback",
  HISTORY: "/workitem/:workId/history",
  REJECTTYPE: "/workitem/:workId/rejecttype",
  REJECTTYPEUPDATE: "/workitem/:progressId/rejecttypeupdate",
  TAKEPHOTO: "/workitem/:workId/takephoto",
  TAKEPHOTO_EDIT: "/progress/:progressId/takephoto",
  TROUBLETYPE: "/workitem/:workId/:mainOperationId?/troubletype",
  TROUBLETYPEUPDATE: "/workitem/:progressId/troubletypeupdate",
  TROUBLEFEEDBACK: "/workitem/:workId/:mainOperationId?/trouble",
  UPDATEPROGRESS: "/progress/:progressId",
  WORKITEM: "/workitem/:workId",
  SCAN: "/scan",
}

export default RouteSegments
