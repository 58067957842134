import ActionTypes from "../../constants/ActionTypes"

const INITIAL_STATE = {
  resourceType: "all",
}

function resourceListReducer(state = INITIAL_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.TERMINAL_SET_RESOURCE_TYPE:
      return {
        ...state,
        resourceType: payload,
      }
    default:
      return state
  }
}

export default resourceListReducer
