import { takeLatest, put, select } from "redux-saga/effects"

import { createSnackbarMessage } from "../../actions/ui/snackbar"
import ActionTypes from "../../constants/ActionTypes"
import { API_SERVER_USER } from "../../constants/api"
import { getUserData } from "../selectors"
import { put as putFetch } from "../../utils/http"
import { createAction } from "../../utils/redux"
import MessageTypes from "../../types/MessageTypes"

function* lockScreen({ payload: screen, name: screenName }) {
  const { token } = yield select(getUserData)
  const options = {
    credentials: token ? "same-origin" : "include",
    customHeaders: { Authorization: `Bearer ${token}` },
    responseType: "json",
    url: `${API_SERVER_USER}/screens/${screen}`,
  }

  try {
    const body = yield putFetch(options)
    yield put(createAction(ActionTypes.SCREEN_LOCK_SUCCESS, body.screenToken))
    yield put(createSnackbarMessage(MessageTypes.INFO, "pages.screen.activated", { id: screenName }))
  } catch (e) {
    yield put(createAction(ActionTypes.SCREEN_LOCK_FAILURE))
    yield put(createSnackbarMessage(MessageTypes.ERROR, "pages.screen.notActivated", { id: screenName }))
  }
}

export default [takeLatest(ActionTypes.SCREEN_LOCK_REQUEST, lockScreen)]
