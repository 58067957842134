import { schema } from "normalizr"
import article from "../../basedata/articles/item"
import articleAmountUnit from "../amountUnits/item"
import technology from "../../basedata/technologies/item"
import processTemplate from "../../basedata/templates/item"
import userSchema from "../../users/item"
import customer from "../../basedata/customers/item"
import status from "../../status/item"

export default new schema.Entity(
  "orderPositions",
  {
    article,
    createdBy: userSchema,
    updatedBy: userSchema,
    ownedBy: userSchema,
    customer,
    status,
    articleAmountUnit,
    technology,
    processTemplate,
  },
  { idAttribute: "orderPositionId" }
)
