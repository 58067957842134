import { combineReducers } from "redux"
import availableHoursPerDay from "./availableHoursPerDay"
import basedata from "./basedata"
import workbenches from "./workbenches"
import snackbar from "./snackbar"
import timelines from "./timelines"
import filters from "./filters"
import breadcrumbs from "./breadcrumbs"

export default combineReducers({
  availableHoursPerDay,
  basedata,
  breadcrumbs,
  filters,
  snackbar,
  timelines,
  workbenches,
})
