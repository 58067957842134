import { map, sortBy } from "lodash"
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range"
import memoizeOne from "memoize-one"

import { forcedTimezone } from "./forcedTimezone"

const type = "holiday"

export const getHolidaySlices = memoizeOne((holidays, timezone) =>
  sortBy(
    map(holidays, holiday => {
      const startDate = moment.tz(holiday.dateFrom, timezone)

      const endDate = moment.tz(holiday.dateTo, timezone).add(1, "d")

      return {
        ...holiday,
        end: endDate.toDate(),
        start: startDate.toDate(),
        type,
      }
    }),
    ["start", "end"]
  )
)

export const getHolidayItems = memoizeOne((holidays, holidaysMessage) =>
  map(getHolidaySlices(holidays, forcedTimezone), holiday => ({
    ...holiday,
    className: "holiday",
    id: `holiday${holiday.id}`,
    type: "background",
    title: `${holidaysMessage}: ${holiday.description}`,
  }))
)
