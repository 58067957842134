import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence } from "../api"
import { API_SERVER_USER } from "../../constants/api"

export default [
  APIGenericSequence({
    actionType: ActionTypes.USER_LANGUAGES,
    endPoint: `${API_SERVER_USER}/clients/languages`,
  }),
]
