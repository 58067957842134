import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.BASEDATA_TEMPLATE_DETAILS_UPDATE_SUCCESS: {
      return {
        ...state,
        [payload.result[0].templateId]: payload.result[0],
      }
    }
    default:
      return state
  }
}
