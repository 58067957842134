import ActionTypes from "../../../constants/ActionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case ActionTypes.TIMELINE_FILTER_FORM_SET_VALUES: {
      const { payload } = action

      return {
        ...state,
        values: payload,
      }
    }
    default:
      return state
  }
}
