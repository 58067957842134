import React from "react"
import Typography from "@mui/material/Typography"
import withStyles from "@mui/styles/withStyles"

const styles = {
  root: {
    marginRight: 16,
  },
}

const FormError = ({ children, classes }) => (
  <div className={classes.root}>
    <Typography align="right" color="error" gutterBottom>
      {children}
    </Typography>
  </div>
)

export default withStyles(styles)(FormError)
