import ActionTypes from "../../constants/ActionTypes"

export function setBreadcrumbs(breadcrumbs, separator, hideMenuButton) {
  return {
    type: ActionTypes.UI_BREADCRUMBS_SET,
    payload: { breadcrumbs, separator, hideMenuButton },
  }
}

export function clearBreadcrumbs() {
  return {
    type: ActionTypes.UI_BREADCRUMBS_CLEAR,
  }
}
