import { ceil } from "lodash"
import moment from "moment/moment"

import DurationTypes from "../../types/DurationTypes"

const MINUTE = 60
const HOUR = 60 * 60
const DAY = 60 * 60 * 24
const WEEK = 60 * 60 * 24 * 7
const MONTH = (60 * 60 * 24 * 365) / 12
const YEAR = 60 * 60 * 24 * 365

export const UNIT = {
  0: "seconds",
  1: "minutes",
  2: "hours",
  3: "days",
  4: "weeks",
  5: "months",
  6: "years",
}

export function durationByFromTo(from, to) {
  if (!from || !to) return null
  const seconds = moment(to).diff(from, "seconds")
  if (Number.isNaN(seconds)) return null
  return formatSecondsInAppropriateUnit(seconds)
}

export function formatSecondsInAppropriateUnit(seconds) {
  if (seconds >= YEAR) return { amount: seconds / YEAR, unit: "years" }
  if (seconds >= MONTH) return { amount: seconds / MONTH, unit: "months" }
  if (seconds >= WEEK) return { amount: seconds / WEEK, unit: "weeks" }
  if (seconds >= DAY) return { amount: seconds / DAY, unit: "days" }
  if (seconds >= HOUR) return { amount: seconds / HOUR, unit: "hours" }
  if (seconds >= MINUTE) return { amount: seconds / MINUTE, unit: "minutes" }
  return { amount: seconds, unit: "seconds" }
}

export const getAppropriateDurationUnit = seconds => {
  if (seconds >= YEAR && (seconds / YEAR) % 1 === 0) return DurationTypes.year
  if (seconds >= MONTH && (seconds / MONTH) % 1 === 0) return DurationTypes.month
  if (seconds >= WEEK && (seconds / WEEK) % 1 === 0) return DurationTypes.week
  if (seconds >= DAY && (seconds / DAY) % 1 === 0) return DurationTypes.day
  if (seconds >= HOUR && (seconds / HOUR) % 1 === 0) return DurationTypes.hour
  return DurationTypes.minute
}

const secondsFormatter = {
  [DurationTypes.year]: seconds => seconds / YEAR,
  [DurationTypes.month]: seconds => seconds / MONTH,
  [DurationTypes.week]: seconds => seconds / WEEK,
  [DurationTypes.day]: seconds => seconds / DAY,
  [DurationTypes.hour]: seconds => seconds / HOUR,
  [DurationTypes.minute]: seconds => seconds / MINUTE,
}

export function formatSecondsInUnit(seconds, unit) {
  return unit ? secondsFormatter[unit](seconds) : seconds
}

const durationFormatter = {
  [DurationTypes.year]: duration => duration * YEAR,
  [DurationTypes.month]: duration => duration * MONTH,
  [DurationTypes.week]: duration => duration * WEEK,
  [DurationTypes.day]: duration => duration * DAY,
  [DurationTypes.hour]: duration => duration * HOUR,
  [DurationTypes.minute]: duration => duration * MINUTE,
}

export function convertDurationToSeconds(duration, unit) {
  return unit ? ceil(durationFormatter[unit](duration)) : Number(duration)
}

export function durationByAmountAndUnit(amount, unit) {
  if (!(unit.durationUnitId in UNIT)) throw new Error("Unknown duration unit")
  return { amount, unit: UNIT[unit.durationUnitId] }
}
