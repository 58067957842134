import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_OPERATION_DETAILS_UPDATE_SUCCESS: {
      const { operation } = action.payload
      const nextState = {
        ...state,
        operations: {
          ...state.operations,
          [operation.operationId]: {
            ...state.operations[operation.operationId],
            workerId: operation.workerId,
          },
        },
      }
      return nextState
    }
    default:
      return state
  }
}
