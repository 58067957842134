import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import ActionTypes from "../../constants/ActionTypes"
import { del } from "../../utils/http"

const endPointUrl = `${API_SERVER_PLANNING_PROCESS}/expert`

export default [
  APIGenericSequence({
    actionType: ActionTypes.PLANNING_OBJECTS_DELETE,
    endPoint: endPointUrl,
    prepareUrl: (endPoint, { id, type }) => `${endPoint}/${type}/${id}`,
    method: del,
  }),
]
