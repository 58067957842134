import KeyMirror from "keymirror"
import accessProxy from "../utils/accessProxy"

const MessageTypes = KeyMirror({
  ERROR: null,
  INFO: null,
  WARNING: null,
})

export default accessProxy(MessageTypes)
