import React from "react"

import { FormattedMessage } from "react-intl"
import { handOverDate } from "pages/Shared/Columns/SpecificColumns/HandOverDate"
import { TerminationNames } from "../../../types/TerminationTypes"
import { Column } from "../interfaces"
import { BooleanCell, SplittedIconCell, TerminationCell } from "../../../components/DataGrid/cellRenderers"
import { SelectHeaderCustomValues, SelectYesNot } from "../../../components/DataGrid/filterHeaders"
import { exportBoolean } from "../../../components/DataGrid/export"
import {
  articleAmountColumn,
  articleNameColumn,
  articleNumberColumn,
  articleGroupNameColumn,
  createdByFullNameColumn,
  createdOnColumn,
  endDateColumn,
  locationNameColumn,
  orderDateColumn,
  orderPositionNumberColumn,
  ownedByFullNameColumn,
  pendingColumn,
  planAnywayColumn,
  processCustomerNameColumn,
  processCustomerNumberColumn,
  processNameColumn,
  processNumberColumn,
  startDateColumn,
  statusIdColumn,
  terminationDateColumn,
  attachmentsColumn,
} from "../../../components/DataGrid/columns"

const ColumnsConfigOrderPositions: Column[] = [
  processNumberColumn,
  processNameColumn,
  ownedByFullNameColumn,
  locationNameColumn,
  orderPositionNumberColumn,
  orderDateColumn,
  processCustomerNumberColumn,
  processCustomerNameColumn,
  articleNumberColumn,
  articleNameColumn,
  articleAmountColumn,
  articleGroupNameColumn,
  statusIdColumn,
  pendingColumn,
  {
    id: "comment",
    translationId: "term.comment",
    accessor: "comment",
    Cell: BooleanCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.comment" />,
    width: 40,
    showTooltip: false,
  },
  {
    ...attachmentsColumn,
    id: "hasAttachments",
    translationId: "item.hasAttachments",
    accessor: "hasAttachments",
    Filter: <div />,
    width: 40,
    showTooltip: false,
  },
  {
    id: "isSplit",
    translationId: "term.hadSplit",
    accessor: (row: { splitted: boolean; isSplit: boolean }): { splitted: boolean; isSplit: boolean } => ({
      splitted: row.splitted,
      isSplit: row.isSplit,
    }),
    Cell: SplittedIconCell,
    Filter: SelectHeaderCustomValues([{ value: true, label: "term.hadSplit" }]),
    Header: <FormattedMessage id="term.hadSplit" />,
  },
  planAnywayColumn,
  {
    id: "termination.terminationName",
    translationId: "term.termination",
    accessor: "terminationId",
    Cell: TerminationCell,
    Filter: <div />,
    Header: <FormattedMessage id="term.termination" />,
    export: ({ value }: { value: string }) => ({ id: `type.termination.${TerminationNames[value]}` }),
  },
  terminationDateColumn,
  handOverDate,
  startDateColumn,
  endDateColumn,
  createdOnColumn,
  createdByFullNameColumn,
  {
    id: "createdByImport",
    translationId: "term.imported",
    accessor: "createdByImport",
    Header: <FormattedMessage id="term.imported" />,
    Cell: BooleanCell,
    Filter: SelectYesNot,
    export: exportBoolean,
    showTooltip: false,
  },
]

export default ColumnsConfigOrderPositions
