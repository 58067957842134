import { createAction } from "../../utils/redux"
import ActionTypes from "../../constants/ActionTypes"
import TimelineItemTypes from "../../types/TimelineItemTypes"

export const replanNextOperation = operation => createAction(ActionTypes.TIMELINE_OPERATION_REPLAN, { operation })

export const undoHandling = () => createAction(ActionTypes.TIMELINE_ITEM_UNDO_UPDATE)

export const closeGapsLeft = () =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_CLOSE_GAPS_LEFT,
  })

export const closeGapsRight = () =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_CLOSE_GAPS_RIGHT,
  })

export const optimizeOperationsLeft = () =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_LEFT,
  })

export const optimizeOperationsRight = () =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_OPTIMIZE_OPERATIONS_RIGHT,
  })

export const filterCleverly = (productionOrderId, keepSelection) => ({
  payload: { productionOrderId, keepSelection },
  type: ActionTypes.TIMELINE_FILTER_CLEVER,
})

export const resetFilterCleverly = () => ({
  type: ActionTypes.TIMELINE_FILTER_RESET,
})

export function moveItem(item, movedItems) {
  let actionType
  switch (item.itemType) {
    case TimelineItemTypes.PlanningStep:
    case TimelineItemTypes.ProcessStep:
      actionType = ActionTypes.TIMELINE_ACTIVITY_MOVE
      break
    case TimelineItemTypes.Operation:
      actionType = ActionTypes.TIMELINE_OPERATION_MOVE
      if (item.mainOperationId) {
        actionType = ActionTypes.TIMELINE_PARALLEL_OPERATION_MOVE
      }
      break
    case TimelineItemTypes.ProductionOrder:
      actionType = ActionTypes.TIMELINE_PRODUCTIONORDER_MOVE
      break
    case TimelineItemTypes.Project:
      actionType = ActionTypes.TIMELINE_PROJECT_MOVE
      break
    default: {
      // pass
    }
  }
  return createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: actionType,
    payload: {
      item,
      movedItems,
    },
  })
}

export const moveMultipleItem = data =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_OPERATIONS_FREE_MOVE,
    payload: data,
  })

export function resizeItem(item) {
  let actionType
  switch (item.itemType) {
    case TimelineItemTypes.PlanningStep:
    case TimelineItemTypes.ProcessStep:
      actionType = ActionTypes.TIMELINE_ACTIVITY_RESIZE
      break
    case TimelineItemTypes.Operation:
      actionType = ActionTypes.TIMELINE_OPERATION_RESIZE
      break
    case TimelineItemTypes.Project:
      actionType = ActionTypes.TIMELINE_PROJECT_RESIZE
      break
    default: {
      // pass
    }
  }
  return createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: actionType,
    payload: {
      item,
    },
  })
}

export function moveItemPreview(item) {
  let actionType
  switch (item.itemType) {
    case TimelineItemTypes.ProcessStep:
      actionType = ActionTypes.TIMELINE_ACTIVITY_MOVE_PREVIEW
      break
    case TimelineItemTypes.Operation:
      actionType = ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW
      break
    default: {
      // pass
    }
  }
  return createAction(actionType, {
    item,
  })
}

export function clearItemPreview({ itemType }) {
  let actionType
  switch (itemType) {
    case TimelineItemTypes.ProcessStep:
      actionType = ActionTypes.TIMELINE_ACTIVITY_MOVE_PREVIEW_CLEAR
      break
    case TimelineItemTypes.Operation:
      actionType = ActionTypes.TIMELINE_OPERATION_MOVE_PREVIEW_CLEAR
      break
    default: {
      // pass
    }
  }
  return createAction(actionType, {
    itemType,
  })
}

export function toggleActivityLock(activityId) {
  return createAction(ActionTypes.UI_TIMELINE_TOGGLE_ACTIVITY_LOCK, { activityId })
}

export function toggleOperationLock(operationId) {
  return createAction(ActionTypes.UI_TIMELINE_TOGGLE_OPERATION_LOCK, { operationId })
}

export function fetchPlanningData() {
  return createAction(ActionTypes.TIMELINE_DATA_FETCH_REQUEST)
}

export function resetLoaded() {
  return createAction(ActionTypes.TIMELINE_DATA_FETCH_RESET)
}

export function fetchPlanningDataProjects(projectIds) {
  return createAction(ActionTypes.TIMELINE_DATA_FETCH_PROJECTS, { projectIds })
}

export const selectItem = (id, type) => createAction(ActionTypes.TIMELINE_SELECTION_SET, { id, type })
export const selectItemRange = (id, type, sourceTimeline) =>
  createAction(ActionTypes.TIMELINE_SELECTION_RANGE, { id, sourceTimeline, type })
export const selectItemsLeft = (id, type) =>
  createAction(ActionTypes.TIMELINE_SELECTION_DIRECTION, { id, direction: "left", type })
export const selectItemsRight = (id, type) =>
  createAction(ActionTypes.TIMELINE_SELECTION_DIRECTION, { id, direction: "right", type })
export const selectMultipleItems = (id, type, sourceTimeline) =>
  createAction(ActionTypes.TIMELINE_SELECTION_ADD, { id, sourceTimeline, type })
export const resetSelect = () => createAction(ActionTypes.TIMELINE_SELECTION_CLEAR)

export const applyOperationsResourcesSuggestions = data =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: data.ActionType || ActionTypes.TIMELINE_OPERATION_MOVE,
    payload: {
      ...data,
    },
  })

export const setTimelineUpdateLoading = () => createAction(ActionTypes.TIMELINE_SET_LOADING)

export const unsetTimelineUpdateLoading = () => createAction(ActionTypes.TIMELINE_UNSET_LOADING)

export const lockProductionOrder = data =>
  createAction(ActionTypes.TIMELINE_ITEM_UPDATE, {
    type: ActionTypes.TIMELINE_PRODUCTIONORDER_LOCKED,
    payload: data,
  })
