import { schema } from "normalizr"
import valueAdd from "../../valueAdds/item"
import resource from "../resources/item"
import resourceGroup from "../resourceGroups/item"

export default new schema.Entity(
  "technologyItems",
  {
    valueAdd,
    resource,
    resourceGroup,
  },
  {
    idAttribute: "technologyItemId",
  }
)
