import { invert } from "lodash"

export const coreStatusTypes = {
  started: 3,
  interrupted: 4,
  done: 5,
}

const plannedStatusTypes = {
  paused: 6,
  planned: 2,
  ...coreStatusTypes,
}

const customStatusTypes = {
  notDone: 7,
}

const StatusTypes = {
  notPlanned: 1,
  ...plannedStatusTypes,
  ...customStatusTypes,
}

const statusSplitTypes = {
  notPlanned: 1,
  ...coreStatusTypes,
  paused: 6,
}

export const disabledNotPlannedStatusTypes = [
  StatusTypes.planned,
  StatusTypes.notPlanned,
  StatusTypes.started,
  StatusTypes.interrupted,
  StatusTypes.paused,
]

export const disabledPlannedStatusTypes = [
  StatusTypes.planned,
  StatusTypes.started,
  StatusTypes.interrupted,
  StatusTypes.paused,
]

const confirmPlannedStatusTypes = [StatusTypes.done]
export const StatusNames = invert(StatusTypes)
export const CoreStatusTypes = coreStatusTypes
export const PlannedStatusTypes = plannedStatusTypes
export const DisabledPlannedStatusTypes = disabledPlannedStatusTypes
export const DisabledNotPlannedStatusTypes = disabledNotPlannedStatusTypes
export const ConfirmPlannedStatusTypes = confirmPlannedStatusTypes
export const StatusSplitTypes = statusSplitTypes

export default StatusTypes
