async function getIndexHtmlFromServer() {
  const headers = new Headers()
  headers.append("pragma", "no-cache")
  headers.append("cache-control", "no-cache")
  const response = await window.fetch("/index.html", {
    cache: "no-store",
    headers,
  })
  const content = await response.text()
  const newDocument = new DOMParser().parseFromString(content, "text/html")
  return newDocument
}

async function checkVersion() {
  // get current version from html
  const currentVersionNumberTag = document.querySelector("#build-hash")
  if (!currentVersionNumberTag) return
  const currentVersionNumber = currentVersionNumberTag.textContent

  // get latest index.html from server
  const newDocument = await getIndexHtmlFromServer()

  // get version from the latest index.html
  const newVersionNumberTag = newDocument.querySelector("#build-hash")
  if (!newVersionNumberTag) return
  const newVersionNumber = newVersionNumberTag.textContent

  if (currentVersionNumber !== newVersionNumber) {
    window.location.reload(true)
  }
}

export default checkVersion
