import ActionTypes from "../constants/ActionTypes"

export function logIn(username, password) {
  return {
    type: ActionTypes.USER_LOGIN_REQUEST,
    payload: { username, password },
  }
}

export function logOut(userId, token) {
  return {
    type: ActionTypes.USER_LOGOUT_REQUEST,
    payload: { userId, token },
  }
}

export function pinLogin(terminal, pin) {
  return {
    type: ActionTypes.PIN_LOGIN,
    payload: { pin, terminal },
  }
}

export function pinAutoLogin(terminal) {
  return {
    type: ActionTypes.PIN_AUTO_LOGIN,
    payload: { terminal },
  }
}

export function setTerminal(terminal) {
  return {
    type: ActionTypes.SET_TERMINAL_ID,
    payload: { terminal },
  }
}

export function screenLock(screenId, screenName) {
  return {
    type: ActionTypes.SCREEN_LOCK_REQUEST,
    payload: screenId,
    name: screenName,
  }
}

export function terminalLock(terminalId, terminalName) {
  return {
    type: ActionTypes.TERMINAL_LOCK_REQUEST,
    payload: terminalId,
    name: terminalName,
  }
}

export function terminalLogout(terminal, userId, token) {
  return {
    type: ActionTypes.LOGOUT_TO_TERMINAL_REQUEST,
    payload: {
      token,
      userId,
      terminal,
    },
  }
}
