import React, { useState } from "react"
import PropTypes from "prop-types"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material"
import { isMobile } from "react-device-detect"

const wrapStyles = {
  position: "relative",
  cursor: "inherit",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
}

const noWidthWrapStyles = {
  position: "relative",
  cursor: "inherit",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

const StyledRoot = styled("div", {
  shouldForwardProp: prop => prop !== "multilineInTwo" && prop !== "isWrapping",
})(({ multilineInTwo, isWrapping }) =>
  multilineInTwo
    ? {
        width: "100%",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }
    : isWrapping
)

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  pointerEvents: "none",
  [`& .${tooltipClasses.tooltip}`]: {
    border: "2px solid white",
    backgroundColor: theme.palette.grey[700],
  },
}))

const getPosition = (ele, positionStyle) => {
  const element = ele.getBoundingClientRect()
  const { width, height, left, top } = element
  const offsetTop = document.body.getBoundingClientRect().top
  return {
    position: positionStyle,
    pointerEvents: "none",
    width,
    height,
    left,
    top: top - offsetTop,
  }
}

const TooltipOverflow = props => {
  const {
    always,
    tooltip,
    children,
    textAlign,
    className,
    multilineInTwo,
    showTooltipProp,
    positionStyle,
    noWrap,
    ...tooltipProps
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [proxyStyle, setProxyStyle] = useState({})
  const [textWrap, setTextWrap] = useState(null)
  const [initial, setInitial] = useState(null)

  const shouldOpenTooltip = () => always || textWrap.offsetWidth < textWrap.scrollWidth

  const showTooltip = (forceTimeout = false) => {
    if (showTooltipProp && shouldOpenTooltip() !== isOpen) {
      setIsOpen(true)
      if (isMobile || forceTimeout) {
        const init = setTimeout(() => {
          hideTooltip()
        }, 5000)
        setInitial(init)
      }
      setProxyStyle(getPosition(textWrap, positionStyle))
    }
  }

  const hideTooltip = () => {
    setIsOpen(false)
    if (isMobile) {
      clearTimeout(initial)
    }
    setProxyStyle({})
  }

  const title = tooltip || children
  const isWrapping = noWrap ? noWidthWrapStyles : wrapStyles

  return (
    <StyledRoot
      ref={div => setTextWrap(div)}
      className={className}
      onMouseEnter={() => {
        showTooltip()
      }}
      onMouseLeave={() => {
        hideTooltip()
      }}
      style={{ textAlign }}
      onClick={() => {
        showTooltip()
      }}
      onTouchStart={() => {
        showTooltip(true)
      }}
      multilineInTwo={multilineInTwo}
      isWrapping={isWrapping}
    >
      {children}
      {isOpen && (
        <StyledTooltip
          open
          disableHoverListener
          title={title}
          placement="top-start"
          PopperProps={{ anchorEl: textWrap }}
          {...tooltipProps}
        >
          <div className="tooltip-content" style={proxyStyle} />
        </StyledTooltip>
      )}
    </StyledRoot>
  )
}

TooltipOverflow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  textAlign: PropTypes.string,
  always: PropTypes.bool,
  multilineInTwo: PropTypes.bool,
  showTooltipProp: PropTypes.bool,
  positionStyle: PropTypes.string,
  noWrap: PropTypes.bool,
}

TooltipOverflow.defaultProps = {
  className: "",
  tooltip: "",
  textAlign: "",
  always: false,
  multilineInTwo: false,
  showTooltipProp: true,
  positionStyle: "absolute",
  noWrap: false,
}

export default TooltipOverflow
