import { takeLatest, put as putEffect } from "redux-saga/effects"
import { get } from "lodash"
import { push } from "connected-react-router"
import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence, APIRequestSequence } from "../api"
import { API_SERVER_USER } from "../../constants/api"
import userListSchema from "../../schemas/userList"
import userDetailsSchema from "../../schemas/userDetails"
import changePasswordSchema from "../../schemas/changePassword/item"
import { post, put, del } from "../../utils/http"
import hash from "../../utils/hash"
import { Routes } from "../../constants/routes"

export default [
  APIRequestSequence({
    actionType: ActionTypes.USERS_LIST,
    endPoint: `${API_SERVER_USER}/users`,
    schema: userListSchema,
  }),
  APIRequestSequence({
    actionType: ActionTypes.USERS_DETAILS,
    endPoint: `${API_SERVER_USER}/users`,
    schema: userDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareResponse: response => [response],
  }),
  APIRequestSequence({
    actionType: ActionTypes.USERS_ADD,
    endPoint: `${API_SERVER_USER}/users`,
    schema: userListSchema,
    method: post,
    // eslint-disable-next-line no-unused-vars
    prepareRequest: ({ data: { repeatPassword, password, rfidNumber, ...data } }) => ({
      // eslint-disable-line no-unused-vars
      ...data,
      passwordhash: hash(password),
      rfidNumber: rfidNumber ? hash(rfidNumber) : rfidNumber,
    }),
    prepareResponse: response => [response],
  }),
  APIRequestSequence({
    actionType: ActionTypes.USERS_UPDATE,
    endPoint: `${API_SERVER_USER}/users`,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    schema: userDetailsSchema,
    method: put,
    // eslint-disable-next-line no-unused-vars
    prepareRequest: ({ data: { repeatPassword, password, ...data } }) => ({
      ...data,
      passwordhash: typeof password === "string" ? hash(password) : password,
    }),
    prepareResponse: response => [response],
  }),
  APIRequestSequence({
    actionType: ActionTypes.USER_CHANGE_LANGUAGE,
    endPoint: `${API_SERVER_USER}/users`,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}/language`,
    schema: userDetailsSchema,
    method: put,
    prepareRequest: req => req,
    // eslint-disable-next-line no-unused-vars
    prepareResponse: response => [response],
  }),
  APIGenericSequence({
    actionType: ActionTypes.USERS_DELETE,
    endPoint: `${API_SERVER_USER}/users`,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareResponse: (data, state, payload) => payload,
    method: del,
  }),
  APIRequestSequence({
    actionType: ActionTypes.USERS_CHANGE_PASSWORD,
    endPoint: `${API_SERVER_USER}/users`,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}/changePassword`,
    schema: changePasswordSchema,
    method: put,
    prepareRequest: ({ data: { password, newPassword } }) => ({
      passwordhash: hash(password),
      newPasswordhash: hash(newPassword),
    }),
  }),
  takeLatest(ActionTypes.USERS_ADD_SUCCESS, function* navigate(action) {
    const userId = get(action, "payload.data[0].userId", "new")
    const url = `${Routes.USER_MANAGEMENT_USERS}/${userId}`
    yield putEffect(push(url))
  }),
  takeLatest(ActionTypes.USERS_UPDATE_SUCCESS, function* navigate(action) {
    const userId = get(action, "payload.data[0].userId", "")
    const url = `${Routes.USER_MANAGEMENT_USERS}/${userId}`
    yield putEffect(push(url))
  }),
  takeLatest(ActionTypes.USERS_UPDATE_SUCCESS, function* refreshUsers() {
    yield putEffect({
      type: ActionTypes.MODULES_LIST_REQUEST,
    })
    yield putEffect({
      type: ActionTypes.USERS_LIST_REQUEST,
    })
  }),
  takeLatest(ActionTypes.USERS_DELETE_SUCCESS, function* navigate(action) {
    const {
      payload: { errorCode },
    } = action
    if (!errorCode) {
      yield putEffect(push(Routes.USER_MANAGEMENT_USERS))
    }
  }),
]
