import { omit } from "lodash"
import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_ORDER_POSITION_REMOVE: {
      const {
        payload: { orderPositionId },
      } = action
      return omit(state, orderPositionId)
    }

    default:
      return state
  }
}
