import reduceReducers from "reduce-reducers"
import { without } from "lodash"
import { createAPIRequestReducer, initialState } from "../api"
import ActionTypes from "../../constants/ActionTypes"

const listReducer = createAPIRequestReducer(ActionTypes.USERS_LIST)

function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USERS_ADD_SUCCESS:
      return {
        ...state,
        entities: [...state.entities, ...action.payload.result],
      }
    case ActionTypes.USERS_DELETE_SUCCESS: {
      const withoutDeleted = without(state.entities, Number(action.payload.id))
      if (withoutDeleted.length !== state.entities.length) {
        return {
          ...state,
          entities: withoutDeleted,
        }
      }
      return state
    }
    default:
      return state
  }
}

export default reduceReducers(listReducer, updateReducer)
