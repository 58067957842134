import { isArray, reduce, isObject, camelCase } from "lodash" // todo: try do this without lodash

export function formatKeys(obj) {
  if (!isObject(obj)) {
    return obj
  }
  if (isArray(obj)) {
    return obj.map(formatKeys)
  }
  return reduce(
    obj,
    (r, v, k) => ({
      ...r,
      [camelCase(k)]: formatKeys(v),
    }),
    {}
  )
}

export function formatAPIResponse(input) {
  return new Promise(resolve => resolve(formatKeys(input)))
}

export const preparePrefixUrl = (url, simulationId) => {
  if (!simulationId) {
    return url
  }
  const urlObject = new URL(url)
  return `${urlObject.origin}/simulation/${simulationId}${urlObject.pathname}`
}
