import { keyBy } from "lodash"

import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.BASEDATA_PUBLIC_HOLIDAYS_LIST_SUCCESS: {
      return keyBy(payload, "id")
    }

    default:
      return state
  }
}
