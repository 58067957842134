import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import withStyles from "@mui/styles/withStyles"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"

import { get, map } from "lodash"

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: 0,
  },
})

const PaperList = ({ classes, items, noItemsLabel, propNames: { id, name }, render }) => (
  <div className={classes.root}>
    {get(items, "length", 0) > 0 ? (
      <List>
        {map(items, item => (
          <ListItem className={classes.container} key={item[id]}>
            <ListItemText>
              {!render ? (
                <Typography paragraph variant="body2">
                  {item[name]}
                </Typography>
              ) : (
                <Typography paragraph variant="body2">
                  {render(item)}
                </Typography>
              )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    ) : (
      <Typography paragraph variant="caption">
        <FormattedMessage id={noItemsLabel} />
      </Typography>
    )}
  </div>
)

PaperList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array,
  propNames: PropTypes.object.isRequired,
  render: PropTypes.func,
  noItemsLabel: PropTypes.string.isRequired,
}

PaperList.defaultProps = {
  render: null,
  items: [],
}

export default withStyles(styles)(PaperList)
