import { includes, without } from "lodash"

import ActionTypes from "../../constants/ActionTypes"
import { initialState } from "./initialState"

export default (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.UI_TIMELINE_TOGGLE_ACTIVITY_LOCK: {
      const { activityId } = payload
      const { unlockedActivities } = state.settings

      return {
        ...state,
        settings: {
          ...state.settings,
          unlockedActivities: includes(unlockedActivities, activityId)
            ? without(unlockedActivities, activityId)
            : [...unlockedActivities, activityId],
        },
      }
    }

    case ActionTypes.UI_TIMELINE_TOGGLE_OPERATION_LOCK: {
      const { operationId } = payload
      const { unlockedOperations } = state.settings

      return {
        ...state,
        settings: {
          ...state.settings,
          unlockedOperations: includes(unlockedOperations, operationId)
            ? without(unlockedOperations, operationId)
            : [...unlockedOperations, operationId],
        },
      }
    }

    default:
      return state
  }
}
