import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"

export default [
  APIGenericSequence({
    actionType: ActionTypes.REPORTS_FETCH,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/v2/reports`,
  }),
]
