import ActionTypes from "../../constants/ActionTypes"
import { APIRequestSequence } from "../api"
import { API_SERVER_USER } from "../../constants/api"
import licenceModulesSchema from "../../schemas/licenceModules"
import licenceModuleDetailsSchema from "../../schemas/licenceModuleDetails"
import { put } from "../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.LICENCE_MODULES_LIST,
    endPoint: `${API_SERVER_USER}/licences`,
    schema: licenceModulesSchema,
  }),

  APIRequestSequence({
    actionType: ActionTypes.LICENCE_MODULES_ADD,
    endPoint: `${API_SERVER_USER}/licences`,
    prepareRequest: payload => payload,
    method: put,
    schema: licenceModulesSchema,
  }),
  APIRequestSequence({
    actionType: ActionTypes.LICENCE_MODULES_DETAILS,
    endPoint: `${API_SERVER_USER}/licences`,
    schema: licenceModuleDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareResponse: data => [data],
  }),
  APIRequestSequence({
    actionType: ActionTypes.LICENCE_MODULES_DETAILS_UPDATE,
    endPoint: `${API_SERVER_USER}/licences`,
    prepareRequest: payload => payload.data,
    method: put,
    schema: licenceModuleDetailsSchema,
    prepareUrl: (endPoint, payload) => `${endPoint}/${payload.id}`,
    prepareResponse: data => {
      if (data.errorCode) {
        return data
      }
      return [data]
    },
  }),
]
