import { invert } from "lodash"

const TerminationTypes = {
  Backward: 1,
  Forward: 2,
}

export const TerminationNames = invert(TerminationTypes)

export default TerminationTypes
