import React from "react"
import Grid from "@mui/material/Grid"
import { FormattedMessage } from "react-intl"
import withStyles from "@mui/styles/withStyles"
import Fade from "@mui/material/Fade"
import LoginForm from "../../containers/Login"
import InfoLabel from "../../components/InfoLabel"
import { lightblue } from "../../constants/colors"

const IS_TEST_SYSTEM = window.planeusConfig && window.planeusConfig.environment === "TESTSYSTEM"
const IS_DEMO_SYSTEM = window.planeusConfig && window.planeusConfig.environment === "DEMOSYSTEM"

const styles = {
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexGrow: 1,
    backgroundColor: lightblue,
    background: "linear-gradient(150deg, #00a5e5 -10%, #00346e 100%)",
  },
  label: {
    position: "absolute",
    top: 0,
    right: 20,
  },
  "@media screen and (max-height: 500px)": {
    label: {
      height: 20,
    },
  },
}

const Login = ({ classes }) => (
  <div className={classes.root}>
    <Grid container justifyContent="center" alignItems="center">
      {IS_TEST_SYSTEM && (
        <InfoLabel
          label={<FormattedMessage id="app.appBar.testSystem" />}
          className={classes.label}
          show={IS_TEST_SYSTEM}
        />
      )}
      {IS_DEMO_SYSTEM && (
        <InfoLabel label={<FormattedMessage id="app.appBar.demoSystem" />} className={classes.label} />
      )}
      <Fade timeout={800} in>
        <Grid item xs={11} sm={8} md={4} lg={3} xl={2}>
          <LoginForm />
        </Grid>
      </Fade>
    </Grid>
  </div>
)

export default withStyles(styles)(Login)
