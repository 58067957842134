import ActionTypes from "../../../constants/ActionTypes"
import { APIRequestSequence } from "../../api"
import templatesSchema from "../../../schemas/basedata/templates/index"
import { API_SERVER_BASEDATA } from "../../../constants/api"
import templateDetailsSchema from "../../../schemas/basedata/templates/details"
import { put } from "../../../utils/http"

export default [
  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TEMPLATES_LIST,
    endPoint: `${API_SERVER_BASEDATA}/processTemplates`,
    schema: templatesSchema,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TEMPLATE_DETAILS_LIST,
    endPoint: `${API_SERVER_BASEDATA}/processTemplateDetails`,
    schema: templateDetailsSchema,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}`,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_ARTICLE_TEMPLATE_DETAILS_LIST,
    endPoint: `${API_SERVER_BASEDATA}/processTemplateDetails`,
    schema: templateDetailsSchema,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}`,
  }),

  APIRequestSequence({
    actionType: ActionTypes.BASEDATA_TEMPLATE_DETAILS_UPDATE,
    endPoint: `${API_SERVER_BASEDATA}/processTemplates`,
    schema: templateDetailsSchema,
    method: put,
    prepareUrl: (endPoint, { id }) => `${endPoint}/${id}`,
    prepareRequest: ({ data }) => data,
    prepareResponse: template => [template],
  }),
]
