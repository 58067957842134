import { forEach } from "lodash"

/* given a sorted array (sorted ascending by start) of items, extract the items within a given range */
export const filterTimeSlicesInRange = (orderedSlices, start, end) => {
  const startDate = typeof start === "string" ? new Date(start) : start
  const endDate = typeof end === "string" ? new Date(end) : end

  const itemsInRange = []
  /* optimize by not starting at the beginning? */

  forEach(orderedSlices, item => {
    if (item.end <= startDate) {
      return true
    }

    if (item.end <= endDate) {
      itemsInRange.push(item)
    } else if (item.start >= endDate && item.end > endDate) {
      // abort the iteration because no item can be in the range after this point
      return false
    } else {
      itemsInRange.push(item)
    }
    return true
  })

  return itemsInRange
}
