export default function accessProxy(object) {
  return new Proxy(object, {
    get: (target, name) => {
      if (name in target) {
        return target[name]
      }
      throw new Error(`Undefined property '${name}'`)
    },
  })
}
