import { invert } from "lodash"

export const AvailabilityCalcTypes = {
  FULL: 1,
  NET: 2,
}

export const EfficiencyCalcTypes = {
  PLAN: 1,
  ACTUAL: 2,
}

export const AvailabilityCalcNames = invert(AvailabilityCalcTypes)
export const EfficiencyCalcNames = invert(EfficiencyCalcTypes)
