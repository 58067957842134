import React from "react"
import { compact, map } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import SnackbarBase from "./Snackbar/SnackbarBase"
import { removeSnackbarMessage } from "../../actions/ui/snackbar"

const useStyles = makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    bottom: 20,
    left: 16,
    right: "auto",
    zIndex: "1400",
    position: "fixed",
  },
  message: {
    backgroundColor: "rgba(0,0,0,0.65)",
    padding: 10,
    marginTop: 10,
    color: "white",
    boxShadow: "rgba(0,0,0,0.5) 3px 3px 15px",
  },
}))

const SnackbarManager = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onClose = id => dispatch(removeSnackbarMessage(id))
  const messages = useSelector(state => state.ui.snackbar.messages)
  return (
    <div className={classes.root}>
      {map(compact(messages), ({ id, text, type, values, defaultMessage }) =>
        text ? (
          <SnackbarBase
            id={id}
            key={id}
            type={type}
            messageId={text}
            values={values}
            defaultMessage={defaultMessage}
            onClose={() => onClose(id)}
          />
        ) : null
      )}
    </div>
  )
}

export default SnackbarManager
