import React, { Fragment } from "react"
import Chip from "@mui/material/Chip"
import Tooltip from "@mui/material/Tooltip"
import IconCheck from "@mui/icons-material/CheckCircle"
import IconComment from "@mui/icons-material/Comment"
import { blue } from "@mui/material/colors"

import { scaleLinear } from "d3-scale"
import { FormattedDate, FormattedNumber, FormattedMessage, FormattedTime } from "react-intl"
import { camelCase, isSafeInteger, get } from "lodash"

import theme from "../../theme"
import { Bullet } from "../ColorPicker"
import Duration, { formatSecondsInAppropriateUnit } from "../Duration"
import { styleColorById } from "../../theme/status"
import { FormattedDuration } from "../Tickets/FormattedDuration"
import UserName from "../UserName/UserName"
import { TerminationNames } from "../../types/TerminationTypes"
import SplittedIcon from "../Icons/SplittedIcon"

export const getStyleById = id => ({
  backgroundColor: styleColorById(id),
})

const dateProps = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
}

const checkboxEnabled = {
  color: blue[500],
  verticalAlign: "middle",
}
const checkboxDisabled = {
  color: theme.palette.grey[400],
  verticalAlign: "middle",
}

export const EnumCell =
  (Formatter, colorByValue = getStyleById) =>
  ({ value, ...props }) =>
    (
      <Chip
        sx={{ "& .MuiChip-label": { textOverflow: "initial" } }}
        label={<Formatter value={value} {...props} />}
        style={colorByValue(value, props)}
      />
    )

export const BooleanCell = ({ value }) => <IconCheck style={value ? checkboxEnabled : checkboxDisabled} />
export const BooleanCellWithExtraValue = accessor => props => {
  const {
    value,
    row: { original },
  } = props
  return (
    <Fragment>
      <IconCheck style={value ? checkboxEnabled : checkboxDisabled} />
      {original[accessor] ? ` (${original[accessor]})` : null}
    </Fragment>
  )
}

export const ColorCell = ({ value }) => (value ? <Bullet color={value} /> : null)
export const IconCell =
  (IconComponent, props) =>
  ({ value }) =>
    value ? <IconComponent {...props} /> : null
export const CommentCell = ({ value }) =>
  (value && value.length && (
    <Tooltip placement="top" title={value}>
      <IconComment color="primary" />
    </Tooltip>
  )) ||
  null
export const DifferenceCell = EnumCell(
  ({ value }) => <FormattedNumber value={value} maximumFractionDigits={0} minimumIntegerDigits={1} />,
  value => ({
    color: "white",
    background: scaleLinear()
      .domain([9, 10, 10, 30, 30, 30])
      .range(["#66b032", "#66b032", "#FB9902", "#FB9902", "#FE2712", "#FE2712"])(Math.round(value)),
  })
)
export const DurationCell = ({ value }) => (value && <FormattedDuration value={value} />) || null
export const DateCell = ({ value, ...props }) =>
  value ? <FormattedDate value={value} {...dateProps} {...props} /> : null
export const IntegerCell = ({ value }) => <FormattedNumber value={value} />
export const InvertedBoolCell = ({ value }) => <IconCheck style={!value ? checkboxEnabled : checkboxDisabled} />
export const DecimalCell = ({ value }) => {
  if (value) {
    if (typeof value === "number") {
      if (!isSafeInteger(value)) {
        return <FormattedNumber value={value} maximumFractionDigits={2} />
      }
    }
  }
  return value
}

export const DateTimeCell = ({ value }) =>
  value && (
    <Fragment>
      <FormattedDate value={value} {...dateProps} /> <FormattedTime value={value} />
    </Fragment>
  )

export const ReadableSecondsCell = ({ value }) => <Duration duration={formatSecondsInAppropriateUnit(value)} />

export const TerminationCell = ({ value }) => <FormattedMessage id={`type.termination.${TerminationNames[value]}`} />

export const ActionCell = ({ value }) => <FormattedMessage id={`action.${value}`} />

export const SplittedIconCell = ({ value: { splitted, isSplit } }) => (
  <SplittedIcon splitted={splitted} isSplit={isSplit} style={{ verticalAlign: "middle" }} />
)

export const TranslatedTerm = ({ value }) => <FormattedMessage id={`term.${camelCase(value)}`} />

export const GridUser = ({ value }) => <UserName user={value} />

export const SimpleGridUser = accessor => props => {
  const user = get(props, `row.original.${accessor}`)
  return <UserName user={user} />
}
