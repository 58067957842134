import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import accessibleLicenceModules from "./accessibleLicenceModules"
import authentication from "./authentication"
import screen from "./screen"
import terminal from "./terminal"
import userPassChange from "./userPassChange"
import languages from "./languages"
import terminalAuthentication from "./terminalAuthentication"
import PersistorKey from "../../types/PersistorKey"

export default combineReducers({
  accessibleLicenceModules,
  userPassChange,
  authentication: persistReducer(
    {
      key: PersistorKey.user,
      storage,
      whitelist: ["token", "authenticated", "user"],
    },
    authentication
  ),
  terminalAuthentication: persistReducer(
    {
      key: PersistorKey.terminalUser,
      storage,
      whitelist: ["token", "pinAuthenticated", "user"],
    },
    terminalAuthentication
  ),
  screen,
  languages,
  terminal,
})
