import ActionTypes from "../../constants/ActionTypes"

export default (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PLANNING_OBJECTS_PRODUCTION_ORDER_DETAILS_LOAD_REQUEST: {
      const {
        payload: { productionOrderId },
      } = action
      return {
        ...state,
        [productionOrderId]: { ...state[productionOrderId], error: null },
      }
    }

    default:
      return state
  }
}
