import keyMirror from "keymirror"
import accessProxy from "../utils/accessProxy"

const PersistorKey = keyMirror({
  terminalUser: null,
  user: null,
  ui_workbench: null,
})

export default accessProxy(PersistorKey)
