import { schema } from "normalizr"

const rightSchema = new schema.Entity("rights", {}, { idAttribute: "rightName" })
const areaSchema = new schema.Entity("areas", { rights: [rightSchema] })
const moduleSchema = new schema.Entity("modules", { areas: [areaSchema] })
const rootModule = new schema.Entity("rootModules", {
  moduleLicences: [moduleSchema],
})

export default rootModule
