import { put as putEffect, takeLatest } from "redux-saga/effects"
import ActionTypes from "../../constants/ActionTypes"
import { APIGenericSequence } from "../api"
import { API_SERVER_PLANNING_PROCESS } from "../../constants/api"
import { post, uploadFile } from "../../utils/http"
import { createAction } from "../../utils/redux"

const ARTICLE_UPLOAD_NAME = "importarticles"
const ORDER_POSITIONS_UPLOAD_NAME = "importprojectpositions"
const CUSTOMERS_UPLOAD_NAME = "importcustomers"
const MATERIAL_UPLOAD_NAME = "importmaterial"

export default [
  APIGenericSequence({
    actionType: ActionTypes.IMPORTS_UPLOAD,
    endPoint: `${API_SERVER_PLANNING_PROCESS}/uploadFile`,
    method: uploadFile,
  }),
  APIGenericSequence({
    actionType: ActionTypes.IMPORTS_VALIDATE,
    endPoint: API_SERVER_PLANNING_PROCESS,
    method: post,
    prepareUrl: (endPoint, payload) => {
      let endPointValidate = endPoint
      switch (payload.uploadType) {
        case ARTICLE_UPLOAD_NAME:
          endPointValidate = `${endPoint}/validateArticlesFile`
          break
        case ORDER_POSITIONS_UPLOAD_NAME:
          endPointValidate = `${endPoint}/validateProjectPositionsFile`
          break
        case CUSTOMERS_UPLOAD_NAME:
          endPointValidate = `${endPoint}/validateCustomersFile`
          break
        case MATERIAL_UPLOAD_NAME:
          endPointValidate = `${endPoint}/validateMaterialFile`
          break
        default:
          endPointValidate = null
      }

      return endPointValidate
    },
    prepareResponse: payload => payload,
  }),
  APIGenericSequence({
    actionType: ActionTypes.IMPORTS_PROCESS,
    endPoint: API_SERVER_PLANNING_PROCESS,
    method: post,
    prepareUrl: (endPoint, payload) => {
      let endPointImport = endPoint
      switch (payload.uploadType) {
        case ARTICLE_UPLOAD_NAME:
          endPointImport = `${endPoint}/importArticles`
          break
        case ORDER_POSITIONS_UPLOAD_NAME:
          endPointImport = `${endPoint}/importProjectPositionsFile`
          break
        case CUSTOMERS_UPLOAD_NAME:
          endPointImport = `${endPoint}/importCustomers`
          break
        case MATERIAL_UPLOAD_NAME:
          endPointImport = `${endPoint}/importMaterial`
          break
        default:
          endPointImport = null
      }

      return endPointImport
    },
    prepareRequest: payload => payload,
  }),
  takeLatest(ActionTypes.IMPORTS_PROCESS_SUCCESS, function* refetchConfigurationObject() {
    yield putEffect(createAction(ActionTypes.PLANNING_OBJECTS_AMOUNT_UNITS_LIST_REQUEST))
  }),
]
