import { isSafeInteger, get as getLodash } from "lodash"

export const checkTokenExpired = message => /^token expired/i.test(message || "")

export function fetchAuthenticated({
  url,
  method,
  token,
  data = null,
  customHeaders = null,
  fetch = window.fetch,
  responseType,
}) {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...customHeaders,
      }
    : customHeaders

  return fetchJSON({ url, method, data, headers, fetch, responseType })
}

const handleResponse = (response, reply) => {
  if (response.ok) {
    return Promise.resolve(reply)
  }

  switch (response.status) {
    case 400:
      return Promise.reject(new Error(`errors.API.${reply.errorType || "unknown"}`))

    case 401:
      return Promise.reject(new Error("errors.API.accessDenied"))

    default:
      return Promise.reject(new Error("errors.API.unknown"))
  }
}

const jsonStringifyReplacer = (key, value) => {
  if (typeof value === "number") {
    // Whithout this line, Json.stringify would convert this 1,9 -> 1.899999976158142
    return isSafeInteger(value) ? value : Number(value.toPrecision(10))
  }
  return value
}

export async function fetchJSON({ url, method, data = null, headers = null, fetch, responseType = "json" }) {
  const settings = {
    credentials: "include",
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  }

  let urlObject = url
  if (method === "get" && data) {
    urlObject = new URL(url)
    Object.keys(data).forEach(key => urlObject.searchParams.append(key, data[key]))
  } else if (data) settings.body = JSON.stringify(data, jsonStringifyReplacer)

  try {
    const response = await fetch(urlObject, settings)

    if (response.status >= 200 && response.status < 300) {
      if (responseType === "blob") {
        return handleResponse(response, await response.blob())
      }
      if (responseType === "none") {
        return handleResponse(response, Promise.resolve())
      }
      return handleResponse(response, await response.json())
    }
    if (response.status) {
      const error = await response.json()
      const errorCode = getLodash(error, "errorCode")
      const message = getLodash(error, "message", "")
      const isTokenExpired = checkTokenExpired(message)

      if (response.status === 400 || (response.status === 401 && isTokenExpired)) {
        // eslint-disable-next-line
        return Promise.reject({ response: error, status: response.status })
      }
      return Promise.reject(new Error(errorCode || `errors.API.${response.status}`))
    }
    return Promise.reject(new Error("errors.API.unknown"))
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error) // Ḿake developers able to spot errors when working with the fetch API
    return Promise.reject(new Error("errors.API.noResponse"))
  }
}

export const del = options =>
  fetchAuthenticated({
    method: "delete",
    ...options,
  })

export const get = options =>
  fetchAuthenticated({
    method: "get",
    ...options,
  })

export const put = options =>
  fetchAuthenticated({
    method: "put",
    ...options,
  })

export const post = options =>
  fetchAuthenticated({
    method: "post",
    ...options,
  })

export async function uploadFile({ url, customHeaders = null, data: file, token }) {
  const form = new FormData()
  form.append("files", file, file.name)

  const settings = {
    body: form,
    credentials: "include",
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          ...customHeaders,
        }
      : { ...customHeaders },
    method: "post",
  }

  const response = await window.fetch(url, settings)
  if (response.status > 300) {
    const error = await response.json()
    const errorCode = getLodash(error, "errorCode")
    const message = getLodash(error, "details")
    return Promise.reject(new Error(message || errorCode || `errors.API.${response.status}`))
  }
  return handleResponse(response, await response.json())
}
